import React from "react";
import {
    BarChart as BarChartIcon,
    PersonOutlined as PersonOutlinedIcon,
    GroupOutlined as GroupOutlinedIcon,
    Face as FaceIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";

const CallCenterSalesMenu = ({
    userPermissions,
    openMenuSales,
    handleClickSales,
    activeStyle,
}) => {
    return (
        <>
            {(userPermissions?.includes("CALLCENTER_LEADS") ||
                userPermissions?.includes("CALLCENTER_LEADS_SUPERVISOR")) && (
                <>
                    <GenericListItemButton
                        onClick={handleClickSales}
                        className={"layout-menu-submenu"}
                        title="Ventas"
                        IconComponent={BarChartIcon}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={openMenuSales}
                        userPermissions={userPermissions}
                    >
                        {userPermissions?.includes("CALLCENTER_LEADS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="CALLCENTER_LEADS"
                                to="sales/dashboard"
                                title="Mis Ventas"
                                IconComponent={PersonOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes(
                            "CALLCENTER_LEADS_SUPERVISOR"
                        ) && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="CALLCENTER_LEADS_SUPERVISOR"
                                to="/sales/ejecutivo"
                                title="Ventas Ejecutivos"
                                IconComponent={GroupOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("CALLCENTER_LEADS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="CALLCENTER_LEADS"
                                to="/sales/ejecutivo/list-transfer-sales"
                                title="Ventas por transferencia"
                                IconComponent={GroupOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("CALLCENTER_LEADS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="CALLCENTER_LEADS"
                                to="leads"
                                title="Leads"
                                IconComponent={FaceIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("CALLCENTER_LEADS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="CALLCENTER_LEADS"
                                to="paymentlinks"
                                title="Mis ligas de pago"
                                IconComponent={LinkIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default CallCenterSalesMenu;
