import { useState } from "react";

export const CustomFormApplyPackageHook = (control, register, errors) => {
    const [formState, setFormState] = useState({
        firstSelect: "",
        secondSelect: "",
        thirdTextField: "",
        showAdditionalTextField: false,
        motivoInput: "",
    });

    const handleFirstSelectChange = (event) => {
        const selectedValue = event.target.value;
        setFormState({
            ...formState,
            firstSelect: selectedValue,
            showAdditionalTextField: selectedValue === "otro",
        });
    };

    const handleSecondSelectChange = (event) => {
        setFormState({ ...formState, secondSelect: event.target.value });
    };

    const handleThirdTextFieldChange = (event) => {
        setFormState({ ...formState, thirdTextField: event.target.value });
    };

    const handleMotivoInputChange = (event) => {
        setFormState({ ...formState, motivoInput: event.target.value });
    };

    const commonTextFieldProps = {
        fullWidth: true,
        error: Boolean(errors.firstSelect),
        helperText: errors.firstSelect && errors.firstSelect.message,
    };
    return {
        formState,
        handleFirstSelectChange,
        handleSecondSelectChange,
        handleThirdTextFieldChange,
        handleMotivoInputChange,
        commonTextFieldProps,
    };
};
