import React from "react";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TitleHeaderHook } from "../hooks/titleHeaderHook";

function HeaderTitle(props) {
    const { timePassed, titleTicket } = TitleHeaderHook(props);
    return (
        <Grid container className="page-header ticket-detail">
            <Grid
                item
                xs={6}
                textAlign={"start"}
                display="flex"
                alignItems="center"
                paddingTop={"15px"}
            >
                <a href="/my-tickets">
                    <ArrowBackIosIcon className={"icon"} />
                </a>
                <h3>{titleTicket}</h3>
            </Grid>
            <Grid
                item
                xs={6}
                className="right"
                display="flex"
                justifyContent="flex-end"
                paddingTop={"15px"}
            >
                <div className="counter" style={{ textAlign: "end" }}>
                    <Button disabled variant="outlined" className="item">
                        Abierto hace: {timePassed}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

export default HeaderTitle;
