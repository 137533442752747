import React from "react";
import { Grid } from "@mui/material";

import HelpdeskHeaderComponent from "./_sections/_header/_component";
import HelpdeskDatagridComponent from "./_sections/_datagrid/_component";
import HelpdeskFooterComponent from "./_sections/_footer/_component";

const HelpdeskComponent = () => {
    return (
        <Grid container>
            <HelpdeskHeaderComponent />
            <HelpdeskDatagridComponent />
            <HelpdeskFooterComponent />
        </Grid>
    );
};

export default HelpdeskComponent;
