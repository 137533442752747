import React from "react";
import {
    Grid,
    DialogContent,
    Dialog,
    DialogTitle,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import DataTableDetailOrder from "./data-table-detail-order.jsx";
import { cleanSaleOrderDetailAction } from "_models/redux/sales-detail-order/clean-action";
import { cleanSaleOrderEsimQr } from "_models/redux/esim-qr/clean-action";

function ModalDetailsInfo(props) {
    const {
        cleanSaleOrderDetailAction,
        cleanSaleOrderEsimQr,
        setOpenModal,
        openModal,
        onClose,
    } = props || {};

    const handleClose = () => {
        setOpenModal(false);
        cleanSaleOrderDetailAction();
        cleanSaleOrderEsimQr();
        onClose();
    };

    return (
        <Dialog open={openModal} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <span>Detalle orden de compra</span>
                    <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DataTableDetailOrder />
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    cleanSaleOrderDetailAction,
    cleanSaleOrderEsimQr,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetailsInfo);
