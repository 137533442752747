import React from "react";
import { connect } from "react-redux";
import LeadsHeader from "./components/header";
import SalesDataTableExecutive from "./components/graph";

const LeadsExecutivePage = (props) => {
    return (
        <div>
            <LeadsHeader />
            <SalesDataTableExecutive />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsExecutivePage);
