import axiosTokenInstance from "services/api/backoffice";
import { ATN_PORTABILITY, ATN_PORTABILITY_ERROR } from "_models/redux/types";

export const atnPortabilityAction =
    (page, size, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                "/api/reports/portability",
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: ATN_PORTABILITY,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: ATN_PORTABILITY_ERROR,
                payload: e || {},
            });
        }
    };
