import React from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const GenericAddButton = ({
    onClick,
    buttonText,
    backgroundColor,
    size,
    isSubmit = false,
    icon = <AddCircleIcon />, // Propiedad para determinar el icono del botón (por defecto es AddCircleIcon)
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button
            type={isSubmit ? "submit" : "button"}
            variant="contained"
            onClick={handleClick}
            fullWidth
            startIcon={icon} // Usar el icono especificado en la prop 'icon'
            style={{ backgroundColor: backgroundColor }}
            size={size}
        >
            {buttonText}
        </Button>
    );
};

export default GenericAddButton;
