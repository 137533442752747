import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { Grid } from "@mui/material";
import { getCampaingAction } from "_models/redux/get-campaing/action";
import { getChannelAction } from "_models/redux/get-channel/action";
import { getLandingList } from "_models/redux/get-landing_list/action";
import { getLeadsByCampaingAction } from "_models/redux/leads-by-campaing/action";
import { cleanLeadsByCampaingAction } from "_models/redux/leads-by-campaing/clean_action";
import { getOrdersByProductAction } from "_models/redux/orders-by-product/action";
import { cleanOrdersByProductAction } from "_models/redux/orders-by-product/clean-action";
import LeadsByCampaingCharts from "./charts";
import LeadsByCampaingFilters from "../filters/filters";
import LeadsByCampaingCustomFilters from "../filters/customFilters";
import { LoadersContext } from "_models/context/app";

const LeadsByCampaingPage = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const {
        leadsByCampaing,
        cleanLeadsByCampaingAction,
        getLeadsByCampaingAction,
        getOrdersByProductAction,
        getChannelObj,
        getCampaingObj,
        landingListObj,
        cleanOrdersByProductAction,
        ordersByProduct,
        getChannelAction,
        getLandingList,
        getCampaingAction,
    } = props || {};

    useEffect(() => {
        getChannelAction();
        getLandingList();
        getCampaingAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [filters, setFilters] = useState({});

    const [fromTime, setFromTime] = useState(
        moment().startOf("month").format()
    );
    const [toTime, setToTime] = useState(moment().format());

    const [channelId, setChannelId] = useState(0);
    const [campaignId, setCampaignId] = useState(0);
    const [landingId, setLandingId] = useState(0);

    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );

    useEffect(() => {
        // setIsScreenLoading(true);

        // getLoadedMethods();

        return () => {
            setIsScreenLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Format the dates in "YYYY/MM/DD" format
        const formattedFromTime = moment(fromTime).format("YYYY-MM-DD");
        const formattedToTime = moment(toTime).format("YYYY-MM-DD");

        // Set the formatted dates back into the state
        setFromTime(formattedFromTime);
        setToTime(formattedToTime);

        // getLoadedMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromTime, toTime]);

    useEffect(() => {
        const { loaded } = leadsByCampaing || {};

        if (loaded) {
            setIsScreenLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadsByCampaing]);

    const getLoadedMethods = () => {
        getLeadsByCampaingMethod();
        getOrdersByProductMethod();
    };

    const getLeadsByCampaingMethod = () => {
        getLeadsByCampaingAction({
            from_time: fromTime,
            to_time: toTime,
            channel_id: channelId,
            campaign_id: campaignId,
            landing_id: landingId,
        });
    };

    const getOrdersByProductMethod = () => {
        getOrdersByProductAction({
            from_time: fromTime,
            to_time: toTime,
            channel_id: channelId,
            campaign_id: campaignId,
            landing_id: landingId,
        });
    };

    const loadData = (e) => {
        if (landingId !== 0) {
            setIsScreenLoading(true);
            getLoadedMethods();
        }
    };

    const parentProperties = {
        form: {
            watch,
            reset,
            control,
            setValue,
            getValues,
            register,
            handleSubmit,
            setError,
            errors,
        },
        redux: {
            leadsByCampaing,
            cleanLeadsByCampaingAction,
            getLeadsByCampaingAction,
            getOrdersByProductAction,
            getChannelObj,
            getCampaingObj,
            landingListObj,
            cleanOrdersByProductAction,
            ordersByProduct,
        },
        states: {
            filters,
            setFilters,
            fromTime,
            setFromTime,
            toTime,
            setToTime,
            channelId,
            setChannelId,
            campaignId,
            landingId,
            setLandingId,
            setCampaignId,
            setIsScreenLoading,
            displayDatepickers,
            setDisplayDatepickers,
        },
        methods: {
            loadData,
        },
    };

    return (
        <Grid container className="m-t-10" spacing={4}>
            <Grid item xs={12} md={12} lg={12} xl={5}>
                <LeadsByCampaingFilters parentProperties={parentProperties} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={5}>
                <LeadsByCampaingCustomFilters
                    parentProperties={parentProperties}
                />
            </Grid>

            <Grid item xs={2}>
                <button
                    type="button"
                    variant="contained"
                    onClick={loadData}
                    className="m-0 h-55"
                >
                    Buscar
                </button>
            </Grid>

            <Grid item xs={12}>
                <LeadsByCampaingCharts parentProperties={parentProperties} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    leadsByCampaing: state?.leadsByCampaing,
    ordersByProduct: state?.ordersByProduct,
    getChannelObj: state?.getChannelObj,
    getCampaingObj: state.getCampaingObj,
    landingListObj: state.landingListObj,
});

const mapDispatchToProps = {
    getLeadsByCampaingAction,
    cleanLeadsByCampaingAction,
    getChannelAction,
    getCampaingAction,
    getOrdersByProductAction,
    cleanOrdersByProductAction,
    getLandingList,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeadsByCampaingPage);
