import { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";

export const ActionLockImeiHook = (props) => {
    const { ticketId, imeiNumber } = props;
    const [modalOpenLockImei, setModalOpenLockImei] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");
    const [alert, setAlert] = useState(false);
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(false);
        const { lockImei_ } = props.lockImei_ || {};
        const imei = lockImei_.imei || undefined;
        if (lockImei_.length !== 0) {
            if (imei === imeiNumber) {
                setSeverityAlert("success");
                setErrorMssge("Bloqueo de IMEI se realizó satisfactoriamente");
                setAlert(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.lockImei_]);

    const handleLockImei = () => {
        setIsLoading(true);
        props.lockImei({
            ticket_id: ticketId,
            imei: imeiNumber,
        });
    };

    const closePopup = () => {
        setModalOpenLockImei(false);
        props.cleanLockImei();
        setAlert(false);
    };

    return {
        modalOpenLockImei,
        setModalOpenLockImei,
        isLoading,
        setIsLoading,
        errorMssge,
        setErrorMssge,
        severityAlert,
        setSeverityAlert,
        alert,
        setAlert,
        handleLockImei,
        closePopup,
        imeiNumber,
    };
};
