import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const DataGridHook = ({ props }) => {
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(true);
    const [showPopover, setShowPopover] = useState(null);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [ticketId, setTicketId] = useState(0);
    const [dataUsersList, setDataUserList] = useState(null);
    const navigate = useNavigate();

    const {
        getTickets,
        generalTickets,
        cleanGeneralTicketsAction,
        getUsers,
        userList,
        changeUser,
        changeUser_,
    } = props || {};

    const { response } = userList || {};

    const open = Boolean(showPopover);

    const generateRows = () => {
        const { loaded, payload } = generalTickets || {};

        if (loaded) {
            const { tickets } = payload || {};
            setLoader(false);

            setRows(tickets);
        }
    };

    useEffect(() => {
        getTickets();
        getUsers();
        setLoader(true);
        return () => {
            cleanGeneralTicketsAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalTickets]);

    useEffect(() => {
        if (userList) {
            setDataUserList(response.users);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList]);

    useEffect(() => {
        if (changeUser_.loading) {
            getTickets();
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeUser_]);

    const handleNavigate = (params) => {
        if (
            typeof params?.target?.id != "undefined" &&
            typeof params?.target?.name != "undefined"
        ) {
            navigate("/tickets/" + params.target.id, {
                state: { directNumber: params.target.name },
            });
        }
    };

    const handleChangeUser = (params) => {
        changeUser({ ticketId: ticketId, user_id: params.id });
    };

    const handleClick = (params) => {
        setShowPopover(params.events.currentTarget);
        setPositionX(params.events.clientX + 30);
        setPositionY(params.events.clientY + 10);
        setTicketId(params.ticketId);
    };

    const handleClose = () => {
        setShowPopover(null);
    };

    return {
        handleClose,
        handleClick,
        handleNavigate,
        handleChangeUser,
        loader,
        dataUsersList,
        rows,
        showPopover,
        positionX,
        positionY,
        open,
    };
};
