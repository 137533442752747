import { Grid } from "@mui/material";

//Assets - login image:
import loginImage from "assets/images/login/login-image.png";

//Component - Login content (child):
import LoginContent from "./LoginContent";
//Hook - Login component:
import { useLoginHook } from "../hooks/useLoginHook";

const LoginComponent = () => {
    //Properties from hook:
    const { mainWrapper, leftWrapper, leftWrapperImg, rightWrapper } =
        useLoginHook() || {};

    //Main Login Wrapper (left an right containers)
    return (
        <Grid container sx={mainWrapper || {}}>
            <Grid item lg={4} xl={6} sx={leftWrapper || {}}>
                <img
                    title="Login Image"
                    alt="Login screen illustration"
                    src={loginImage || ""}
                    style={leftWrapperImg || {}}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={6}
                sx={rightWrapper || {}}
            >
                <LoginContent />
            </Grid>
        </Grid>
    );
};

export default LoginComponent;
