import { Grid, IconButton } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//Generic Text Field Component:
import CustomTextField from "utils/components/fields/CustomTextField/Component";
//Generic Error Message Component:
import ErrorMessageComponent from "widgets/errorMessage/component";

const LoginFormFields = ({ hookProps }) => {
    //Desructuring hook props, inherit from component parent:
    const {
        //Form Props:
        errors,
        register,

        //State Props:
        visibilityValues,

        //Methods:
        showPasswordClick,
        passwordOnChange,
        codeOnChange,
        showCodeClick,
    } = hookProps || {};

    //Fields for login form:
    // - Email
    // - Password
    // - Verification Code
    return (
        <>
            <Grid item xs={12}>
                <CustomTextField
                    fieldLabel="Correo electrónico"
                    fieldPlaceholder="correoelectrónico@dalefon.com.mx"
                    fieldDisabled={false}
                    fileldDefaultValue=""
                    fieldStartIcon=""
                    fielEndIcon=""
                    fieldName="user_email"
                    fieldMethods={{ register }}
                    fieldRequired={{
                        required: "El correo electrónico es requerido",
                    }}
                    fieldError={
                        <ErrorMessageComponent
                            errorParams={{ errors }}
                            errorType={"user_email"}
                        />
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <CustomTextField
                    fieldType={
                        visibilityValues.showPassword ? "text" : "password"
                    }
                    fieldLabel="Contraseña"
                    fieldPlaceholder="Contraseña"
                    fieldDisabled={false}
                    fileldDefaultValue=""
                    fieldStartIcon=""
                    fielEndIcon={
                        <IconButton onClick={showPasswordClick} size="large">
                            {visibilityValues.showPassword ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )}
                        </IconButton>
                    }
                    fieldName="user_password"
                    fieldMethods={{ register }}
                    fieldOnChange={passwordOnChange}
                    fieldRequired={{
                        required: "La contraseña es requerida",
                    }}
                    fieldError={
                        <ErrorMessageComponent
                            errorParams={{ errors }}
                            errorType={"user_password"}
                        />
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <CustomTextField
                    fieldType={visibilityValues.showCode ? "text" : "password"}
                    fieldLabel="Código de verificación"
                    fieldPlaceholder="Código de verificación"
                    fieldDisabled={false}
                    fileldDefaultValue=""
                    fieldStartIcon=""
                    fielEndIcon={
                        <IconButton onClick={showCodeClick} size="large">
                            {visibilityValues.showCode ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )}
                        </IconButton>
                    }
                    fieldName="user_verification_code"
                    fieldMethods={{ register }}
                    fieldOnChange={codeOnChange}
                    fieldRequired={{
                        required: "El código de verificación es requerido",
                    }}
                    fieldError={
                        <ErrorMessageComponent
                            errorParams={{ errors }}
                            errorType={"user_verification_code"}
                        />
                    }
                />
            </Grid>
        </>
    );
};

export default LoginFormFields;
