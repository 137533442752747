// Import: Middleware Action & Custom Types
import { CLEAN_TICKETS, CLEAN_TICKETS_ERROR } from "_models/redux/types";

// Action function
export const cleanTicketsAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_TICKETS,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_TICKETS_ERROR,
        });
    }
};
