import { Grid } from "@mui/material";

import DailySalesManagerChart from "./chart-component";
import DailySalesManagerTable from "./table-component";

const DailySalesManagerContent = (props) => {
    return (
        <Grid item xs={12}>
            <Grid container mt={7}>
                <Grid xs={12} md={12}>
                    <DailySalesManagerChart props={props} />
                </Grid>
                <Grid xs={12} md={12}>
                    <DailySalesManagerTable props={props} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DailySalesManagerContent;
