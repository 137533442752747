// Import: Middleware Action & Custom Types
import {
    CLEAN_DETAILS_TRANSACTIONS_FRAUD,
    CLEAN_DETAILS_TRANSACTIONS_FRAUD_ERROR,
} from "_models/redux/types";

// Action function
export const cleanTransactionFraud = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_DETAILS_TRANSACTIONS_FRAUD,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_DETAILS_TRANSACTIONS_FRAUD_ERROR,
            payload: undefined,
        });
    }
};
