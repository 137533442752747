import { useTheme } from "@mui/material/styles";
//Login Styles:
import { loginStyles } from "../styles/loginStyles";

//Asset: logo image
import miniLogo from "assets/images/mini-logo.png";

export const useLoginContent = () => {
    //Instance of Material-UI theme:
    const theme = useTheme();
    //Destructuring corresponding styles: login content
    const { loginContent } = loginStyles({ theme }) || {};

    //Get Current Year:
    const currentYear = new Date().getFullYear();

    //Return content properties:
    //Data, image and styles
    return {
        //Data:
        currentYear,
        //Image:
        miniLogo,
        //Styles:
        loginContent,
    };
};
