import React from "react";
import {
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const CustomListItem = ({ text, to, title, activeStyle, open, icon }) => {
    return (
        <List disablePadding xs={{ display: "block" }}>
            <NavLink
                to={to}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                title={title}
            >
                <ListItemButton
                    xs={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        xs={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={text}
                        xs={{ opacity: open ? 1 : 0 }}
                    />
                </ListItemButton>
            </NavLink>
        </List>
    );
};

export default CustomListItem;
