import React from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "./modal/new-inicidence";
import { useState } from "react";

const RowButton = () => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <Grid container className="page-header">
            <Grid item xs={6}>
                <h3>Incidencias</h3>
            </Grid>

            <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                <button
                    className="openModalBtn"
                    onClick={() => {
                        setModalOpen(true);
                    }}
                >
                    <AddIcon className="svg" />
                    Nueva incidencia
                </button>

                {modalOpen && <Modal setOpenModal={setModalOpen} />}
            </Grid>
        </Grid>
    );
};

export default RowButton;
