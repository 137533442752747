import React from "react";
import { Grid } from "@mui/material";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import InputError from "./InputError";
import { addressStyles } from "../style/PageTransferSaleStyle";

const AddressForm = (props) => {
    const {
        registerOptions,
        register,
        errors,
        inputChange,
        zipcodeShrink,
        stateShrink,
        municipalityShrink,
        neighborhoodShrink,
        addressShrink,
        contactForServiceShrink,
        states,
        municipalities,
        neighborhoods,
        newOne,
        newTwo,
        newThree,
        handleNeighborhoodChange,
        referenceInput,
    } = props;

    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12}>
                    <h1>Dirección de envío</h1>

                    <SelectInput
                        label="Estado"
                        placeholder="Estado"
                        id="stateInput"
                        name="stateInput"
                        value={newOne}
                        onChange={(e) => inputChange(e)}
                        shrink={stateShrink}
                        options={states}
                        register={register}
                        registerOptions={registerOptions?.stateInput}
                        errors={errors?.stateInput}
                        sx={addressStyles.textField}
                    />

                    <InputError errors={errors?.stateInput} />

                    <SelectInput
                        label="Municipio"
                        placeholder="Municipio"
                        id="municipalityInput"
                        name="municipalityInput"
                        value={newTwo}
                        onChange={(e) => inputChange(e)}
                        shrink={municipalityShrink}
                        options={municipalities}
                        register={register}
                        registerOptions={registerOptions?.municipalityInput}
                        errors={errors?.municipalityInput}
                        sx={addressStyles.textField}
                    />

                    <InputError errors={errors?.municipalityInput} />

                    <SelectInput
                        label="Colonia"
                        placeholder="Colonia"
                        id="neighborhoodInput"
                        name="neighborhoodInput"
                        value={newThree}
                        onChange={handleNeighborhoodChange}
                        shrink={neighborhoodShrink}
                        options={neighborhoods}
                        register={register}
                        registerOptions={registerOptions?.neighborhoodInput}
                        errors={errors?.neighborhoodInput}
                        sx={addressStyles.textField}
                    />

                    <InputError errors={errors?.neighborhoodInput} />

                    <TextInput
                        label="Código postal"
                        placeholder="Código postal"
                        id="zipcodeInput"
                        name="zipcodeInput"
                        onChange={(e) => inputChange(e)}
                        shrink={zipcodeShrink}
                        register={register}
                        registerOptions={registerOptions?.zipcodeInput}
                        errors={errors?.zipcodeInput}
                        sx={addressStyles.textField}
                    />

                    <InputError errors={errors?.zipcodeInput} />

                    <TextInput
                        label="Calle y número int/ext"
                        placeholder="Calle y número int/ext"
                        id="addressInput"
                        name="addressInput"
                        onChange={(e) => inputChange(e)}
                        shrink={addressShrink}
                        register={register}
                        registerOptions={registerOptions?.addressInput}
                        errors={errors?.addressInput}
                        sx={addressStyles.textField}
                    />

                    <InputError errors={errors?.addressInput} />

                    <TextInput
                        label="Referencias de la dirección"
                        placeholder="Referencias de la dirección"
                        id="referenceInput"
                        name="referenceInput"
                        onChange={(e) => inputChange(e)}
                        shrink={referenceInput}
                        register={register}
                        registerOptions={registerOptions?.referenceInput}
                        errors={errors?.referenceInput}
                        sx={addressStyles.textField}
                    />

                    <InputError errors={errors?.referenceInput} />

                    <TextInput
                        label="Núm. de contacto para servicio de mensajería"
                        placeholder="Núm. de contacto para servicio de mensajería"
                        id="contactForServiceInput"
                        name="contactForServiceInput"
                        onChange={(e) => inputChange(e)}
                        shrink={contactForServiceShrink}
                        register={register}
                        registerOptions={
                            registerOptions?.contactForServiceInput
                        }
                        errors={errors?.contactForServiceInput}
                        sx={addressStyles.textField}
                    />

                    <InputError errors={errors?.contactForServiceInput} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddressForm;
