import React from "react";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import SimTypeChip from "utils/SimTypeChip";

const CustomerInfoComponent = ({
    isLoading,
    name,
    last_name,
    mothers_last_name,
    directNumber,
    labelSimType,
}) => {
    return (
        <Grid
            item
            xs={12}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Grid>
                <Grid item xs={12}>
                    <h4>
                        {isLoading ? (
                            <CircularProgress />
                        ) : name &&
                          last_name &&
                          mothers_last_name !== "undefined" ? (
                            name + " " + last_name + " " + mothers_last_name
                        ) : null}
                    </h4>
                    <p>{isLoading ? <CircularProgress /> : directNumber}</p>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                }}
            >
                <Grid item xs={12}>
                    <EditIcon />
                </Grid>
                <Grid xs={12}>
                    {console.log("labelSimType:", labelSimType)}
                    <SimTypeChip simTypeName={Number(labelSimType)} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CustomerInfoComponent;
