import React from "react";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";

export const DetailsSTP = (orderDetails) => [
    {
        label: "Nombre",
        value: orderDetails?.beneficiary_name,
    },
    {
        label: "Cuenta",
        value: orderDetails?.beneficiary_account,
    },
    {
        label: "Nombre 2",
        value: orderDetails?.beneficiary_name_2,
    },
    {
        label: "Cuenta 2",
        value: orderDetails?.beneficiary_account_2,
    },
    {
        label: "Tipo de cuenta",
        value: orderDetails?.beneficiary_account_type,
    },
    {
        label: "Institución",
        value: orderDetails?.beneficiary_institution,
    },
    {
        label: "Tipo de cuenta 2",
        value: orderDetails?.beneficiary_account_type_2,
    },
    {
        label: "Beneficiario RFC/CURP",
        value: orderDetails?.beneficiary_rfc_curp,
    },
];

export const payerFieldsSTP = (orderDetails) => {
    return [
        { label: "Nombre:", value: orderDetails?.payer_name },
        { label: "Cuenta:", value: orderDetails?.payer_account },
        { label: "Tipo de cuenta:", value: orderDetails?.payer_account_type },
        { label: "Institución:", value: orderDetails?.payer_institution },
        { label: "RFC/Curp:", value: orderDetails?.payer_rfc_curp },
    ];
};

export const getTransactionDetailsSTP = (orderDetails) => [
    {
        label: "Fecha de operación",
        value: orderDetails?.operation_date,
    },
    {
        label: "Fecha de creación",
        value: convertToMexicoTime(orderDetails?.created),
    },
    {
        label: "Timestamp",
        value: orderDetails?.settlement_timestamp_ms,
    },
    { label: "Compañía", value: orderDetails?.company },
    { label: "ID de cuenta STP", value: orderDetails?.stp_account_id },
];

export const getPaymentDetailsSTP = (orderDetails) => [
    {
        label: "Monto",
        key: "amount",
        value: <CurrencyFormatter amount={orderDetails?.amount} />,
    },
    {
        label: "Concepto:",
        key: "payment_concept",
        value: orderDetails?.payment_concept,
    },
    {
        label: "ID Solicitud:",
        key: "payment_request_id",
        value: orderDetails?.payment_request_id,
    },
    {
        label: "Tipo:",
        key: "payment_type",
        value: orderDetails?.payment_type,
    },
    {
        label: "Referencia:",
        key: "reference",
        value: orderDetails?.reference,
    },
];

export const getTrackingDetailsSTP = (orderDetails) => [
    { label: "Folio codi", value: orderDetails?.codi_folio },
    { label: "UUID", value: orderDetails?.uuid },
    {
        label: "Clave de rastreo",
        value: orderDetails?.order_tracking_key,
    },
];
