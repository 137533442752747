import axiosTokenInstance from "services/api/backoffice";
import {
    PROCESS_TRANSFER_SALES,
    PROCESS_TRANSFER_SALES_ERROR,
} from "_models/redux/types";

// Action function
export const processTransferSalesAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `api/callcenter/sims/orders/process-purchase-by-transfer`,
            params
        );

        const { data, status } = response || {};

        dispatch({
            type: PROCESS_TRANSFER_SALES,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        dispatch({
            type: PROCESS_TRANSFER_SALES_ERROR,
            payload: e || {},
        });
    }
};
