import { Grid, TextField } from "@mui/material";

import { useGiftOrdersHeaders } from "modules/support-gift-orders/hooks/gift-orders-header/useGiftOrdersHeader";

const GiftOrdersHeaderTextField = () => {
    // Hook propio de la funcionalidad del encabezado (filtros)
    const { register, errors, inputOnChange, registerValidations } =
        useGiftOrdersHeaders();

    return (
        <>
            <Grid item xs={6} lg={3} className={"filter-container"}>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    label="Número telefónico"
                    placeholder="Número telefónico"
                    id="phoneInput"
                    name="phoneInput"
                    {...register("phoneInput", registerValidations?.phoneInput)}
                    onChange={(e) => inputOnChange(e)}
                    className="m-tb-20 form-control data-input"
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#6C63FF",
                            },
                        },
                    }}
                    onWheel={(e) => e.target.blur()}
                />

                {errors?.phoneInput && (
                    <span>
                        <p className="required-incidence">
                            {" "}
                            {errors?.phoneInput?.message}
                        </p>
                    </span>
                )}
            </Grid>
        </>
    );
};

export default GiftOrdersHeaderTextField;
