import React, { useState, useEffect, useContext } from "react";

import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { AuthContext } from "_models/context/app";

export default function Loader(props) {
    const authContext = useContext(AuthContext);
    const { loading, mainScreenLoader } = props || {
        loading: false,
        mainScreenLoader: false,
    };
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (typeof loading == "boolean") {
            setOpen(loading);
        }
    }, [loading, authContext?.isMenuOpen, mainScreenLoader]);

    return (
        <Backdrop
            className=""
            sx={{
                bgcolor: "rgba(88,88,88,0.2)",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <div>
                <CircularProgress color="inherit" />
            </div>
        </Backdrop>
    );
}
