import axiosTokenInstance from "services/api/backoffice";
import {
    REPORT_TRANSACTIONS_FRAUD_SUCCESS,
    REPORT_TRANSACTIONS_FRAUD_FAILURE,
} from "_models/redux/types";

// Función de acción
export const actionReportTransactionsFraud =
    (fraudDetails) => async (dispatch) => {
        const {
            descriptionTransaction,
            txn_payment_id,
            payment_provider_name,
        } = fraudDetails || {};
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(
                `api/reports/transactions/fraud`,
                {
                    reason: descriptionTransaction,
                    txn_payment_id: parseInt(txn_payment_id),
                    payment_provider_name: payment_provider_name,
                }
            );

            const { data, status } = response || {};

            dispatch({
                type: REPORT_TRANSACTIONS_FRAUD_SUCCESS,
                payload: data || {},
                status: status || {},
                method: "post",
            });
        } catch (error) {
            dispatch({
                type: REPORT_TRANSACTIONS_FRAUD_FAILURE,
                payload: error || {},
            });
        }
    };
