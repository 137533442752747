import { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoadersContext } from "_models/context/app";

export const CommentTicketsHooks = ({
    commentTikets,
    listCommentTickets,
    postCommentsTikets,
    listcommentsTikets,
}) => {
    const [comment, setComments] = useState([]);
    const [message, setMessage] = useState("");
    const [ticketId, setTicketId] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    let params = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (e) => {
        setIsLoading(true);

        const params = {
            message: message,
        };
        try {
            await postCommentsTikets(params, ticketId);
            setMessage("");
        } catch (error) {
            console.error("Error al agregar el comentario:", error);
            setIsLoading(false);
        }
    };

    const cancelData = (e) => {
        e.preventDefault();
        setMessage("");
    };

    const registerOptions = {
        message: {
            required: "Escribe un comentario",
            minLength: {
                value: 10,
                message: "mínimo 10 caracteres",
            },
            maxLength: {
                value: 1500,
                message: "máximo 1500 caracteres",
            },
        },
    };

    useEffect(() => {
        setIsScreenLoading(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(() => {
        setTicketId(params.ticketId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const { commentTiketsObj } = commentTikets || {};
        const { message } = commentTiketsObj || {};

        if (message === "success") {
            setIsLoading(false);
            listTicketsComments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentTikets]);

    // Utilizamos useCallback para memorizar la función y evitar llamadas innecesarias
    const listTicketsComments = useCallback(() => {
        if (typeof ticketId !== "undefined") {
            listcommentsTikets(ticketId);
        }
    }, [ticketId, listcommentsTikets]);

    useEffect(() => {
        // Llamar a la función para obtener los comentarios del ticket cuando se monte el componente o cuando cambie el ticketId
        listTicketsComments();
    }, [ticketId, listTicketsComments]);

    useEffect(() => {
        const { loading, listCommentTicketObj } = listCommentTickets || {};
        const { comments } = listCommentTicketObj || {};

        if (
            loading &&
            typeof comments !== "undefined" &&
            Array.isArray(comments)
        ) {
            setComments(comments);
            setIsLoading(false);
        }
    }, [listCommentTickets]);

    return {
        registerOptions,
        handleSubmit,
        errors,
        cancelData,
        onSubmit,
        register,
        message,
        setMessage,
        comment,
        ticketId,
        isLoading,
    };
};
