import {
    GET_SALES_DAILY,
    GET_SALES_DAILY_ERROR,
    CLEAN_GET_SALES_DAILY,
    CLEAN_GET_SALES_DAILY_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function getSalesDailyReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SALES_DAILY:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status,
                state: "success",
            };

        case GET_SALES_DAILY_ERROR:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status,
                state: "error",
            };

        case CLEAN_GET_SALES_DAILY:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_GET_SALES_DAILY_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
