import React from "react";
import { Grid, Button } from "@mui/material";
import { buttonStyle, gridTextStyle } from "../style/PageTransferSaleStyle";

const SimForm = ({ generateFields, totalCost, handleAddItem }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={6} style={gridTextStyle}>
                        eSIM
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => handleAddItem("eSim")}
                            variant="contained"
                            style={buttonStyle}
                        >
                            +
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={6} style={gridTextStyle}>
                        SIM
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => handleAddItem("sim")}
                            variant="contained"
                            style={buttonStyle}
                        >
                            +
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                    {generateFields()}
                </Grid>
            </Grid>
            <Grid item xs={12} style={gridTextStyle}>
                <div>Total seleccionado: {totalCost}</div>
            </Grid>
        </Grid>
    );
};

export default SimForm;
