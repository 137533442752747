export const ModalCustomHook = (props) => {
    const {
        setOpenModal,
        titleModal,
        subTitleModal,
        actionRequest,
        isLoading,
        setIsLoading,
        setAlert,
        errorMssge,
        severityAlert,
        clearFunction,
    } = props || {};

    const handleSubmit = () => {
        setIsLoading(true);
        actionRequest();
    };

    const closePopup = () => {
        setOpenModal(false);
        clearFunction();
        setAlert(false);
    };
    return {
        handleSubmit,
        closePopup,
        titleModal,
        subTitleModal,
        severityAlert,
        errorMssge,
        isLoading,
    };
};
