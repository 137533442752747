import React from "react";
import { Grid, Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import { ModalCustomHook } from "../../hooks/modalCustomHook";

function ModalCustomActions(props) {
    const {
        handleSubmit,
        closePopup,
        titleModal,
        subTitleModal,
        severityAlert,
        errorMssge,
        isLoading,
    } = ModalCustomHook(props);

    return (
        <Grid container className="modalBackground">
            <Grid container xs={4} className="modalContainer">
                <Grid container>
                    <Grid item xs={6} className="title">
                        <h2>{titleModal}</h2>
                    </Grid>

                    <Grid item xs={6} className="titleCloseBtn">
                        <button onClick={closePopup}>
                            <CloseIcon />
                        </button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <h3> {subTitleModal}</h3>
                </Grid>

                <Grid item xs={12} className="body"></Grid>
                <Grid item xs={12} paddingTop={2}>
                    <Collapse in={alert}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={closePopup}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity={severityAlert}
                        >
                            {errorMssge}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={12} className="footer">
                    <button onClick={closePopup} id="cancelBtn">
                        Cancelar
                    </button>
                    <LoadingButton
                        size="small"
                        onClick={handleSubmit}
                        endIcon={<SendIcon />}
                        loading={isLoading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Cambiar
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ModalCustomActions;
