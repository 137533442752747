export const containerStyle = {
    position: "relative",
};

export const loaderContainerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

export const itemContainerStyle = {
    marginBottom: "10px",
    textAlign: "center",
};

export const typeTextStyle = (type) => ({
    backgroundColor: type === "simValues" ? "#A34398" : "#4C2484",
    borderRadius: "10px",
    color: "white",
    padding: "0.5em 1em",
    width: "100%",
});

export const iconButtonStyle = {
    fontSize: 30,
};

export const buttonStyle = {
    marginLeft: "5px",
};

export const gridTextStyle = {
    textAlign: "center",
};

export const addressStyles = {
    textField: {
        mb: 2,
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                borderColor: "#6C63FF",
            },
        },
    },
};

export const ClientFormStyle = {
    textField: {
        mb: 2,
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                borderColor: "#6C63FF",
            },
        },
    },
};
