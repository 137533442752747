import { getLoginDataHelper } from "./getLoginData";

export const checkPermission = (permission) => {
    let { userPermissions } = getLoginDataHelper();

    if (!userPermissions) {
        console.error("userPermissions is null or undefined");
        return false;
    }

    userPermissions = userPermissions.split(",");

    for (let i = 0; i < userPermissions.length; i++) {
        const user_permission = userPermissions[i];

        if (user_permission === permission) {
            return true;
        }
    }
    return false;
};
