import { useContext } from "react";

import { GiftOrdersContext } from "../../data/giftOrdersContexts";

export const useGiftOrdersDetail = () => {
    // Obtener el contexto del módulo y sus propiedades
    const giftOrdersContext = useContext(GiftOrdersContext);

    // Desestructuración de las propiedades del contexto
    const { reduxProps, reducerProps, moduleMethods } = giftOrdersContext || {};

    // Desestructuración de las propiedades del Reducer
    const { giftOrdersReducer } = reducerProps || {};
    const { openModal } = giftOrdersReducer || {};

    // Desestructuración de las propiedades del Redux
    const { getGiftOrdersDetail } = reduxProps || {};
    const { response } = getGiftOrdersDetail || {};

    // Desestructuración de los "métodos del módulo"
    const { handleOpenModal, handleCloseModal } = moduleMethods || {};

    // Se define los arreglos de botones que se usarán en el modal
    const buttons = [
        {
            label: "Aceptar",
            onClick: handleCloseModal,
            variant: "text",
        },
    ];

    // Propiedades / variables / estados y métodos / funciones que se usarán en los componentes
    return {
        openModal,
        handleOpenModal,
        handleCloseModal,
        buttons,

        order_details: response,
    };
};
