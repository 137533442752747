import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";
import moment from "moment";

// Función para obtener detalles del producto
export const getProductDetails = (orderDetails) => [
    {
        label: "Teléfono",
        value: orderDetails?.direct_number,
    },
    {
        label: "Precio",
        value: <CurrencyFormatter amount={orderDetails?.product_amount} />,
    },
    {
        label: "Producto",
        value: orderDetails?.product_name,
    },

    {
        label: "Descuento",
        value: <CurrencyFormatter amount={orderDetails?.discount} />,
    },
];

// Función para obtener detalles básicos de la transacción
export const getBasicTransactionDetails = (orderDetails) => [
    {
        label: "Autorización:",
        value: orderDetails?.authorization_code,
    },
    {
        label: "id transacción:",
        value: orderDetails?.transaction_id,
    },
    {
        label: "Estado:",
        value: orderDetails?.status,
    },
    {
        label: "Número transacción:",
        value: orderDetails?.transaction_number,
    },
];

// Función para obtener detalles avanzados de la transacción
export const getAdvancedTransactionDetails = (orderDetails) => {
    const transactionCompletedAt = orderDetails?.transaction_completed_at;
    const transactionStartedAt = orderDetails?.transaction_started_at;

    const timeDifference =
        transactionCompletedAt && transactionStartedAt
            ? moment(transactionCompletedAt).diff(
                  moment(transactionStartedAt),
                  "seconds"
              )
            : "N/A";

    return [
        {
            label: "Fecha BD",
            value: convertToMexicoTime(orderDetails?.transaction_client_time),
        },

        {
            label: "Tiempo de respuesta",
            value:
                timeDifference !== "N/A" ? `${timeDifference} segundos` : "N/A",
        },
        {
            label: "Expira",
            value: convertToMexicoTime(orderDetails?.offer_expires_at),
        },
    ];
};

// Función para obtener detalles del distribuidor
export const getDistributorDetails = (orderDetails) => [
    {
        label: "Distribuidor",
        value: orderDetails?.distributor_user,
    },
    {
        label: "Portal",
        value: orderDetails?.source,
    },
];

// Función para obtener detalles de la respuesta
export const getResponseDetails = (orderDetails) => [
    {
        label: "Código",
        value: orderDetails?.response_code,
    },
    {
        label: "Mensaje",
        value: orderDetails?.response_message,
    },
    {
        label: "Autofacturado",
        value: orderDetails?.self_invoiced ? "Sí" : "No",
    },
    {
        label: "Secuencia compra",
        value: orderDetails?.purchase_sequence,
    },
];
