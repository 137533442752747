import axiosTokenInstance from "../../../services/api/backoffice";
import { CHANGE_NIR, CHANGE_NIR_ERROR } from "_models/redux/types";

// Action function
export const postChangeNir = (params) => async (dispatch) => {
    const { ticket_id, nirNumber } = params || {
        ticket_id: undefined,
        nirNumber: undefined,
    };

    const paramsData = {
        nir: parseInt(nirNumber),
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `/api/support/tickets/${ticket_id}/actions/nir-change`,
            paramsData
        );

        dispatch({
            type: CHANGE_NIR,
            payload: response || {},
        });
    } catch (e) {
        dispatch({
            type: CHANGE_NIR_ERROR,
            payload: e || {},
        });
    }
};
