import axiosTokenInstance from "services/api/backoffice";
import {
    DETAIL_LIST_SIM_IPB,
    DETAIL_LIST_SIM_IPB_ERROR,
} from "_models/redux/types";

// Action function
export const detailListSimIpbAction = (transactions_id) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/reports/ipb/distributors/detail/sims-orders/${transactions_id}`
        );

        const { data, status } = response || {};

        dispatch({
            type: DETAIL_LIST_SIM_IPB,
            response: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: DETAIL_LIST_SIM_IPB_ERROR,
            response: e || {},
        });
    }
};
