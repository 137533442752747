import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { HelpdeskContext } from "../../_context";
import { CircularProgress } from "@mui/material";

export const useHelpdeskAddPopup = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const helpdeskContext = useContext(HelpdeskContext);

    const { postCreateIncidence, handleClose } = helpdeskContext;

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            setIsSubmitting(true);
            postCreateIncidence(data);
        } catch (error) {
            console.error("Error al guardar:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        handleClose();
        reset();
    };

    return {
        errors,
        handleSubmit,
        onSubmit,
        register,
        isSubmitting,
        setIsSubmitting,
        CircularProgress,
        handleCancel,
    };
};
