import axiosTokenInstance from "services/api/backoffice";
import {
    POST_BIND_ASSISTED_ORDER,
    POST_BIND_ASSISTED_ORDER_ERROR,
} from "_models/redux/types";

// Action function
export const postBindAssistedOrderAction = (params) => async (dispatch) => {
    const { order_number } = params || {};

    const url = `/api/callcenter/actions/orders/${order_number}/bind-assisted-order`;

    try {
        const response = await axiosTokenInstance.post(`${url}`);

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: POST_BIND_ASSISTED_ORDER,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: POST_BIND_ASSISTED_ORDER_ERROR,
        });
    }
};
