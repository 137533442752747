import axiosTokenInstance from "services/api/backoffice";
import {
    CHANGE_SIM_TO_ESIM,
    CHANGE_SIM_TO_ESIM_ERROR,
} from "_models/redux/types";

export const changeSimToEsimAction = (params) => async (dispatch) => {
    const { reason, sim_order_id } = params || {};

    try {
        const response = await axiosTokenInstance.patch(
            `/api/orders/${sim_order_id}/change-sim-to-esim`,
            { reason }
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || null,
            type: CHANGE_SIM_TO_ESIM,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            status: e?.response?.status || null,
            type: CHANGE_SIM_TO_ESIM_ERROR,
        });
    }
};
