import { LoadingButton } from "@mui/lab";

// Definición de las columnas del datagrid
export const giftOrdersDatagridColumns = ({ handleActionOrderDetail }) => {
    return [
        {
            field: "order_number",
            headerName: "Número de orden",
            width: 200,
            renderCell: (params) => {
                const { row } = params || {};
                const { id, order_number } = row || {};
                return (
                    <div>
                        <LoadingButton
                            fullWidth
                            property="textAlign"
                            size="small"
                            onClick={() => handleActionOrderDetail(id)}
                            className="button"
                            sx={{
                                fontSize: "17px",
                                color: "#624591",
                            }}
                        >
                            {order_number}
                        </LoadingButton>
                    </div>
                );
            },
        },
        {
            field: "purchase_created",
            headerName: "Fecha de compra",
            width: 310,
        },
        {
            field: "gift_created",
            headerName: "Fecha de regalo",
            width: 310,
        },
        {
            field: "from_name",
            headerName: "De:",
            width: 310,
        },
        {
            field: "to_name",
            headerName: "Para:",
            width: 310,
        },
        {
            field: "initial_direct_number",
            headerName: "Número inicial",
            width: 310,
        },
        {
            field: "direct_number",
            headerName: "Númeron final",
            width: 310,
        },

        {
            field: "email",
            headerName: "Correo electrónico",
            width: 310,
        },
        {
            field: "status",
            headerName: "Estatus",
            width: 310,
        },
    ];
};
