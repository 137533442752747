import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { getStaticStates } from "_models/data/states";
import DynamicProductFields from "../components/DynamicProductFields";
import { useNavigate } from "react-router-dom";
export const TranferSaleHook = ({
    offers,
    neighborhood,
    municipality,
    municipalityAction,
    neighborhoodAction,
    getOffers,
    offersForSimPurchaseAction,
    ofersSimPurchase,
    transferSalesAction,
    transferSales,
    transferSalesClean,
}) => {
    const history = useNavigate();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [messageModal, setMessageModal] = useState("");
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const offersData = useMemo(() => {
        return ofersSimPurchase?.payload?.products || [];
    }, [ofersSimPurchase]);

    const [totalCost, setTotalCost] = useState(0);
    const [contactForServiceShrink, setContactForServiceShrink] =
        useState(false);
    const [isESimOrSimAdded, setIsESimOrSimAdded] = useState(false);
    const [simCount, setSimCount] = useState(0);
    const [eSimCount, setESimCount] = useState(0);
    const [nameShrink, setNameShrink] = useState(false);
    const [contactPhoneShrink, setContactPhoneShrink] = useState(false);
    const [emailShrink, setEmailShrink] = useState();
    const [addressShrink, setAddressShrink] = useState(false);
    const [zipcodeShrink, setZipcodeShrink] = useState(false);
    const [stateShrink, setStateShrink] = useState(false);
    const [municipalityShrink, setMunicipalityShrink] = useState(false);
    const [neighborhoodShrink, setNeighborhoodShrink] = useState(false);
    const [leadBackofficeStatusSelected, setLeadBackofficeStatusSelected] =
        useState(0);
    const [backofficeStatusShrink, setBackofficeStatusShrink] = useState(false);
    const [checkImeiShrink, setCheckImeiShrink] = useState(false);
    const [offerShrink, setOfferShrink] = useState(false);
    const [newOne, setNewOne] = useState();
    const [newTwo, setNewTwo] = useState();
    const [newThree, setNewThree] = useState();
    const [newFour, setNewFour] = useState(0);
    const [newFive, setNewFive] = useState();
    const [referenceInput, setReference] = useState(false);
    const { states } = getStaticStates();
    const { products } = offers?.payload || {};
    const { municipalities } = municipality?.payload || {};
    const { neighborhoods } = neighborhood?.payload || {};
    const hasSim = items.some((item) => item.type === "simValues");
    const userId = localStorage.getItem("user_id");

    // Formatear el total con separadores de miles y el signo de dólar
    const formattedTotal = totalCost?.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
    });

    const {
        reset,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const registerOptions = {
        nameInput: {
            required: "El nombre es requerido",
        },
        contactPhoneInput: {
            required: "El número de contacto es requerido",
        },
        contactForServiceInput: {
            required:
                "El número de contacto para servicio de mensajería es requerido",
        },
        addressInput: {
            required: "La dirección es requerida",
        },
        zipcodeInput: {
            required: "El código postal es requerido",
        },
        idInput: {
            required: "El paquete es requerido",
        },
        stateInput: {
            required: "El estado es requerido",
        },
        municipalityInput: {
            required: "El municipio es requerido",
        },
        neighborhoodInput: {
            required: "La colonia es requerida",
        },
        offerInput: {
            required: "La oferta es requerida",
        },
        backofficeStatusInput: {
            required: "El estatus es requerido",
        },
        referenceInput: {
            minLength: { value: 10, message: "Mínimo 10 caracteres" },
            required: "Este campo es requerido",
        },
    };

    const inputChange = (e) => {
        const inputValue = e?.target?.value;
        const inputName = e?.target?.name;

        if (inputValue === "") {
            switch (inputName) {
                case "nameInput":
                    setNameShrink(false);
                    break;
                case "contactPhoneInput":
                    setContactPhoneShrink(false);
                    break;
                case "contactForServiceInput":
                    setContactForServiceShrink(false);
                    break;

                case "emailInput":
                    setEmailShrink(false);
                    break;

                case "addressInput":
                    setAddressShrink(false);
                    break;
                case "zipcodeInput":
                    setZipcodeShrink(false);
                    break;
                case "referenceInput":
                    setReference(false);
                    break;
                default:
                    break;
            }
        } else {
            switch (inputName) {
                case "nameInput":
                    setNameShrink(true);
                    break;
                case "contactPhoneInput":
                    setContactPhoneShrink(true);
                    break;
                case "contactForServiceInput":
                    setContactForServiceShrink(true);
                    break;

                case "emailInput":
                    setEmailShrink(true);
                    break;

                case "addressInput":
                    setAddressShrink(true);
                    break;
                case "zipcodeInput":
                    setZipcodeShrink(true);
                    break;
                case "referenceInput":
                    setReference(true);
                    break;

                case "stateInput":
                    setNewOne(inputValue);
                    setValue(inputName, inputValue);
                    setStateShrink(true);
                    municipalityAction({ state_id: inputValue });
                    neighborhoodAction({ municipality_id: inputValue });
                    break;
                case "municipalityInput":
                    setNewTwo(inputValue);
                    setValue(inputName, inputValue);
                    setMunicipalityShrink(true);
                    neighborhoodAction({ municipality_id: inputValue });
                    break;
                case "neighborhoodInput":
                    setNewThree(inputValue);
                    setValue(inputName, inputValue);
                    setNeighborhoodShrink(true);
                    break;

                case "offerInput":
                    setNewFive(inputValue);
                    setValue(inputName, inputValue);
                    setOfferShrink(true);
                    break;

                case "backofficeStatusInput":
                    setLeadBackofficeStatusSelected(inputValue);
                    setValue(inputName, inputValue);
                    setBackofficeStatusShrink(true);
                    break;

                default:
                    break;
            }
        }
    };

    const handleNeighborhoodChange = (e) => {
        const selectedNeighborhoodId = e.target.value;
        const selectedNeighborhood = neighborhoods.find(
            (n) => n.id === Number(selectedNeighborhoodId)
        );

        // Actualiza el estado de la colonia seleccionada.
        inputChange(e); // Llama a tu función original para manejar otros cambios necesarios.

        if (selectedNeighborhood) {
            setValue("zipcodeInput", selectedNeighborhood.zipcode);

            // Actualiza el estado para asegurar que el label se "encoge" correctamente.
            setZipcodeShrink(true);

            const event = {
                target: {
                    name: "zipcodeInput",
                    value: selectedNeighborhood.zipcode,
                },
            };
            inputChange(event); // Asume que esto actualiza el valor del campo correctamente.
        }
    };

    //Bloque de componente SimForm
    const [selectedValues, setSelectedValues] = useState({
        eSimValues: [],
        simValues: [],
    });

    // Añade un efecto que se dispare cada vez que cambian las selecciones o la cuenta de SIM/eSIM
    useEffect(() => {
        const selectedProductNames = getSelectedProductNames(selectedValues);
        const isFreeShipping = checkIfFreeShipping(selectedProductNames);

        // Calcula y actualiza el costo total
        const calculatedTotal = calculateTotal(
            selectedProductNames,
            offersData,
            isFreeShipping
        );
        setTotalCost(calculatedTotal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValues, offersData, eSimCount, simCount]);

    useEffect(() => {
        const total = calculateTotal(items, offersData);
        setTotalCost(total);
    }, [items, offersData]);

    // Añade una función para obtener los nombres de los productos seleccionados basados en tus estados
    const getSelectedProductNames = (selectedValues) => {
        // Combina y filtra los arrays para obtener solo valores seleccionados no vacíos
        const selectedProducts = [
            ...selectedValues.eSimValues,
            ...selectedValues.simValues,
        ].filter(Boolean);
        return selectedProducts;
    };

    // Añade una función para verificar si el envío es gratuito basado en las selecciones
    const checkIfFreeShipping = (selectedProductNames) => {
        // Si hay productos seleccionados, verifica si alguno tiene envío gratuito.
        return selectedProductNames?.some((name) => {
            const product = offersData.find((p) => p.name === name);
            return product && product.free_shipping;
        });
    };

    useEffect(() => {
        if (Array.isArray(items) && Array.isArray(offersData)) {
            const total = calculateTotal(items, offersData);
            setTotalCost(total);
        }
    }, [items, offersData]); // Recalcula el total cuando 'items' o 'offersData' cambian

    // Función para calcular el costo total basado en las selecciones
    const calculateTotal = (items, offersData) => {
        let totalCost = 0;
        let shippingCostApplied = false;

        // Verifica si 'items' es un arreglo antes de usar forEach
        if (Array.isArray(items)) {
            items.forEach((item) => {
                const { quantity, productId, type } = item;

                if (quantity > 0) {
                    const product = offersData.find(
                        (p) => p.name === productId
                    );
                    if (product) {
                        let productCost = 0;

                        // Si es una SIM y free_sim es verdadero, el costo del producto es 0
                        if (type === "simValues" && product.free_sim) {
                            productCost = 0;
                        } else {
                            productCost =
                                product.offer !== null
                                    ? product.offer
                                    : product.price;
                        }

                        totalCost += productCost * quantity;

                        // Aplica el costo de envío solo si es una SIM y no se ha aplicado antes
                        if (
                            type === "simValues" &&
                            !shippingCostApplied &&
                            !product.free_shipping
                        ) {
                            totalCost += product.shipping_price;
                            shippingCostApplied = true;
                        }
                    }
                }
            });
        } else {
            console.error("Expected 'items' to be an array, received:", items);
        }

        return totalCost;
    };

    useEffect(() => {
        if (transferSales.status === 200) {
            setLoading(false);
            setDialogOpen(true);
            setMessageModal("Se agregó correctamente");
            reset();
        } else if (transferSales?.payload?.response?.status > 200) {
            setMessageModal(messageModal);
            setMessageModal(transferSales?.payload?.response?.data?.detail);
            setDialogOpen(true);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferSales]);

    useEffect(() => {
        return () => {
            transferSalesClean();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Actualiza el estado cada vez que eSimCount o simCount cambian
    useEffect(() => {
        // Preserva los valores existentes al ajustar el tamaño de los arreglos
        setSelectedValues((prevValues) => ({
            eSimValues: adjustArraySize(prevValues.eSimValues, eSimCount),
            simValues: adjustArraySize(prevValues.simValues, simCount),
        }));
    }, [eSimCount, simCount]);

    const handleAddItem = (type) => {
        const newItem = {
            type: type === "eSim" ? "eSimValues" : "simValues",
            quantity: "",
            productId: "",
        };
        setItems((prevItems) => [...prevItems, newItem]);
    };

    const handleRemoveItem = (index) => {
        setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };
    // Incrementa el conteo de eSIM
    const handleAddESim = () => {
        setESimCount(eSimCount + 1);
    };

    // Disminuye el conteo de eSIM
    const handleRemoveESim = () => {
        if (eSimCount > 0) {
            setESimCount(eSimCount - 1);
        }
    };

    // Incrementa el conteo de SIM
    const handleAddSim = () => {
        setSimCount(simCount + 1);
    };

    // Disminuye el conteo de SIM
    const handleRemoveSim = () => {
        if (simCount > 0) {
            setSimCount(simCount - 1);
        }
    };

    // Función para generar campos basados en el conteo de eSIM o SIM
    const handleESimChange = (event) => {
        const count = Number(event.target.value);
        setESimCount(count >= 0 ? count : 0); // Evita números negativos
    };

    // Función para generar campos basados en el conteo de eSIM o SIM
    const handleSimChange = (event) => {
        const count = Number(event.target.value);
        setSimCount(count >= 0 ? count : 0); // Evita números negativos
    };

    // Ajusta el tamaño del arreglo mientras preserva los valores existentes
    const adjustArraySize = (array, newSize) => {
        const newArray = array.slice(0, newSize); // Preserva los valores existentes hasta newSize
        while (newArray.length < newSize) {
            newArray.push(""); // Agrega elementos vacíos si el arreglo es más pequeño que newSize
        }
        return newArray;
    };

    // Función para manejar cambios en los campos generados
    const handleFieldChange = (index, value, field) => {
        setItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            )
        );
    };

    useEffect(() => {
        offersForSimPurchaseAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateFields = () => {
        return (
            <DynamicProductFields
                items={items}
                handleFieldChange={handleFieldChange}
                offersData={offersData}
                handleRemoveItem={handleRemoveItem}
            />
        );
    };
    //Bloque de componente SimForm

    const onSubmit = (formData) => {
        if (!isESimOrSimAdded) {
            return;
        }
        setLoading(true);
        const productsData = [];
        items.forEach((item) => {
            if (item.quantity > 0) {
                const productDetail = offersData.find(
                    (offer) => offer.name === item.productId
                );
                if (productDetail) {
                    productsData.push({
                        sim_type_id: item.type === "simValues" ? 1 : 2,
                        quantity: parseInt(item.quantity),
                        carrier_product_id: parseInt(
                            productDetail.carrier_product_id
                        ),
                    });
                }
            }
        });

        const payload = {
            total_amount: totalCost,
            lead_name: formData.nameInput,
            lead_email: formData.emailInput,
            lead_phone: formData.contactPhoneInput,
            backoffice_user_id: parseInt(userId),
            products: productsData,
        };

        // Agrega shipping solo si hay al menos una SIM agregada
        if (items.some((item) => item.type === "simValues")) {
            payload.shipping = {
                zipcode: formData.zipcodeInput,
                neighborhood_id: formData.neighborhoodInput,
                address: formData.addressInput,
                contact_phone: formData.contactForServiceInput,
                reference: formData.referenceInput,
            };
        }

        transferSalesAction(payload);
    };

    const handleEnviarClick = () => {
        if (!isESimOrSimAdded) {
            setShowErrorMessage(true);
        } else {
            handleSubmit(onSubmit)();
        }
    };

    const handleGoBack = () => {
        history("/sales/ejecutivo/list-transfer-sales");
    };

    const handleAccept = () => {
        history("/sales/ejecutivo/list-transfer-sales");
    };

    return {
        registerOptions,
        errors,
        contactForServiceShrink,
        nameShrink,
        contactPhoneShrink,
        emailShrink,
        addressShrink,
        zipcodeShrink,
        stateShrink,
        municipalityShrink,
        neighborhoodShrink,
        leadBackofficeStatusSelected,
        backofficeStatusShrink,
        checkImeiShrink,
        offerShrink,
        newOne,
        newTwo,
        newThree,
        newFour,
        newFive,
        setNameShrink,
        setContactPhoneShrink,
        setEmailShrink,
        setAddressShrink,
        setZipcodeShrink,
        setStateShrink,
        setMunicipalityShrink,
        setNeighborhoodShrink,
        setLeadBackofficeStatusSelected,
        setContactForServiceShrink,
        setBackofficeStatusShrink,
        setCheckImeiShrink,
        setOfferShrink,
        setNewOne,
        setNewTwo,
        setNewThree,
        setNewFour,
        setNewFive,
        setValue,
        register,
        products,
        states,
        municipalities,
        neighborhoods,
        municipalityAction,
        neighborhoodAction,
        getOffers,
        simCount,
        setSimCount,
        eSimCount,
        setESimCount,
        neighborhood,
        selectedValues,
        setSelectedValues,
        handleAddESim,
        handleRemoveESim,
        handleAddSim,
        handleRemoveSim,
        handleESimChange,
        handleSimChange,
        handleFieldChange,
        generateFields,
        inputChange,
        handleNeighborhoodChange,
        calculateTotal,
        formattedTotal,
        handleSubmit,
        onSubmit,
        offersData,
        handleAddItem,
        hasSim,
        loading,
        setDialogOpen,
        messageModal,
        dialogOpen,
        isESimOrSimAdded,
        setIsESimOrSimAdded,
        showErrorMessage,
        setShowErrorMessage,
        handleEnviarClick,
        handleGoBack,
        handleAccept,
        referenceInput,
        setReference,
    };
};
