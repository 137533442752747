import React from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { getEvents } from "_models/redux/events/action";
import { getNir } from "_models/redux/request-get-nir/action";
import { postChangeNir } from "_models/redux/post-change-nir/action";
import { cleanChangeNir } from "_models/redux/post-change-nir/clean";
import { cleanNirs } from "_models/redux/request-get-nir/clean";
import { ActionChangeNirHook } from "../../hooks/actionsTicketsHooks/actionChangeNirHook";
import ModalChangeNir from "../ModalChangeNir";

const ChangeNir = (props) => {
    const {
        onSubmit,
        handleClickButton,
        handleSelectState,
        handleSelectNIR,
        closePopup,
        stateValue,
        nirValueSelect,
        nirNumber,
        disableSelectNir,
        nirsMap,
        loaderButton,
        alert,
        errorMssge,
        severityAlert,
        sepomex,
        handleSubmit,
        error,
        changeNir,
        modalOpen,
    } = ActionChangeNirHook(props);

    return (
        <Grid item>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickButton}
                variant="outlined"
                className="button"
            >
                <h4>Cambio de NIR</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalChangeNir
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    closePopup={closePopup}
                    alert={alert}
                    error={error}
                    changeNir={changeNir}
                    severityAlert={severityAlert}
                    errorMssge={errorMssge}
                    loaderButton={loaderButton}
                    nirNumber={nirNumber}
                    handleSelectState={handleSelectState}
                    stateValue={stateValue}
                    sepomex={sepomex}
                    handleSelectNIR={handleSelectNIR}
                    nirValueSelect={nirValueSelect}
                    disableSelectNir={disableSelectNir}
                    nirsMap={nirsMap}
                />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    nir: state.nir,
    changeNirReducer: state.changeNir,
});
const mapDispatchToProps = {
    getEvents,
    getNir,
    postChangeNir,
    cleanChangeNir,
    cleanNirs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNir);
