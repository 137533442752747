import { useContext } from "react";

import { HelpdeskContext } from "../../_context";

export const useHelpdeskHeader = () => {
    const helpdeskContext = useContext(HelpdeskContext);
    const { setModalOpen, setFormValue } = helpdeskContext || {};
    const openMyModal = () => {
        setModalOpen(true);
    };

    const handleFormChange = (event) => {
        setFormValue(event.target.value);
    };

    return { openMyModal, handleFormChange };
};
