import axiosTokenInstance from "../../../services/api/backoffice";

import { BARRING_ACTION, BARRING_ACTION_ERROR } from "_models/redux/types";
// Action function
export const postBarringAction =
    (sessionIncidence = undefined) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(
                `/api/support/tickets/${sessionIncidence}/actions/barring`
            );

            const { data } = response || {};

            dispatch({
                type: BARRING_ACTION,
                payload: data || {},
            });
        } catch (e) {
            dispatch({
                type: BARRING_ACTION_ERROR,
                payload: e || {},
            });
        }
    };
