import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { getMySales } from "_models/redux/my-sales/action";
import { getMySalesDetails } from "_models/redux/my-sales-details/action";
import { cleanMySalesAction } from "_models/redux/my-sales/clean-action";
import { cleanMySalesDetailsAction } from "_models/redux/my-sales-details/clean-action";
import Loader from "widgets/loader/Loader";
import ChartComponent from "../components/Chart";
import Details from "../components/Details";
import { SalesDataTableHooks } from "../hooks/salesDataTableHooks";
import Filters from "../components/Filters";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const SalesDataTable = (props) => {
    const {
        optionsGrid,
        isLoading,
        rows,
        options,
        dropdownValue,
        handleClose,
        data,
        inputChange,
        handleMySalesDetails,
        displayDatepickers,
        startValue,
        setStartValue,
        setDropdownValue,
        setFromTime,

        endValue,
        setEndValue,
        setToTime,
    } = SalesDataTableHooks(props);

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Filters
                        options={options}
                        dropdownValue={dropdownValue}
                        inputChange={inputChange}
                    />
                    {dropdownValue === 1 && (
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            xl={6}
                            className={displayDatepickers}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"es"}
                            >
                                <DatePicker
                                    value={startValue}
                                    onChange={(newValue) => {
                                        setStartValue(newValue);
                                        setDropdownValue(1);
                                        setFromTime(
                                            moment(
                                                newValue.format("YYYY-MM-DD")
                                            ).format()
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"es"}
                            >
                                <DatePicker
                                    value={endValue}
                                    onChange={(newValue) => {
                                        setEndValue(newValue);
                                        setDropdownValue(1);
                                        setToTime(
                                            moment(
                                                newValue.format("YYYY-MM-DD")
                                            ).format()
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    )}
                    <Grid container className="my-sales container-1">
                        {data?.length > 1 && (
                            <Grid item xs={12} md={12}>
                                <ChartComponent
                                    data={data}
                                    optionsGrid={optionsGrid}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Details
                        handleClose={handleClose}
                        rows={rows}
                        handleMySalesDetails={handleMySalesDetails}
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    mySales: state?.mySales,
    mySalesDetails: state?.mySalesDetails,
});

const mapDispatchToProps = {
    getMySales,
    cleanMySalesAction,
    getMySalesDetails,
    cleanMySalesDetailsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesDataTable);
