import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import TabsTicket from "./tabs";
import CustomerProfileHeaderClient from "../userTickets/components/customer-profile-header-client";
import ActionsBar from "./components/actionsBar";
import HeaderTitle from "./components/titleHeader";
import { useLocation, useParams } from "react-router-dom";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import TicketInfo from "./components/ticket-info";
import ErrorPage from "./components/ErrorPage";
import { cleanTicketById } from "_models/redux/get-ticket-by-id/clean-action";

const DetailTicketsPage = (props) => {
    let location = useLocation();
    console.log("location:", location);
    let params = useParams(props);

    const titleTicket = props?.ticketById?.response?.title;
    const [ticketById, setTicketById] = useState();
    const [directNumberById, setDirectNumberByID] = useState();

    useEffect(() => {
        setTicketById(params.ticketId);

        props.getTicketById({ ticketId: params.ticketId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const response = props.ticketById.response || undefined;

        if (typeof response != "undefined") {
            setDirectNumberByID(response.direct_number);
            setTicketById(response.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ticketById]);

    return (
        <Grid container>
            {props?.ticketById?.status === 200 ? (
                <>
                    <Grid item xs={9} className="tabs-container">
                        <HeaderTitle
                            ticketById={props?.ticketById}
                            directNumber={directNumberById}
                            ticketId={ticketById}
                            titleTicket={titleTicket}
                        />
                        <TabsTicket />
                    </Grid>
                    <Grid item xs={3} className="ticket-bar">
                        <TicketInfo ticketId={ticketById} />
                        <CustomerProfileHeaderClient
                            ticketId={ticketById}
                            directNumber={directNumberById}
                        />
                        <ActionsBar />
                    </Grid>
                </>
            ) : props?.ticketById?.error?.response?.status > 200 ? (
                <ErrorPage
                    cleanTicketById={props.cleanTicketById}
                    messageResponseTickets={
                        props?.ticketById?.error?.response?.data?.detail
                    }
                />
            ) : null}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    tickets: state.tickets,
    ticketById: state.ticketById,
});

const mapDispatchToProps = {
    getTicketById,
    cleanTicketById,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTicketsPage);
