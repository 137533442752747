export const useModalWidgetHook = (props) => {
    const { setOpen } = props || {};

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return { handleClickOpen, handleClose };
};
