import {
    TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR,
    TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR,
    CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR,
    CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function totalPrepaidsBySubDistributorReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
