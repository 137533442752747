import { Grid, TextField } from "@mui/material";

export const CustomTextField = ({
    id,
    label,
    placeholder,
    register,
    registerOptions,
    onChange,
    errors,
}) => (
    <Grid item xs={6} md={2} className={"filter-container"}>
        <TextField
            fullWidth
            type="text"
            variant="outlined"
            label={label}
            placeholder={placeholder}
            id={id}
            name={id}
            {...register(id, registerOptions)}
            onChange={onChange}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        borderColor: "#6C63FF",
                    },
                },
            }}
        />

        {errors && (
            <span>
                <p className="required-incidence"> {errors?.message}</p>
            </span>
        )}
    </Grid>
);
