import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";

const ErrorModal = ({ handleClose, open }) => (
    <div>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Error
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    <h1>Oops algo salió mal....</h1>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    </div>
);

export default ErrorModal;
