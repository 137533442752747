import {
    RECHARGE_HISTORY,
    RECHARGE_HISTORY_ERROR,
    CLEAN_RECHARGE_HISTORY,
    CLEAN_RECHARGE_HISTORY_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    response: undefined,
    status: undefined,
    state: undefined,
};

export default function rechargeHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case RECHARGE_HISTORY:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                response: action?.response || {},
                status: action?.status || null,
                state: "success",
            };

        case RECHARGE_HISTORY_ERROR:
            return {
                ...state,
                loaded: true,
                response: action?.response || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_RECHARGE_HISTORY:
            return {
                ...state,
                loaded: false,
                response: action?.response || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_RECHARGE_HISTORY_ERROR:
            return {
                ...state,
                loaded: false,
                response: action?.response || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
