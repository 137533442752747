import React from "react";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { lockImei } from "_models/redux/request-lock-imei/action";
import { getEvents } from "_models/redux/events/action";
import { cleanLockImei } from "_models/redux/request-lock-imei/clean";
import { ActionLockImeiHook } from "../../hooks/actionsTicketsHooks/actionLockImeiHook";
import ModalLockImei from "../ModalLockImei";

const LockImeiContainer = (props) => {
    const {
        modalOpenLockImei,
        setModalOpenLockImei,
        isLoading,
        errorMssge,
        severityAlert,
        alert,
        handleLockImei,
        closePopup,
        imeiNumber,
    } = ActionLockImeiHook(props);

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={() => {
                    setModalOpenLockImei(true);
                }}
                variant="outlined"
                className="button"
            >
                <h4>Bloquear IMEI</h4>
            </LoadingButton>

            {modalOpenLockImei && (
                <ModalLockImei
                    closePopup={closePopup}
                    alert={alert}
                    severityAlert={severityAlert}
                    errorMssge={errorMssge}
                    handleLockImei={handleLockImei}
                    isLoading={isLoading}
                    imeiNumber={imeiNumber}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    lockImei_: state.lockImei,
    events: state.events,
});
const mapDispatchToProps = {
    lockImei,
    getEvents,
    cleanLockImei,
};

export default connect(mapStateToProps, mapDispatchToProps)(LockImeiContainer);
