import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

export const useCustomComponenteMakePrepaidHook = ({
    productsPrepaid,
    ActionMakePrepaid,
    ticketId,
    makePrepaid,
    listcommentsTikets,
}) => {
    const { status } = makePrepaid || {};
    const { payload } = productsPrepaid || {};
    const { products } = payload || {};
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [isCompra, setIsCompra] = useState(true);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        setLoading(true);
        const payload = {
            val: {
                ...data,
                is_paid: isCompra,
            },
            ticketId: ticketId,
        };
        ActionMakePrepaid(payload);
    };

    const handleSelectChange = (event) => {
        const productId = event.target.value;
        setValue("product_id", productId);
    };

    const handlePaidChange = (event) => {
        const value = event.target.value === "true";
        setIsCompra(value);
    };

    const handleCommentChange = (event) => {
        setValue("reason", event.target.value);
    };

    useEffect(() => {
        if (status === 200) {
            setLoading(false);
            setStatusMessage("El registro fue exitoso");
            listcommentsTikets(ticketId);
        } else if (makePrepaid?.payload?.response?.status > 200) {
            setLoading(false);
            setStatusMessage(makePrepaid?.payload?.response?.data?.detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, makePrepaid]);

    return {
        products,
        register,
        handleSubmit,
        handleSelectChange,
        handleCommentChange,
        onSubmit,
        loading,
        statusMessage,
        errors,
        setValue,
        isCompra,
        setIsCompra,
        handlePaidChange,
    };
};
