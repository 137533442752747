import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ActionsMenu = ({ onFraud, onChargeback }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <Button
                        onClick={() => {
                            onFraud();
                            handleClose();
                        }}
                    >
                        Fraude
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        onClick={() => {
                            onChargeback();
                            handleClose();
                        }}
                    >
                        Contracargo
                    </Button>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ActionsMenu;
