import axiosTokenInstance from "services/api/backoffice";
import { PUT_LEAD, PUT_LEAD_ERROR } from "_models/redux/types";

// Action function
export const putLeadAction = (params) => async (dispatch) => {
    const { lead_id, body } = params || {};

    const dispatchObj = {
        method: "put",
        status: undefined,
        payload: undefined,
        type: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `/api/callcenter/leads/${lead_id}/generate-payment-link`,
            body
        );

        const { data, status } = response || {};

        dispatchObj.type = PUT_LEAD;
        dispatchObj.status = status || {};
        dispatchObj.payload = data || {};

        dispatch(dispatchObj);
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatchObj.type = PUT_LEAD_ERROR;
        dispatchObj.status = status || {};
        dispatchObj.payload = data || {};

        dispatch(dispatchObj);
    }
};
