import { useDailySalesManagerPageHook } from "./hooks/page-hook";

import DailySalesManagerContainer from "./components/container-component";

const DailySalesManagerPage = () => {
    const { permission, userPermissions } = useDailySalesManagerPageHook();

    return userPermissions
        ?.split(",")
        ?.find((element) => element === permission) ? (
        <>
            <h4> Ventas por día </h4>
            <DailySalesManagerContainer />
        </>
    ) : null;
};

export default DailySalesManagerPage;
