import React from "react";
import {
    Popover,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Button,
} from "@mui/material";

const TicketDetailsPopover = ({
    ticket,
    open,
    handleClose,
    positionX,
    positionY,
    dataUsersList,
    handleChangeUser,
}) => {
    return (
        <Popover
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{
                top: positionY,
                left: positionX,
            }}
        >
            <h3>Asignar ticket a :</h3>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 500,
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "scroll",
                    maxHeight: 260,
                    "& ul": { padding: 0 },
                }}
            >
                {dataUsersList?.map((elem, index) => {
                    return (
                        <ListItem key={index}>
                            <Button
                                onClick={() =>
                                    handleChangeUser({
                                        ticketId: ticket.id,
                                        id: elem.id,
                                    })
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar className={"avatar"}>
                                        {elem.name[0]}
                                        {elem.last_name[0]}
                                        {elem.mother_last_name === undefined
                                            ? ""
                                            : elem.mother_last_name}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${elem.name} ${elem.last_name} ${
                                        elem.mother_last_name === undefined
                                            ? ""
                                            : elem.mother_last_name
                                    }`}
                                />
                            </Button>
                        </ListItem>
                    );
                })}
            </List>
        </Popover>
    );
};

export default TicketDetailsPopover;
