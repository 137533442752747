import React from "react";
import { connect } from "react-redux";
import { Grid, Container, Button } from "@mui/material";
import "moment/locale/es";

import MonthlySalesReportComponent from "./component";
import LeadsByCampaingFilters from "modules/dashoboard/filters/filters";

import { MonthlySalesContext } from "../data/context";
import { useMonthlySalesReportContainerHook } from "../hooks/container-hook";

import { getSalesMonthlyAction } from "_models/redux/get-sales-monthly/action";
import { cleanGetSalesMonthlyAction } from "_models/redux/get-sales-monthly/clean-action";

const MonthlySalesReportContainer = (props) => {
    const { parentProperties } = useMonthlySalesReportContainerHook(props);
    const { methods } = parentProperties || {};
    const { loadData } = methods || {};

    return (
        <MonthlySalesContext.Provider value={parentProperties}>
            <Container className="kpi-container">
                <Grid container>
                    <Grid item xs={12}>
                        <LeadsByCampaingFilters
                            parentProperties={parentProperties}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className="filters-button-container">
                            <Button
                                type="button"
                                variant="contained"
                                onClick={loadData}
                            >
                                Cargar
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <MonthlySalesReportComponent
                            parentProperties={parentProperties}
                        />
                    </Grid>
                </Grid>
            </Container>
        </MonthlySalesContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    getSalesMonthly: state.getSalesMonthly,
});

const mapDispatchToProps = {
    getSalesMonthlyAction,
    cleanGetSalesMonthlyAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlySalesReportContainer);
