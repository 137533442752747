import React from "react";
import { getImei } from "_models/redux/request-imei/action";
import { connect } from "react-redux";
import ModalDeviceInfo from "../modalDeviceInfo";
import { LoadingButton } from "@mui/lab";
import { listcommentsTikets } from "_models/redux/request-list-comments/action";
import { ActionImeiHook } from "../../hooks/actionsTicketsHooks/ActionImeiHook";

const ActionImei = (props) => {
    const { handleActionInfo, modalOpenDeviceInfo, setModalOpenDeviceInfo } =
        ActionImeiHook(props);

    return (
        <form>
            <div>
                <LoadingButton
                    fullWidth
                    property="textAlign"
                    size="small"
                    onClick={handleActionInfo}
                    variant="outlined"
                    className="button"
                >
                    <h4>Dispositivo</h4>
                </LoadingButton>
                {modalOpenDeviceInfo && (
                    <ModalDeviceInfo
                        modalOpen={modalOpenDeviceInfo}
                        setOpenModal={setModalOpenDeviceInfo}
                    />
                )}
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    getActionImei: state.getActionImei,
});
const mapDispatchToProps = {
    listcommentsTikets,
    getImei,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionImei);
