import React, { useState } from "react";
import { TextField, Grid, Paper, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CustomDialog from "widgets/component/CustomDialog";
import CustomCard from "modules/shipping/_detailShipping/_sections/card/card";

const FiltersComponentDetails = () => {
    const [fechaCompra, setFechaCompra] = useState(null);
    const [fechaEnvio, setFechaEnvio] = useState(null);
    const [numeroOrden, setNumeroOrden] = useState("");
    const [nombre, setNombre] = useState("");
    const [telefono, setTelefono] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleGuardarCompra = () => {
        setIsDialogOpen(true);
    };

    const handleCustomAction = () => {
        // Función que deseas ejecutar cuando se hace clic en el botón.
    };

    return (
        <>
            <Paper elevation={3} style={{ padding: 20 }}>
                <Typography variant="h5" gutterBottom>
                    Detalles de Compra
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2} className="datepicker-item">
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"es"}
                            className="datepicker-location"
                        >
                            <DatePicker
                                label="Fecha de Compra"
                                value={fechaCompra}
                                onChange={(date) => setFechaCompra(date)}
                                fullWidth
                                className="datepicker-cls"
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"es"}
                        >
                            <DatePicker
                                label="Fecha de Envío"
                                value={fechaEnvio}
                                onChange={(date) => setFechaEnvio(date)}
                                fullWidth
                                className="datepicker-cls"
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="Número de Orden"
                            variant="outlined"
                            value={numeroOrden}
                            onChange={(e) => setNumeroOrden(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="Nombre"
                            variant="outlined"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="Teléfono"
                            variant="outlined"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconButton
                            color="primary"
                            aria-label="Buscar"
                            onClick={handleGuardarCompra}
                            className="search-button"
                            style={{
                                backgroundColor: "#642BA5",
                                color: "white",
                                borderRadius: "6px",
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                                width: "60px",
                                height: "100%",
                            }}
                            size="large"
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <CustomDialog
                title="Detalle del envío"
                content={<CustomCard />}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                fullWidth
                maxWidth="sm"
                buttonPosition="center" // Agrega la propiedad posición
                extraButtons={[
                    {
                        label: "Asignar número de guía",
                        variant: "contained",
                        buttonColor: "#642BA5", // Agrega la propiedad para configurar el color del botón
                        textColor: "white", // Agrega la propiedad para configurar el color del texto del botón
                        onClick: handleCustomAction,
                    },
                ]}
            />
        </>
    );
};

export default FiltersComponentDetails;
