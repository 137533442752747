import {
    IMEI,
    IMEI_ERROR,
    IMEI_CLEAN,
    IMEI_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    imeiObj: [],
    loading: false,
};

export default function imeiReducer(state = initialState, action) {
    switch (action.type) {
        case IMEI:
        case IMEI_CLEAN:
            return {
                ...state,
                imeiObj: action.payload,
                loading: true,
            };

        case IMEI_ERROR:
        case IMEI_CLEAN_ERROR:
            return {
                ...state,
                imeiObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
