// Import: Middleware Action & Custom Types
import { CLEAN_PREPAID, CLEAN_PREPAID_ERROR } from "_models/redux/types";

// Action function to clean prepaid data
export const cleanPrepaidAction = () => async (dispatch) => {
    // Dispatch actions to clean prepaid data
    try {
        dispatch({
            type: CLEAN_PREPAID,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_PREPAID_ERROR,
            payload: undefined,
        });
    }
};
