import { connect } from "react-redux";
import { Typography } from "@mui/material";
import DateFormComponent from "./DateFormComponent";
import DataTableComponent from "./DataTableComponent";
import DialogsComponent from "./DialogsComponent";
import { ContentRechargeHistoryHooks } from "../hooks/contentRechargeHistoryHooks";
import { rechargeHistoryAction } from "_models/redux/recharge-history/action";
import { rechargeHistoryClean } from "_models/redux/recharge-history/clean-action";
import { getPrepaid } from "_models/redux/prepaid-history/action";
import { cleanPrepaidAction } from "_models/redux/prepaid-history/clean-action";
import { cleanReportTransactionsFraud } from "_models/redux/report-transaction-fraud/clean_action";
import { actionReportTransactionsFraud } from "_models/redux/report-transaction-fraud/action";
import { cleanReportPrepaidChargebackDetails } from "_models/redux/report-transaction-chargeback/clean_action";
import { actionReportTransactionsChargeback } from "_models/redux/report-transaction-chargeback/action";

const ContentRechargeHistory = ({
    ticketId,
    rechargeHistoryAction,
    rechargeHistory,
    rechargeHistoryClean,
    prepaid,
    getPrepaid,
    cleanPrepaidAction,
    reportTransactionsFraud,
    reportTransactionsChargeback,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    transactionId,
    onClose,
}) => {
    const {
        pagination,
        Columns,
        onSubmit,
        mappedRows,
        register,
        handleSubmit,
        size,
        errors,
        page,
        isDialogOpenResponse,
        dialogMessage,
        loading,
        handleClose,
        handleOpenPopUp,
        setOpenActionFraud,
        isDialogOpenDetails,
        openActionFraud,
        handleCloseFraudChargeback,
        error,
        loadingFraudAndChargeback,
        handleConfirm,
        setDescriptionTransaction,
        setError,
        isError,
    } = ContentRechargeHistoryHooks({
        ticketId,
        rechargeHistoryAction,
        rechargeHistory,
        rechargeHistoryClean,
        prepaid,
        getPrepaid,
        cleanPrepaidAction,
        reportTransactionsFraud,
        reportTransactionsChargeback,
        cleanReportPrepaidChargebackDetails,
        actionReportTransactionsChargeback,
        actionReportTransactionsFraud,
        cleanReportTransactionsFraud,
        transactionId,
        onClose,
    });

    return (
        <>
            <DateFormComponent
                register={register}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
            />
            <Typography
                variant="h6"
                style={{ marginTop: "20px", textAlign: "center" }}
            >
                Resultados del Reporte
            </Typography>
            <DataTableComponent
                loading={loading}
                mappedRows={mappedRows}
                Columns={Columns}
                pagination={pagination}
                page={page}
                size={size}
            />
            <DialogsComponent
                isDialogOpenDetails={isDialogOpenDetails}
                prepaid={prepaid}
                handleClose={handleClose}
                isDialogOpenResponse={isDialogOpenResponse}
                dialogMessage={dialogMessage}
                openActionFraud={openActionFraud}
                handleCloseFraudChargeback={handleCloseFraudChargeback}
                setDescriptionTransaction={setDescriptionTransaction}
                error={error}
                setError={setError}
                loadingFraudAndChargeback={loadingFraudAndChargeback}
                handleConfirm={handleConfirm}
                isError={isError}
                reportTransactionsFraud={reportTransactionsFraud}
                reportTransactionsChargeback={reportTransactionsChargeback}
                handleOpenPopUp={handleOpenPopUp}
                setOpenActionFraud={setOpenActionFraud}
                loading={loading}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    rechargeHistory: state.rechargeHistory,
    prepaid: state.prepaid,
    reportTransactionsFraud: state.reportTransactionsFraud,
    reportTransactionsChargeback: state.reportTransactionsChargeback,
});

const mapDispatchToProps = {
    rechargeHistoryAction,
    rechargeHistoryClean,
    getPrepaid,
    cleanPrepaidAction,
    cleanReportTransactionsFraud,
    actionReportTransactionsFraud,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentRechargeHistory);
