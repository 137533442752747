import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => (
    <div className="loader-container">
        <CircularProgress size={80} />
    </div>
);

export default Loader;
