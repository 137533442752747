import React from "react";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";

export const DetailsToku = (orderDetails) => [
    {
        label: "Nombre del beneficiario",
        value: orderDetails?.customer_name,
    },
    {
        label: "ID cliente",
        value: orderDetails?.customer_id,
    },
];

export const payerFieldsToku = (orderDetails) => [
    { label: "Cuenta del pagador:", value: orderDetails?.payment_method },
    { label: "Institución del pagador:", value: orderDetails?.gateway },
];

export const getTransactionDetailsToku = (orderDetails) => [
    {
        label: "Fecha de operación",
        value: convertToMexicoTime(orderDetails?.toku_transaction_date),
    },
    {
        label: "Fecha de creación",
        value: convertToMexicoTime(orderDetails?.created),
    },
    { label: "ID de cuenta STP", value: orderDetails?.account_id },
    {
        label: "Estatus del Pago Toku",
        value: orderDetails?.toku_payment_status,
    },
    {
        label: "Código de Respuesta Toku",
        value: orderDetails?.toku_response_code,
    },
    {
        label: "Tipo de Evento",
        value: orderDetails?.event_type,
    },
];

export const getPaymentDetailsToku = (orderDetails) => [
    {
        label: "Monto",
        value: <CurrencyFormatter amount={orderDetails?.amount} />,
    },
    {
        label: "ID Solicitud de pago:",
        value: orderDetails?.payment_request_id,
    },
    {
        label: "Tipo de pago:",
        value:
            orderDetails?.installments === 1
                ? "Contado"
                : `${orderDetails?.installments} meses`,
    },
    {
        label: "Estatus del pago:",
        value: orderDetails?.payment_status,
    },
    {
        label: "Código de autorización:",
        value: orderDetails?.authorization_code,
    },
    {
        label: "Orden de compra:",
        value: orderDetails?.buy_order,
    },
    {
        label: "Detalle de tarjeta:",
        value: orderDetails?.card_detail,
    },
    {
        label: "Código de Tipo de pago:",
        value: orderDetails?.payment_type_code,
    },
];

export const getTrackingDetailsToku = (orderDetails) => [
    { label: "Folio codi", value: orderDetails?.codi_folio },
    { label: "UUID", value: orderDetails?.uuid },
    {
        label: "Clave de rastreo",
        value: orderDetails?.order_tracking_key,
    },
];
