import {
    CREATE_LEAD_BY_CHANNEL_CLEAN,
    CLEAN_CREATE_LEAD_BY_CHANNEL_ERROR,
} from "_models/redux/types";

export const cleanChannelLead = () => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_LEAD_BY_CHANNEL_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_CREATE_LEAD_BY_CHANNEL_ERROR,
            payload: [],
        });
    }
};
