import React from "react";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { connect } from "react-redux";
import { getSupplementaryService } from "_models/redux/get-supplementary-service/action";
import { cleanSupplementaryService } from "_models/redux/get-supplementary-service/clean-action";
import { postSupplementaryService } from "_models/redux/post-supplementary-service/action";
import { getEvents } from "_models/redux/events/action";
import { ActionSupplementaryServiceHook } from "../../hooks/actionsTicketsHooks/actionSupplementaryServiceHook";
import ModalVoiceServices from "../ModalVoiceServices";

const SupplementaryService = (props) => {
    const {
        modalOpen,
        hiddenComponent,
        tripartiteCallWaiting,
        showPrivateNumbers,
        typeCallForwarding,
        msisdnCallForwarding,
        isLoadingButton,
        alert,
        errorMssge,
        severityAlert,
        handleClickButton,
        handleCloseClean,
        handleChangeCallForwarding,
        handleChangetripartiteCallWaiting,
        handleChangeShowPrivateNumbers,
        handleChangeNumberCallForwarding,
        handleSubmitChangeSupplementaryService,
    } = ActionSupplementaryServiceHook(props);

    return (
        <Grid item>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickButton}
                variant="outlined"
                className="button"
            >
                <h4>Gestión de servicios de voz</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalVoiceServices
                    handleCloseClean={handleCloseClean}
                    hiddenComponent={hiddenComponent}
                    msisdnCallForwarding={msisdnCallForwarding}
                    handleChangeNumberCallForwarding={
                        handleChangeNumberCallForwarding
                    }
                    alert={alert}
                    errorMssge={errorMssge}
                    severityAlert={severityAlert}
                    isLoadingButton={isLoadingButton}
                    handleSubmitChangeSupplementaryService={
                        handleSubmitChangeSupplementaryService
                    }
                    typeCallForwarding={typeCallForwarding}
                    handleChangeCallForwarding={handleChangeCallForwarding}
                    tripartiteCallWaiting={tripartiteCallWaiting}
                    handleChangetripartiteCallWaiting={
                        handleChangetripartiteCallWaiting
                    }
                    showPrivateNumbers={showPrivateNumbers}
                    handleChangeShowPrivateNumbers={
                        handleChangeShowPrivateNumbers
                    }
                />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    supplementaryService: state.supplementaryService,
    changeSupplementaryService: state.changeSupplementaryService,
});
const mapDispatchToProps = {
    getSupplementaryService,
    cleanSupplementaryService,
    postSupplementaryService,
    getEvents,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplementaryService);
