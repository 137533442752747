import CustomDialog from "widgets/component/CustomDialog";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/componentDialog";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
} from "@mui/material";
import { CircularProgressStyle } from "modules/transactions-netpay/list-transactions-netpay/style/style";

const DialogsComponent = ({
    isDialogOpenDetails,
    prepaid,
    handleClose,
    isDialogOpenResponse,
    dialogMessage,
    openActionFraud,
    handleCloseFraudChargeback,
    setDescriptionTransaction,
    error,
    setError,
    loadingFraudAndChargeback,
    handleConfirm,
    isError,
    reportTransactionsFraud,
    reportTransactionsChargeback,
    setOpenActionFraud,
    handleOpenPopUp,
    loading,
}) => (
    <>
        <CustomDialog
            fullWidth
            title={
                prepaid?.response?.payment_info === null
                    ? "No hay datos"
                    : "Detalle de la Recarga"
            }
            content={
                loading ? (
                    <CircularProgress
                        sx={CircularProgressStyle.CircularProgress}
                    />
                ) : (
                    <DataTableDetailOrder
                        type="detailsHistoryPrepaid"
                        handleOpenPopUp={handleOpenPopUp}
                        setOpenActionFraud={setOpenActionFraud}
                        response={prepaid?.payload}
                        namePayment={prepaid?.payload?.payment_provider?.name}
                        idTransaction={
                            prepaid?.payload?.payment_provider?.transaction_id
                        }
                        onClose={handleClose}
                    />
                )
            }
            open={isDialogOpenDetails}
            onClose={handleClose}
            maxWidth="md"
            extraButtons={[
                {
                    label: "Aceptar",
                    variant: "contained",
                    onClick: handleClose,
                    buttonColor: "purple",
                    textColor: "white",
                },
            ]}
        />

        <CustomDialog
            title="Cambio de Usuario"
            content={dialogMessage}
            onClose={handleClose}
            open={isDialogOpenResponse}
            fullWidth
            maxWidth="xs"
            showCloseButton={false}
            extraButtons={[
                {
                    label: "Aceptar",
                    variant: "contained",
                    onClick: handleClose,
                    buttonColor: "#724ECB",
                    textColor: "white",
                },
            ]}
        />

        <Dialog open={openActionFraud} onClose={handleCloseFraudChargeback}>
            <DialogTitle>
                {"¿Estás seguro de que quieres ejecutar esta acción?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {"Una vez que ejecutes esta acción, no podrás deshacerla."}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Descripción"
                    type="text"
                    fullWidth
                    onChange={(event) => {
                        setDescriptionTransaction(event.target.value);
                        setError(event.target.value.length < 5);
                    }}
                    error={error}
                    helperText={
                        error ? "Este campo requiere al menos 5 caracteres" : ""
                    }
                />
                {loadingFraudAndChargeback && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                )}
                {dialogMessage && (
                    <DialogContentText
                        style={{ color: isError ? "red" : "green" }}
                    >
                        {dialogMessage}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseFraudChargeback} color="primary">
                    Cerrar
                </Button>
                {reportTransactionsFraud?.status !== 200 &&
                    reportTransactionsChargeback?.status !== 200 && (
                        <Button
                            onClick={handleConfirm}
                            color="primary"
                            autoFocus
                            disabled={error}
                        >
                            Aceptar
                        </Button>
                    )}
            </DialogActions>
        </Dialog>
    </>
);

export default DialogsComponent;
