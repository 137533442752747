import React from "react";
import { connect } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import "moment/locale/es";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Box } from "@mui/material";
import { getSaleOrdersAction } from "_models/redux/sales-orders/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomNoRowsOverlay from "widgets/datagrid/CustomNoRowsOverlay";
import CustomLinearProgress from "widgets/datagrid/CustomLinearProgress";
import { cleanSaleOrdersAction } from "_models/redux/sales-orders/clean-action";
import ModalDetailsInfo from "./modal-details-info";
import { DataGridHook } from "../hooks/dataGridHook";
import { datagridStyles } from "../style/datagridStyle";
import { CustomTextField } from "utils/components/fields/CustomTextField/CustomTextField";

const OrdersDatagrid = (props) => {
    const {
        columns,
        rows,
        datagridPageSize,
        rowCount,
        datagridPage,
        loadingDatagrid,
        modalOpenDetailOrder,
        inputChange,
        onSubmit,
        registerOptions,
        PopModalError,
        handleSubmit,
        dateValue,
        setDateValue,
        register,
        errors,
        changePage,
        changePageSize,
        setModalOpenDetailOrder,
        getRowClassName,
    } = DataGridHook(props);
    const { onClose } = props || {};

    return (
        <Grid container>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} md={2} className={"filter-container"}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"es"}
                            >
                                <DatePicker
                                    value={dateValue}
                                    onChange={(newValue) => {
                                        setDateValue(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <CustomTextField
                            id="numberOrder"
                            label="No. de orden"
                            placeholder="No. de orden"
                            register={register}
                            registerOptions={registerOptions?.numberOrder}
                            onChange={(e) => inputChange(e)}
                            errors={errors?.numberOrder}
                        />

                        <CustomTextField
                            id="nameOrder"
                            label="Nombre"
                            placeholder="Nombre"
                            register={register}
                            registerOptions={registerOptions?.nameOrder}
                            onChange={(e) => inputChange(e)}
                            errors={errors?.nameOrder}
                        />

                        <CustomTextField
                            id="phoneNumberOrder"
                            label="Teléfono"
                            placeholder="Teléfono"
                            register={register}
                            registerOptions={registerOptions?.phoneNumberOrder}
                            onChange={(e) => inputChange(e)}
                            errors={errors?.phoneNumberOrder}
                        />

                        <CustomTextField
                            id="emailOrder"
                            label="Correo electrónico"
                            placeholder="Correo electrónico"
                            register={register}
                            registerOptions={registerOptions?.emailOrder}
                            onChange={(e) => inputChange(e)}
                            errors={errors?.emailOrder}
                        />
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                className="m-0 h-55 small-button"
                                type="submit"
                            >
                                <SearchIcon className="svg" />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Box sx={{ height: "100%", maxWidth: "100%" }}>
                <DataGrid
                    getRowClassName={getRowClassName}
                    columns={columns}
                    rows={rows}
                    autoHeight={true}
                    pagination
                    pageSize={datagridPageSize}
                    rowCount={rowCount}
                    paginationMode="server"
                    onPageChange={(newPage) => changePage(newPage)}
                    onPageSizeChange={(newPageSize) =>
                        changePageSize(newPageSize)
                    }
                    rowsPerPageOptions={[50, 75, 100]}
                    page={datagridPage}
                    loading={loadingDatagrid}
                    disableColumnMenu
                    disableSelectionOnClick={true}
                    checkboxSelection={false}
                    overflow={true}
                    sx={datagridStyles}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        NoResultsOverlay: CustomNoRowsOverlay,
                        LoadingOverlay: CustomLinearProgress,
                    }}
                />
            </Box>
            <PopModalError />
            <ModalDetailsInfo
                setOpenModal={setModalOpenDetailOrder}
                openModal={modalOpenDetailOrder}
                onClose={onClose}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    saleOrders: state?.saleOrders,
    saleOrderDetail: state?.saleOrderDetail,
});

const mapDispatchToProps = {
    getSaleOrdersAction,
    cleanSaleOrdersAction,
    getSaleOrderDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDatagrid);
