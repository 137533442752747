import axiosTokenInstance from "../../../services/api/backoffice";

import { UPDATE_APN, UPDATE_APN_ERROR } from "_models/redux/types";

// Action function
export const postUpdateApn =
    (sessionIncidence = undefined) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(
                `/api/support/tickets/${sessionIncidence}/actions/send-apn`
            );

            const { data } = response || {};

            dispatch({
                type: UPDATE_APN,
                payload: data || {},
            });
        } catch (e) {
            dispatch({
                type: UPDATE_APN_ERROR,
                payload: e || {},
            });
        }
    };
