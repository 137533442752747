import { Grid } from "@mui/material";
import { useGiftOrdersDetail } from "modules/support-gift-orders/hooks/gift-orders-detail/useGiftOrdersDetail";

const GiftOrdersDetailClient = () => {
    // Hook propio de la funcionalidad del detalle,
    // destrucuturando el objeto que necesita la interfaz
    const { order_details } = useGiftOrdersDetail();

    return (
        <>
            <h2>Regalo</h2>

            <Grid container className="device-info">
                <Grid item xs={12}>
                    <div>
                        <span>Envío:</span> {order_details?.from_name}{" "}
                    </div>
                    <div>
                        <span>Recibió:</span> {order_details?.to_name}{" "}
                    </div>
                    <div>
                        <span>Correo:</span> {order_details?.email}{" "}
                    </div>
                    <hr></hr>
                    <div>
                        <p>Se regaló el {order_details?.gift_created} </p>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default GiftOrdersDetailClient;
