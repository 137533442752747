import React from "react";

const InputError = ({ errors }) => {
    return (
        <div className={"lead-form-input-error"}>
            <span>
                {errors && (
                    <p className="required-incidence m-0"> {errors.message}</p>
                )}
            </span>
        </div>
    );
};

export default InputError;
