import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const CheckBox = ({
    tripartiteCallWaiting,
    handleChangetripartiteCallWaiting,
    showPrivateNumbers,
    handleChangeShowPrivateNumbers,
}) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={tripartiteCallWaiting}
                        onChange={handleChangetripartiteCallWaiting}
                    />
                }
                label="Llamada tripartita"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showPrivateNumbers}
                        onChange={handleChangeShowPrivateNumbers}
                    />
                }
                label="Mostrar números privados"
            />
        </FormGroup>
    );
};

export default CheckBox;
