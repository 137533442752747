import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const TableTotalrechargesBySubDistributor = (props) => {
    const { rows } = props || {};

    return rows?.map((row) => (
        <TableRow
            key={row.name}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            <TableCell component="th" scope="row">
                {row.date}
            </TableCell>
            <TableCell align="center">{row.sub_distributor}</TableCell>
            <TableCell align="right">{row.prepaid}</TableCell>
        </TableRow>
    ));
};

export default TableTotalrechargesBySubDistributor;
