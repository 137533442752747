import { STATUS_LIST, STATUS_LIST_ERROR } from "_models/redux/types";

const initialState = {
    response: [],
    error: undefined,
    loading: false,
};

export default function statusListReducer(state = initialState, action) {
    switch (action.type) {
        case STATUS_LIST:
            return {
                ...state,
                response: action.payload,
                loading: true,
            };

        case STATUS_LIST_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
