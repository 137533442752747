import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";

export const getPaymentDetails = (orderDetails) => [
    {
        label: "Monto",
        value: <CurrencyFormatter amount={orderDetails?.amount} />,
    },
    {
        label: "Masked PAN",
        value: orderDetails?.masked_pan,
    },
    {
        label: "Estatus de pago",
        value: orderDetails?.payment_status,
    },
    {
        label: "Cuotas",
        value:
            orderDetails?.installments === 1
                ? "Contado"
                : `${orderDetails?.installments} meses`,
    },
    {
        label: "Código de autorización",
        value: orderDetails?.authorization_code || "No disponible",
    },
];

export const getRequestDetails = (orderDetails) => [
    {
        label: "ID Solicitud de pago",
        key: "payment_request_id",
        value: orderDetails?.payment_request_id,
    },
    {
        label: "Concepto de pago",
        key: "reference",
        value: orderDetails?.reference,
    },
    {
        label: "Mensaje",
        key: "message",
        value: orderDetails?.message,
    },
];

export const getTransactionDetails = (orderDetails) => [
    {
        label: "ID de la operación",
        value: orderDetails?.operation_id || "No disponible",
    },
    {
        label: "Tiempo de transacción",
        value: convertToMexicoTime(orderDetails?.transaction_time),
    },
    {
        label: "Fecha de creación",
        value: convertToMexicoTime(orderDetails?.created),
    },
];

export const getIdentifierDetails = (orderDetails) => [
    {
        label: "ID NetPay",
        value: orderDetails?.unique_identifier,
    },
    {
        label: "ID Dalefon",
        value: orderDetails?.uuid,
    },
];

export const getSecurityDetails = (orderDetails) => [
    {
        label: "3DS",
        value: orderDetails?.review_required ? "Sí" : "No",
    },
    {
        label: "Frictionless",
        value: orderDetails?.frictionless_confirmation ? "Sí" : "No",
    },
];
