import React from "react";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "./new-ticket";
import { useState } from "react";

const TicketHeader = () => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <Grid container direction={"row"}>
                    <Grid item xs={6} textAlign={"start"}>
                        <h3>Mis incidencias</h3>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                        <button
                            className="right openModalBtn"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            <SearchIcon className="svg" />
                            Buscar número telefónico
                        </button>

                        {modalOpen && (
                            <Modal
                                modalOpen={modalOpen}
                                setOpenModal={setModalOpen}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TicketHeader;
