import { useContext } from "react";

import { DrawerHeader } from "../styles/layoutStyles";

import { LayoutContext } from "../data/layoutContext";

export const useLayoutMenuHeader = () => {
    const { layoutVariables, methods } = useContext(LayoutContext) || {};
    const { theme } = layoutVariables || {};
    const { layoutMethods } = methods || {};
    const { handleDrawerOpen, handleDrawerClose } = layoutMethods || {};

    return { DrawerHeader, theme, handleDrawerClose, handleDrawerOpen };
};
