import React from "react";
import { connect } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";

import CustomLinearProgress from "widgets/datagrid/CustomLinearProgress";
import CustomNoRowsOverlay from "widgets/datagrid/CustomNoRowsOverlay";

import { getLeads } from "_models/redux/leads/action";
import { cleanLeadsAction } from "_models/redux/leads/clean-action";
import { cleanLeadAssignmentAction } from "_models/redux/lead-assignment/clean_action";
import { setLeadAssignmentAction } from "_models/redux/lead-assignment/action";

import { useShippingDetailDatagrid } from "./_hook";

const ShippingDetailDatagridComponent = (props) => {
    const {
        columns,
        rows,
        datagridPageSize,
        rowCount,
        datagridPage,
        loadingDatagrid,
        changePage,
        changePageSize,
        datagridRegister,
        PopModalError,
    } = useShippingDetailDatagrid(props);

    return (
        <div style={{ height: "auto", width: "100%" }} className={"leads-list"}>
            <DataGrid
                columns={columns}
                rows={rows}
                autoHeight={true}
                pagination
                pageSize={datagridPageSize}
                rowCount={rowCount}
                paginationMode="server"
                onPageChange={(newPage) => changePage(newPage)}
                onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
                rowsPerPageOptions={[50, 75, 100]}
                page={datagridPage}
                loading={loadingDatagrid}
                disableColumnMenu
                disableSelectionOnClick={true}
                checkboxSelection={false}
                sx={datagridRegister}
                components={{
                    // Toolbar: GridToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    NoResultsOverlay: CustomNoRowsOverlay,
                    LoadingOverlay: CustomLinearProgress,
                }}
            />
            <PopModalError />
        </div>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    leadsObj: state?.leads,
    leadAssignment: state?.leadAssignment,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getLeads,
    cleanLeadsAction,
    setLeadAssignmentAction,
    cleanLeadAssignmentAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippingDetailDatagridComponent);
