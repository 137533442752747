import React from "react";

import { Grid, LinearProgress } from "@mui/material";

const CustomLinearProgress = () => {
    return (
        <Grid className="custom-linear-progress-container">
            <LinearProgress color="secondary" />
        </Grid>
    );
};

export default CustomLinearProgress;
