import axios from "axios";

const axiosAuthInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor for API calls
axiosAuthInstance.interceptors.request.use(
    async (config) => {
        const xApiKey = process.env.REACT_APP_X_API_KEY;

        config.headers["x-api-key"] = `${xApiKey}`;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosAuthInstance;
