import axiosTokenInstance from "services/api/backoffice";
import { MY_SALES, MY_SALES_ERROR } from "_models/redux/types";

// Action function
export const getMySales = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/callcenter/reports/sales/my-sales-per-day?from_time=${params.from_time}&to_time=${params.to_time}`
        );

        const { data, status } = response || {};

        dispatch({
            type: MY_SALES,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: MY_SALES_ERROR,
            payload: e || {},
        });
    }
};
