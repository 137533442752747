import React from "react";
import { connect } from "react-redux";
import { DetailSection } from "./components/DetailSection";
import { ProductList } from "./components/ProductList";
import {
    Grid,
    Typography,
    CircularProgress,
    Card,
    CardContent,
} from "@mui/material";
import { PageDetailTransferSaleHook } from "./hooks/PageDetailTransferSaleHook";
import { detailTransferSalesAction } from "_models/redux/detail-transfer-sales/action";
import { cleanDetailTransferSalesClean } from "_models/redux/detail-transfer-sales/clean-action";
import { processTransferSalesAction } from "_models/redux/process-transfer-sales/action";
import { processTransferSalesClean } from "_models/redux/process-transfer-sales/clean-action";
import { TransferIdSection } from "./components/TransferIdSection";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import { cardStyle, centerTextSX, gridContainerSX } from "./style/style";

const PageDetailTransferSale = ({
    detailTransferSalesAction,
    cleanDetailTransferSalesClean,
    detailTransferSales,
    processTransferSales,
    processTransferSalesAction,
    processTransferSalesClean,
}) => {
    const {
        transferId,
        handleTransferIdChange,
        handleSendTransferId,
        payload,
        loading,
        handleGoBack,
        dialogOpen,
        handleCloseDialog,
        dialogTitle,
        dialogContent,
        user_permissions,
    } = PageDetailTransferSaleHook({
        detailTransferSalesAction,
        cleanDetailTransferSalesClean,
        detailTransferSales,
        processTransferSales,
        processTransferSalesAction,
        processTransferSalesClean,
    });

    return (
        <Grid container spacing={3} sx={gridContainerSX}>
            <Grid item xs={12}>
                <IconButton onClick={handleGoBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">Detalles de la Venta</Typography>
            </Grid>
            {loading ? (
                <Grid item xs={12} sx={centerTextSX}>
                    <CircularProgress />
                </Grid>
            ) : (
                payload && (
                    <>
                        <Grid item xs={12} lg={12}>
                            <Card variant="outlined" sx={cardStyle}>
                                <CardContent>
                                    <DetailSection payload={payload} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant="outlined" sx={cardStyle}>
                                <CardContent>
                                    <ProductList
                                        products={payload.products}
                                        totalAmount={payload?.total_amount}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined" sx={cardStyle}>
                                <CardContent>
                                    <TransferIdSection
                                        transaction_status_id={
                                            payload.transaction_status_id
                                        }
                                        user_permissions={user_permissions}
                                        transferId={transferId}
                                        onTransferIdChange={
                                            handleTransferIdChange
                                        }
                                        onSendTransferId={handleSendTransferId}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container spacing={3} sx={gridContainerSX}>
                            {/* Otras partes del código */}
                            <Dialog
                                open={dialogOpen}
                                onClose={handleCloseDialog}
                                aria-labelledby="dialog-title"
                            >
                                <DialogTitle id="dialog-title">
                                    {dialogTitle}
                                </DialogTitle>
                                <DialogContent>
                                    <Typography>{dialogContent}</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleCloseDialog}
                                        autoFocus
                                    >
                                        Cerrar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </>
                )
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    detailTransferSales: state?.detailTransferSales,
    processTransferSales: state?.processTransferSales,
});

const mapDispatchToProps = {
    cleanDetailTransferSalesClean,
    detailTransferSalesAction,
    processTransferSalesAction,
    processTransferSalesClean,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageDetailTransferSale);
