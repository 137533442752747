import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getLoginDataHelper } from "_controllers/helpers/getLoginData";

import LeadsByCampaingPage from "../../leadsByCampaing/page";

const MarketingKPIsPage = () => {
    const permission = "MARKETING_REPORTS";

    const history = useNavigate();

    const { userPermissions } = getLoginDataHelper();

    useEffect(() => {
        if (
            typeof userPermissions
                ?.split(",")
                ?.find((element) => element === permission) === "undefined"
        ) {
            history("/");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return userPermissions
        ?.split(",")
        ?.find((element) => element === permission) ? (
        <div className="kpi-container">
            <h2>Canal y campañas</h2>
            <LeadsByCampaingPage />
            {/* <LeadsByCampaignChannelPage /> */}

            {/* <LeadsByCampaingPage />
            <LeadsByCampaingPage /> */}
        </div>
    ) : null;
};

export default MarketingKPIsPage;
