import axiosTokenInstance from "../../../services/api/backoffice";
import { CHANGE_USER, CHANGE_USER_ERROR } from "_models/redux/types";

// Action function

export const changeUser = (params) => async (dispatch) => {
    const { ticketId, user_id } = params;
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.patch(
            `/api/support/tickets/${ticketId}/assign-user`,
            { user_id }
        );

        const { data } = response || {};

        dispatch({
            type: CHANGE_USER,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: CHANGE_USER_ERROR,
            payload: e || {},
        });
    }
};
