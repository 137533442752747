//Main Hook (Container):
import { useLayoutContainer } from "./hooks/useLayoutContainer";
//Context:
import { LayoutContext } from "./data/layoutContext";
//Component:
import LayoutComponent from "./components/LayoutComponent";

const LayoutPage = (props) => {
    //Hook Instance:
    const layoutValues = useLayoutContainer(props);

    //Set Hook Values to Context:
    return (
        <LayoutContext.Provider value={layoutValues || {}}>
            <LayoutComponent />
        </LayoutContext.Provider>
    );
};

export default LayoutPage;
