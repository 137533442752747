import React from "react";
import {
    Grid,
    FormControl,
    Select,
    MenuItem,
    ListItemAvatar,
    Avatar,
} from "@mui/material";

const DropdownAgentSelect = ({ valueAgent, handleChange, agentList }) => (
    <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
            <FormControl variant="standard" fullWidth size="small">
                <Select
                    variant="standard"
                    defaultValue={2}
                    fullWidth
                    labelId="agent-select"
                    id="agent-select"
                    value={valueAgent}
                    onChange={handleChange}
                >
                    {agentList.map((agent) => (
                        <MenuItem key={agent.id} value={agent.id || -1}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            backgroundColor: "#FFC6F4",
                                            color: "#E452B4",
                                            fontSize: 14,
                                            borderRadius: 30,
                                        }}
                                    >
                                        {agent?.name[0]}
                                        {agent?.last_name[0]}
                                        {agent?.mothers_last_name === undefined
                                            ? ""
                                            : agent?.mothers_last_name[0]}
                                    </Avatar>
                                </ListItemAvatar>
                                {agent.name}
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    </Grid>
);

export default DropdownAgentSelect;
