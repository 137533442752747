import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import miniLogo from "assets/images/mini-logo.png";
import forgotPasswordSuccessImage from "assets/images/login/login-image.png";
import FormFooterComponent from "widgets/formFooter/component";

const SuccessForgotPasswordPage = () => {
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    return (
        <Grid item container xs={12} className={"forgot-password-page"}>
            <Grid
                item
                container
                xs={0}
                sm={0}
                md={4}
                lg={6}
                className={"forgot-password-image-container"}
            >
                <img
                    title="Forgot Password Success"
                    alt="Forgot Password Success"
                    src={forgotPasswordSuccessImage}
                    className={"forgot-password-image"}
                />
            </Grid>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={6}
                className={"forgot-password-component-container"}
            >
                <Grid
                    item
                    container
                    xs={12}
                    className={"forgot-password-component"}
                >
                    <Grid
                        item
                        container
                        xs={12}
                        className={"forgot-password-form no-form"}
                    >
                        <Grid item container xs={12}>
                            <Grid item container xs={6} className={""}></Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                className={
                                    "forgot-password-logo-container custom-logo"
                                }
                            >
                                <img
                                    title="Mini"
                                    alt="Mini"
                                    src={miniLogo}
                                    className={"forgot-password-logo"}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            className={
                                "forgot-password-title-container justify-content-start"
                            }
                        >
                            <h3 className={"forgot-password-title"}>
                                Se está procesando tu solicitud...
                            </h3>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={"forgot-password-text-container"}
                        >
                            <p className={"forgot-password-text"}>
                                Hemos enviado un mensaje a tu bandeja de correo
                                electrónico con las instrucciones para
                                reestablecer tu contraseña.
                            </p>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            className={
                                "forgot-password-text-container custom-text-container"
                            }
                        >
                            <p className={"forgot-password-text"}>
                                Ya puedes cerrar esta ventana
                            </p>
                        </Grid>
                        <FormFooterComponent />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SuccessForgotPasswordPage;
