import axiosTokenInstance from "../../../services/api/backoffice";

import { IMEI, IMEI_ERROR } from "_models/redux/types";

// Action function
export const getImei = (params) => async (dispatch) => {
    const { ticket_id } = params || {
        ticket_id: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/${ticket_id}/actions/device-info`
        );

        const { data } = response || {};

        dispatch({
            type: IMEI,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: IMEI_ERROR,
            payload: e || {},
        });
    }
};
