import React, { useState } from "react";

import { ShippingPendingContext } from "./_context";

import ShippingPendingComponent from "./_component";

const ShippingPendingContainer = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [dropdownOptionsChannels, setDropdownOptionsChannels] = useState("");
    const [selectChannel, setSelectChannel] = useState("");

    const shippingValues = {
        modalOpen,
        setModalOpen,
        dropdownOptionsChannels,
        setDropdownOptionsChannels,
        selectChannel,
        setSelectChannel,
    };

    return (
        <ShippingPendingContext.Provider value={shippingValues}>
            <ShippingPendingComponent />
        </ShippingPendingContext.Provider>
    );
};

export default ShippingPendingContainer;
