import {
    RESET_PASSWORD_STATUS,
    RESET_PASSWORD_STATUS_ERROR,
} from "_models/redux/types";
import axiosAuthInstance from "../../../services/api/authentication";

export const resetPasswordStatusAction = (params) => async (dispatch) => {
    const { token } = params || {
        token: undefined,
    };

    try {
        const response = await axiosAuthInstance.post(
            `/api/users/reset-password/${token}/status`
        );

        const { data, status } = response;

        dispatch({
            type: RESET_PASSWORD_STATUS,
            payload: data,
            status: status,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: RESET_PASSWORD_STATUS_ERROR,
            payload: e || {},
        });
    }
};
