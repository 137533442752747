import React from "react";
import { connect } from "react-redux";
import TicketHeader from "./components/header";
import TicketsDatagrid from "./components/datagrid";

const UserMyTicketsPage = (props) => {
    return (
        <div>
            <TicketHeader />
            <TicketsDatagrid />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserMyTicketsPage);
