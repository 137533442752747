import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const TitleHeaderHook = (props) => {
    const [timePassed, setTimePassed] = useState("");
    const { titleTicket, ticketById } = props;
    const { response } = ticketById || undefined;
    const { created } = response || undefined;
    const mexicoCreated = convertToMexicoTime(created);

    useEffect(() => {
        const interval = setInterval(() => {
            const createdMoment = moment(mexicoCreated);

            const duration = moment.duration(moment().diff(createdMoment));

            const days = duration.asDays();
            const hours = duration.hours();
            const minutes = duration.minutes();

            const formattedTime = `${Math.floor(
                days
            )} días, ${hours} horas, ${minutes} minutos`;

            setTimePassed(formattedTime);
        }, 1000);

        return () => clearInterval(interval);
    }, [mexicoCreated]);

    return {
        timePassed,
        titleTicket,
    };
};
