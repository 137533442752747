import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Grid, Container } from "@mui/material";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

import AnalyticsIcon from "@mui/icons-material/Analytics";
import FilterListIcon from "@mui/icons-material/FilterList";

const SamplesPage = (props) => {
    const history = useNavigate();

    useEffect(() => {
        return () => {};
    }, []);

    const onClickHandler = (e = undefined, link = "/samples") => {
        history(link);
    };

    return (
        <Container>
            <Grid container>
                <Grid item xs={12}></Grid>
                <h4>SAMPLES</h4>

                <Grid item xs={12}>
                    <Paper sx={{ width: 320, maxWidth: "100%" }}>
                        <MenuList>
                            <MenuItem
                                onClick={(e) =>
                                    onClickHandler(e, "/samples/charts")
                                }
                            >
                                <ListItemIcon>
                                    <AnalyticsIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Charts</ListItemText>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                ></Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={(e) =>
                                    onClickHandler(e, "/samples/filters")
                                }
                            >
                                <ListItemIcon>
                                    <FilterListIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Filters</ListItemText>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                ></Typography>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SamplesPage;
