import axiosTokenInstance from "services/api/backoffice";
import { LIST_SIM_IPB, LIST_SIM_IPB_ERROR } from "_models/redux/types";

// Action function

export const listSimIpbAction =
    (page = 1, size = 10, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                `/api/reports/ipb/distributors/sims-orders?`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: LIST_SIM_IPB,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: LIST_SIM_IPB_ERROR,
                payload: e || {},
            });
        }
    };
