import {
    CHANGE_NIR,
    CHANGE_NIR_ERROR,
    CHANGE_NIR_CLEAN,
    CHANGE_NIR_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    changeNir: [],
    error: undefined,
    loading: false,
};

export default function changeNirReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_NIR:
        case CHANGE_NIR_CLEAN:
            return {
                ...state,
                changeNir: action.payload,
                loading: true,
            };

        case CHANGE_NIR_ERROR:
        case CHANGE_NIR_CLEAN_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
