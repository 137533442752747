import axiosTokenInstance from "services/api/backoffice";
import {
    ACTIVATIONS_AND_DEACTIVATIONS,
    ACTIVATIONS_AND_DEACTIVATIONS_ERROR,
} from "_models/redux/types";

// Action function
export const getActivationsAndDeactivationsAction =
    (params) => async (dispatch) => {
        const { from_time, to_time } = params || {};

        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.get(
                `/api/reports/subscribers/activations-deactivations?from_time=${from_time}&to_time=${to_time}`
            );

            const { data, status } = response || {};

            dispatch({
                method: "get",
                payload: data || {},
                status: status || {},
                type: ACTIVATIONS_AND_DEACTIVATIONS,
            });
        } catch (e) {
            dispatch({
                method: "get",
                payload: e || {},
                type: ACTIVATIONS_AND_DEACTIVATIONS_ERROR,
            });
        }
    };
