import axiosTokenInstance from "../../../services/api/backoffice";
import {
    CHANGE_SUPPLEMENTARY_SERVICE,
    CHANGE_SUPPLEMENTARY_SERVICE_ERROR,
} from "_models/redux/types";

// Action function
export const postSupplementaryService = (params) => async (dispatch) => {
    const { ticket_id } = params || {
        ticket_id: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `/api/support/tickets/${ticket_id}/actions/suplementary-services`
        );

        const { data } = response || {};

        dispatch({
            type: CHANGE_SUPPLEMENTARY_SERVICE,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: CHANGE_SUPPLEMENTARY_SERVICE_ERROR,
            payload: e || {},
        });
    }
};
