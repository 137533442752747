import React from "react";
import { TextField } from "@mui/material";

const TextInput = ({
    label,
    placeholder,
    id,
    name,
    value,
    onChange,
    shrink,
    register,
    registerOptions,
    sx,
}) => {
    return (
        <TextField
            {...register(name, registerOptions)}
            fullWidth
            variant="outlined"
            label={label}
            placeholder={placeholder}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            InputLabelProps={{
                shrink: shrink,
            }}
            sx={sx}
        />
    );
};

export default TextInput;
