export const registerValidations = {
    phoneInput: {
        // required: "Número telefónico es requerido",
        minLength: {
            value: 10,
            message: "El número telefónico es de 10 dígitos",
        },
        maxLength: {
            value: 10,
            message: "El número telefónico es de 10 dígitos",
        },
    },
};
