import { colors } from "variables";

export const loginStyles = ({ theme }) => {
    return {
        loginContainer: {
            mainWrapper: {
                display: "flex",
                marginTop: "20px",
                background: theme.palette.primary.main,
                backgroundImage:
                    "linear-gradient(180deg, " +
                    theme.palette.primary.main +
                    " 0%, " +
                    theme.palette.tertiary.main +
                    " 100%)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "0% 0%",
                backgroundSize: "cover",
                height: "100vh",
            },
            leftWrapper: {
                display: { xs: "none", lg: "flex" },
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            },
            leftWrapperImg: {
                maxWidth: "400px",
            },
            rightWrapper: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            },
        },
        loginContent: {
            loginCard: { minWidth: 275, width: "80%", height: "80vh" },
            loginCardContent: { padding: "5% 10%" },
            loginCardHeader: { margin: "0 0 50px" },
            loginCardTitle: { display: "flex", alignItems: "center" },
            loginCardLogo: { display: "flex", justifyContent: "end" },
            loginCardLogoImg: {
                maxWidth: "100px",
            },
            loginFooter: {
                width: "100%",
                textAlign: "center",
                fontSize: "14px",
                color: theme.palette.nonary.main,
            },
            loginFooterIcon: {
                fontSize: "12px",
                color: theme.palette.nonary.main,
            },
        },
        loginForm: {
            loginFormButtonContainer: { width: "100%", padding: "15px 0" },
            loginFormButton: { width: "100%" },
            loginForgotPassword: {
                width: "100%",
                margin: "30px 0",
                color: theme.palette.quaternary.main,
                textAlign: "center",
            },
            loginFormLoader: { width: "100%", textAlign: "center" },
            loginFormErrorContainer: {
                width: "100%",
                textAlign: "center",
            },
            loginFormError: {
                width: "100%",
                color: colors?.color5,
                textAlign: "center",
            },
        },
    };
};
