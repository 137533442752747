import { useContext } from "react";

import { GiftOrdersContext } from "../../data/giftOrdersContexts";

export const useGiftOrdersError = () => {
    // Obtener el contexto del módulo y sus propiedades
    const giftOrdersContext = useContext(GiftOrdersContext);

    // Desestructuración de las propiedades del contexto
    const { reducerProps, moduleMethods } = giftOrdersContext || {};

    // Desestructuración de las propiedades del Reducer
    const { giftOrdersReducer } = reducerProps || {};
    const { errorOpenModal, errorModalMessage } = giftOrdersReducer || {};

    // Desestructuración de las propiedades del "método del módulo"
    const { handleErrorCloseModal } = moduleMethods || {};

    // Sólo se obtienen las propiedades que se usarán en el componente correspondiente

    // Propiedades / variables / estados y métodos / funciones que se usarán en los componentes
    return { errorOpenModal, handleErrorCloseModal, errorModalMessage };
};
