//Layout Methods Hook:
import { useLayoutMethods } from "./useLayoutMethods";
//Layout Effects Hook:
import { useLayoutEffects } from "./useLayoutEffects";
import { useLayoutVariables } from "./useLayoutVariables";

export const useLayoutContainer = () => {
    //VARIABLES:
    const { layoutVariables } = useLayoutVariables() || {};

    //Container properties:
    // This properties are passed to:
    // -useLayoutEffects hook
    // -Layout component (before modified by the useLayoutMethods hook):
    const containerProps = {
        layoutVariables,
    };

    //METHODS:
    //Instance and destructuring login methods at once:
    //(I'mean, at the same time)
    containerProps.methods = useLayoutMethods(containerProps) || {};

    //USEEFFECTS:
    //Instance login effects:
    //(This hook doesn't need to be destructured, because it does not return anything)
    useLayoutEffects(containerProps);

    //Return container properties:
    //(Values embebed in the module main component context)
    return containerProps;
};
