import React from "react";
import { DataGridHook } from "../controllers/dataGridHook";
import TicketDetailsPopover from "./TicketDetailsPopover";
import LinearProgressIndicator from "./LinearProgressIndicator";
import TicketRow from "./TicketRow";
import EmptyState from "./EmptyState";

const TicketsDatagrid = (props) => {
    const {
        handleClose,
        handleClick,
        handleNavigate,
        handleChangeUser,
        rows,
        positionX,
        positionY,
        loader,
        dataUsersList,
        open,
    } = DataGridHook(props);

    const ContainerTable = () => {
        if (rows?.length > 0)
            return (
                <div className={"list-box"}>
                    {rows.map((ticket, index) => (
                        <TicketRow
                            key={index}
                            ticket={ticket}
                            handleNavigate={handleNavigate}
                            handleClick={handleClick}
                        />
                    ))}
                </div>
            );
        else return <EmptyState />;
    };

    return (
        <div>
            {loader ? (
                <LinearProgressIndicator loader={loader} />
            ) : (
                <ContainerTable />
            )}
            <TicketDetailsPopover
                open={open}
                handleClose={handleClose}
                positionX={positionX}
                positionY={positionY}
                dataUsersList={dataUsersList}
                handleChangeUser={handleChangeUser}
            />
        </div>
    );
};

export default TicketsDatagrid;
