import React from "react";
import { Grid, Collapse, Alert, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

const ModalLockImei = ({
    closePopup,
    alert,
    severityAlert,
    errorMssge,
    handleLockImei,
    isLoading,
    imeiNumber,
}) => {
    return (
        <Grid container className="modalBackground">
            <Grid container xs={4} className="modalContainer">
                <Grid container>
                    <Grid item xs={6} className="title" direction={"column"}>
                        <h3>Bloqueo de IMEI</h3>
                    </Grid>
                    <Grid item xs={6} className="titleCloseBtn">
                        <button onClick={closePopup}>
                            <CloseIcon />
                        </button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <h4>¿Está seguro de bloquear IMEI?</h4>
                </Grid>

                <Grid item xs={12} className="body">
                    <TextField
                        variant="standard"
                        disabled
                        fullWidth
                        type="text"
                        className="form-control"
                        placeholder={imeiNumber}
                        name="imeiNumber"
                        value={imeiNumber}
                    />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                    <Collapse in={alert}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={closePopup}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity={severityAlert}
                        >
                            {errorMssge}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={12} className="footer">
                    <button onClick={closePopup} id="cancelBtn">
                        Cancelar
                    </button>

                    <LoadingButton
                        size="small"
                        onClick={handleLockImei}
                        endIcon={<SendIcon />}
                        loading={isLoading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Bloquear
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ModalLockImei;
