import React from "react";
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    CircularProgress,
    Box,
} from "@mui/material";
import { useCustomComponenteMakePrepaidHook } from "../hooks/actionsTicketsHooks/CustomComponenteMakePrepaidHook";

const CustomComponenteMakePrepaid = ({
    productsPrepaid,
    ActionMakePrepaid,
    ticketId,
    makePrepaid,
    listcommentsTikets,
}) => {
    const {
        products,
        register,
        handleSubmit,
        handleSelectChange,
        handleCommentChange,
        onSubmit,
        loading,
        statusMessage,
        errors,
        handlePaidChange,
    } = useCustomComponenteMakePrepaidHook({
        productsPrepaid,
        ActionMakePrepaid,
        ticketId,
        makePrepaid,
        listcommentsTikets,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Paquetes
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            {...register("product_id", { required: true })}
                            onChange={handleSelectChange}
                        >
                            {products?.map((product) => (
                                <MenuItem
                                    key={product.carrier_product_id}
                                    value={product.carrier_product_id}
                                >
                                    {product.product_name}{" "}
                                </MenuItem>
                            ))}
                        </Select>

                        {errors.product_id && (
                            <span style={{ color: "red" }}>
                                Este campo es obligatorio
                            </span>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="is-paid-select-label">
                            ¿Comprado o regalo?
                        </InputLabel>
                        <Select
                            labelId="is-paid-select-label"
                            id="is-paid-select"
                            {...register("is_paid", { required: true })}
                            onChange={handlePaidChange}
                        >
                            <MenuItem value={"true"}>Comprado</MenuItem>
                            <MenuItem value={"false"}>Regalo</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Comentarios"
                        multiline
                        rows={4}
                        variant="outlined"
                        {...register("reason", { required: true })}
                        onChange={handleCommentChange}
                        fullWidth
                    />
                    {errors.reason && (
                        <span style={{ color: "red" }}>
                            Este campo es obligatorio
                        </span>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <button type="submit">Submit</button>
                </Grid>
                {loading && (
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Box>
                    </Grid>
                )}
                {statusMessage && (
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {statusMessage}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default CustomComponenteMakePrepaid;
