import React from "react";
import { Grid, Button, Avatar } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import moment from "moment";

const TicketRow = ({ ticket, handleNavigate, handleClick }) => {
    const lastActionTime = moment(ticket.last_action_time)
        .startOf("hour")
        .fromNow();
    const created = moment(ticket.created).startOf("hour").fromNow();

    return (
        <Grid container className="row">
            <Grid
                item
                xs={4}
                className={"section-one"}
                display={"flex"}
                alignItems={"center"}
            >
                <p>
                    <ConfirmationNumberIcon className="svg" />
                    <span>{ticket.id}</span>
                </p>
                <Button
                    id={ticket.id}
                    name={ticket.direct_number}
                    onClick={handleNavigate}
                >
                    {ticket.title}
                </Button>
            </Grid>
            <Grid item xs={8} className={"section-two"}>
                <Grid
                    container
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Grid item>
                        <p className="phone-number">
                            <LocalPhoneIcon className="svg" />
                            {ticket.direct_number}
                        </p>
                    </Grid>
                    <Grid item className={"dates"}>
                        Creado: {created}
                    </Grid>
                    <Grid item className={"dates"}>
                        Última actividad: {lastActionTime}
                    </Grid>
                    <Grid item className="dropdown-status">
                        {ticket.general_status_label}
                    </Grid>
                    <Grid item>
                        <Button
                            id={ticket.id}
                            onClick={(e) =>
                                handleClick({
                                    ticketId: ticket.id,
                                    events: e,
                                })
                            }
                        >
                            <Avatar className={"avatar"}>
                                {ticket.assigned_to_user_name[0]}
                                {ticket.assigned_to_user_last_name[0]}
                                {ticket.assigned_to_user_mothers_last_name[0]}
                            </Avatar>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TicketRow;
