import axiosTokenInstance from "services/api/backoffice";
import {
    GET_SUPPORT_GIFT_ORDERS,
    GET_SUPPORT_GIFT_ORDERS_ERROR,
} from "_models/redux/types";

export const getGiftOrdersAction = (params) => async (dispatch) => {
    const { direct_number, from_time, to_time } = params || {};

    let payload = {};

    if (direct_number) {
        payload.direct_number = direct_number;
    }
    if (from_time) {
        payload.from_time = from_time;
    }
    if (to_time) {
        payload.to_time = to_time;
    }

    const queryStringParmas = "?" + new URLSearchParams(payload).toString();

    const endpointUrl = "/api/reports/esim-gifts-reports";

    try {
        const response = await axiosTokenInstance.get(
            `${endpointUrl}${queryStringParmas}`
        );

        dispatch({
            method: "get",
            payload: payload || null,
            response: response?.data || null,
            status: response?.status || null,
            object: response,
            type: GET_SUPPORT_GIFT_ORDERS,
        });
    } catch (e) {
        console.error(e);

        dispatch({
            method: "get",
            payload: payload || null,
            response: e?.response || null,
            status: e?.response?.status || null,
            object: e,
            type: GET_SUPPORT_GIFT_ORDERS_ERROR,
        });
    }
};
