import React, { useContext } from "react";

import { Grid, Button, CircularProgress } from "@mui/material";

import { ForgotPasswordContext } from "./context";

import miniLogo from "assets/images/mini-logo.png";
import FieldComponent from "widgets/field/component";
import ErrorMessageComponent from "widgets/errorMessage/component";
import FormFooterComponent from "widgets/formFooter/component";

const ForgotPasswordComponent = () => {
    const forgotPasswordContext = useContext(ForgotPasswordContext);

    const { variant, progress } = forgotPasswordContext || {
        variant: undefined,
        progress: undefined,
    };

    return (
        <Grid item container xs={12} className={"forgot-password-component"}>
            <Grid item container xs={12} className={"forgot-password-form"}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={forgotPasswordContext.handleSubmit(
                        forgotPasswordContext.sendForgotPassword
                    )}
                >
                    <Grid item container xs={12}>
                        <Grid item container xs={6} className={""}></Grid>
                        <Grid
                            item
                            container
                            xs={6}
                            className={"forgot-password-logo-container"}
                        >
                            <img
                                title="Mini Logo"
                                alt="Mini Logo"
                                src={miniLogo}
                                className={"forgot-password-logo"}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"forgot-password-title-container"}
                    >
                        <h3 className={"forgot-password-title"}>
                            ¿Olvidaste tu contraseña?
                        </h3>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        className={"forgot-password-text-container"}
                    >
                        <p className={"forgot-password-text"}>
                            Te enviaremos un correo con los pasos a seguir para
                            reestablecer su contraseña:
                        </p>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"forgot-password-field-container"}
                    >
                        <FieldComponent
                            fieldContext={forgotPasswordContext}
                            fielEndIcon=""
                            fieldStartIcon=""
                            fieldClassName="user-email-textfield"
                            fieldName="user_email"
                            fieldLabel="Correo electrónico"
                            fieldPlaceholder="correoelectrónico@dalefon.com.mx"
                            fieldDisabled={false}
                            fileldDefaultValue=""
                            fieldRequired={{
                                required: "El correo electrónico es requerido",
                            }}
                            fieldError={
                                <ErrorMessageComponent
                                    errorParams={forgotPasswordContext || {}}
                                    errorType={"user_email"}
                                />
                            }
                        />
                    </Grid>

                    <Grid item container xs={12}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={"forgot-password-button"}
                            // onClick={forgotPasswordContext.sendForgotPassword}
                        >
                            Enviar
                        </Button>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"forgot-password-loader"}
                    >
                        <CircularProgress variant={variant} value={progress} />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"forgot-password-response"}
                    >
                        <span
                            className={
                                forgotPasswordContext.forgotPasswordResponseCls
                                    .container || ""
                            }
                        >
                            <h5
                                className={
                                    forgotPasswordContext
                                        .forgotPasswordResponseCls.text || ""
                                }
                            >
                                {forgotPasswordContext.forgotPasswordResponse ||
                                    ""}
                            </h5>
                        </span>
                    </Grid>

                    <FormFooterComponent />
                </form>
            </Grid>
        </Grid>
    );
};

export default ForgotPasswordComponent;
