import { NEIGHBORHOOD, NEIGHBORHOOD_ERROR } from "_models/redux/types";
import axiosTokenInstance from "services/api/backoffice";

export const neighborhoodAction = (params) => async (dispatch) => {
    const { municipality_id } = params || {};

    const paramsData = {
        municipality_id,
    };

    try {
        const response = await axiosTokenInstance.get(
            `/api/sepomex/municipalities/${municipality_id}/neighborhoods`,
            paramsData
        );

        const { data, status } = response;

        dispatch({
            type: NEIGHBORHOOD,
            payload: data,
            status: status,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: NEIGHBORHOOD_ERROR,
            payload: e || {},
        });
    }
};
