import { connect } from "react-redux";

import { getGiftOrdersAction } from "_models/redux/gift-orders/get-gift-orders/getGiftOrdersAction";
import { getGiftOrdersCleanAction } from "_models/redux/gift-orders/get-gift-orders/getGiftOrdersCleanAction";

import { getGiftOrdersDetailAction } from "_models/redux/gift-orders/get-gift-orders-detail/getGiftOrdersDetailAction";
import { getGiftOrdersDetailCleanAction } from "_models/redux/gift-orders/get-gift-orders-detail/getGiftOrdersDetailCleanAction";

import { GiftOrdersContext } from "./data/giftOrdersContexts";

import { useGiftOrdersContainer } from "./hooks/useGiftOrdersContainer";
import GiftOrdersContent from "./components/GiftOrdersContent";

const GiftOrdersPage = (props) => {
    // Hook principal - contenedor de los objetos: funciones y variables del módulo
    // que se heredan a los componentes hijos por medio del contexto
    // @param {Object} props - Propiedades del componente, en este caso las acciones de Redux
    const giftOrdersValues = useGiftOrdersContainer(props);

    // Renderiza el componente principal - con el contenido del módulo
    return (
        <GiftOrdersContext.Provider value={giftOrdersValues}>
            <GiftOrdersContent />
        </GiftOrdersContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    getGiftOrders: state?.getGiftOrders,
    getGiftOrdersDetail: state?.getGiftOrdersDetail,
});

const mapDispatchToProps = {
    getGiftOrdersAction,
    getGiftOrdersCleanAction,
    getGiftOrdersDetailAction,
    getGiftOrdersDetailCleanAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftOrdersPage);
