import React, { useEffect, useState } from "react";
import { Grid, Avatar, Button, Fade, LinearProgress } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { connect } from "react-redux";
import { getTicketsByNumber } from "_models/redux/tickets-by-number/action";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import noIssues from "assets/images/tickets/no-issues.png";

const TicketsDatagrid = (props) => {
    let location = useLocation();
    const directNumber = location?.state?.directNumber;
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        props.getTicketsByNumber({ directNumber: directNumber });
        setLoader(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ticketsByNumber]);

    const generateRows = () => {
        const { ticketsByNumber } = props.ticketsByNumber || {
            tickets: undefined,
        };

        const rows = ticketsByNumber.tickets;

        if (typeof rows != "undefined" && Array.isArray(rows)) {
            setLoader(false);
            setRows(rows);
        }
    };

    const handleNavigate = (params) => {
        navigate("/tickets/" + params.target.name);
    };

    const ContainerTable = () => {
        if (rows?.length > 0)
            return (
                <div className={"list-box"}>
                    {rows.map((element, index) => {
                        const lastActionTime = moment(
                            element.last_action_time
                        ).format("DD/MM/YYYY");
                        const created = moment(element.created).format(
                            "DD/MM/YYYY"
                        );

                        return (
                            <Grid container className="row" key={index}>
                                <Grid item xs={6} className={"section-one"}>
                                    <Button
                                        name={element.id}
                                        onClick={handleNavigate}
                                    >
                                        {element.title}
                                    </Button>
                                    <p>
                                        <ConfirmationNumberIcon className="svg" />
                                        <span>
                                            <p>{element.id}</p>
                                        </span>
                                        <LocalPhoneIcon className="svg" />
                                        <span>{element.direct_number}</span>
                                    </p>
                                </Grid>
                                <Grid item xs={6} className={"section-two"}>
                                    <Grid item xs={12} md={6}>
                                        <Grid item className="dropdown-status">
                                            {element.general_status_label}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Avatar className={"avatar"}>
                                            {element.assigned_to_user_name[0]}
                                            {
                                                element
                                                    .assigned_to_user_last_name[0]
                                            }
                                            {
                                                element
                                                    .assigned_to_user_mothers_last_name[0]
                                            }
                                        </Avatar>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        className={"dates"}
                                    >
                                        <p>
                                            Última actualización:{" "}
                                            <span>{lastActionTime}</span>
                                        </p>
                                        <p>
                                            Creado: <span>{created}</span>
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </div>
            );
        else {
            return <EmptyContainer />;
        }
    };

    const EmptyContainer = () => {
        return (
            <Grid container className="no-issues">
                <Grid item xs={12}>
                    <img
                        title="No Issues"
                        alt="No Issues"
                        src={noIssues}
                        className={"no-issues-img"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h2>¡Felicidades!</h2>
                    <p>Todas tus incidencias han sido resueltas.</p>
                </Grid>
            </Grid>
        );
    };

    const LinearProgressContainer = () => {
        return (
            <Fade in={loader}>
                <LinearProgress />
            </Fade>
        );
    };

    return (
        <div>{loader ? <LinearProgressContainer /> : <ContainerTable />}</div>
    );
};

const mapStateToProps = (state) => ({
    ticketsByNumber: state.ticketsByNumber,
});

const mapDispatchToProps = { getTicketsByNumber };

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDatagrid);
