import { Grid, CircularProgress } from "@mui/material";

const LoginFormResponses = ({ hookProps }) => {
    //Desructuring hook props, inherit from component parent:
    const {
        //State Props:
        variant,
        progress,
        loginResponse,
        loginResponseCls,

        //Styles:
        loginFormLoader,
        loginFormErrorContainer,
        loginFormError,
    } = hookProps || {};

    //Response items (components) for login form:
    // - Loader
    // - Error Message
    return (
        <>
            <Grid item xs={12} sx={loginFormLoader || {}}>
                <CircularProgress variant={variant} value={progress} />
            </Grid>

            <Grid item xs={12} sx={loginFormErrorContainer || {}}>
                <h5
                    className={loginResponseCls.text || ""}
                    style={loginFormError || {}}
                >
                    {loginResponse || ""}
                </h5>
            </Grid>
        </>
    );
};

export default LoginFormResponses;
