import React from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { postPortabilityAction } from "_models/redux/portability/action";
import { cleanPortabilityAction } from "_models/redux/portability/clean-action";
import { ActionPortabilityHook } from "../../hooks/actionsTicketsHooks/actionPortabilityHook";
import ModalChangeNirPortability from "../ModalChangeNirPortability";

const ActionPortability = (props) => {
    const {
        onSubmit,
        handleClickButton,
        closePopup,
        response,
        modalOpen,
        loaderButton,
        nipValue,
        phoneValue,
        register,
        errors,
        registerOptions,
        handleSubmit,
        state,
        loaded,
    } = ActionPortabilityHook(props);

    return (
        <Grid item>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickButton}
                variant="outlined"
                className="button"
            >
                <h4>Portabilidad</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalChangeNirPortability
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    closePopup={closePopup}
                    state={state}
                    loaded={loaded}
                    response={response}
                    loaderButton={loaderButton}
                    register={register}
                    registerOptions={registerOptions}
                    nipValue={nipValue}
                    phoneValue={phoneValue}
                    errors={errors}
                />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    portability: state?.portability,
});
const mapDispatchToProps = {
    postPortabilityAction,
    cleanPortabilityAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPortability);
