import axiosTokenInstance from "services/api/backoffice";
import {
    LIST_TRANSFER_SALES,
    LIST_TRANSFER_SALES_ERROR,
} from "_models/redux/types";

// Action function

export const listTransferSalesAction =
    (page = 1, size = 10, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters }; // Incorpora los filtros
            const response = await axiosTokenInstance.get(
                `/api/callcenter/sims/orders/get-list-purchase-by-transfer`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: LIST_TRANSFER_SALES,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: LIST_TRANSFER_SALES_ERROR,
                payload: e || {},
            });
        }
    };
