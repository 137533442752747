import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

import { useNavigate } from "react-router-dom";

const RedirectTo = (props) => {
    const { page } = props || {};

    const history = useNavigate();

    useEffect(() => {
        // closeLoggedSession();

        if (page === "login") {
            history("/login");
        } else {
            history("/dashboard");
        }
    }, [history, page]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="75vh"
        >
            <CircularProgress />
        </Box>
    );
};

export default RedirectTo;
