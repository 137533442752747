// Import: Middleware Action & Custom Types
import { CLEAN_CHECK_IMEI, CLEAN_CHECK_IMEI_ERROR } from "_models/redux/types";

// Action function
export const cleanCheckImeiAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_CHECK_IMEI,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_CHECK_IMEI_ERROR,
            payload: undefined,
        });
    }
};
