import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { useModalWidgetHook } from "widgets/modal/modal-widget-hook";

export const useNewAssistedLeadFormHook = (props) => {
    const { handleClickOpen, handleClose } = useModalWidgetHook(props);

    const {
        properties,
        postErrorResponse,
        postSuccessResponse,
        setPostErrorResponse,
        setPostSuccessResponse,
    } = props || {};

    const { postBindAssistedOrder, postBindAssistedOrderAction } =
        properties || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const registerRequired = {
        orderNumber: {
            required: "El número de orden es requerido",
        },
    };

    const [isLoading, setloading] = useState(false);

    const onSubmit = (e) => {
        const { orderNumber } = e || {};

        setloading(true);

        const postBindAssistedOrderObj = {
            order_number: orderNumber,
        };

        postBindAssistedOrderAction(postBindAssistedOrderObj);
    };

    useEffect(() => {
        const { loaded, payload, state, status } = postBindAssistedOrder || {};
        const { response } = payload || {};
        const { data } = response || {};
        const { context, title, detail } = data || {};

        if (context) {
            setloading(false);
            switch (context) {
                case "sim_order_not_found":
                case "order_assisted_max_allowed_time_to_bind_reached":
                case "order_assisted_already_assigned_to_backoffice_user":
                    setPostErrorResponse(
                        <span className="error-incidence">
                            <Grid>{title}</Grid>
                            <Grid>{detail}</Grid>
                        </span>
                    );
                    break;

                default:
                    setPostErrorResponse(
                        <span className="error-incidence">
                            <Grid>Ocurrió un error</Grid>
                            <Grid>Inténtelo más tarde</Grid>
                        </span>
                    );
                    break;
            }
        } else if (state === "success" && status === 200) {
            const {
                carrier_product_name,
                lead_contact_name,
                order_number,
                sim_type,
                tracker_email,
            } = payload || {};
            setPostSuccessResponse(
                <>
                    <Grid>
                        <h3>DETALLES DE LA ASIGNACIÓN</h3>
                    </Grid>
                    <br />
                    <Grid>Número de la orden: {order_number}</Grid>
                    <br />
                    <Grid>Tipo: {sim_type}</Grid>
                    <Grid>Producto: {carrier_product_name}</Grid>
                    <Grid>
                        Cliente: {lead_contact_name} ({tracker_email})
                    </Grid>
                </>
            );
        } else if (loaded) {
            setloading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postBindAssistedOrder]);

    useEffect(() => {
        setPostErrorResponse();
        setPostSuccessResponse();
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        errors,
        register,
        onSubmit,
        handleSubmit,
        registerRequired,
        isLoading,
        setloading,
        postErrorResponse,
        postSuccessResponse,
        setPostErrorResponse,
        setPostSuccessResponse,
        handleClose,
        handleClickOpen,
        postBindAssistedOrder,
    };
};
