// Import: Middleware Action & Custom Types
import {
    CLEAN_SALE_ORDER_ESIMQR,
    CLEAN_SALE_ORDER_ESIMQR_ERROR,
} from "_models/redux/types";

// Action function
export const cleanSaleOrderEsimQr = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_SALE_ORDER_ESIMQR,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_SALE_ORDER_ESIMQR_ERROR,
        });
    }
};
