import React from "react";
import { Grid, TextField, Collapse, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import RowButtons from "./RowButtons";
import CheckBox from "./CheckBox";

const ModalVoiceServices = ({
    handleCloseClean,
    hiddenComponent,
    msisdnCallForwarding,
    handleChangeNumberCallForwarding,
    alert,
    errorMssge,
    severityAlert,
    isLoadingButton,
    handleSubmitChangeSupplementaryService,
    ...props
}) => {
    return (
        <Grid container className="modalBackground">
            <Grid container xs={6} className="modalContainer">
                <Grid container>
                    <Grid item xs={6} className="title">
                        <h3>Gestión de servicios de voz</h3>
                    </Grid>
                    <Grid item xs={6} className="titleCloseBtn">
                        <button onClick={handleCloseClean}>
                            <CloseIcon />
                        </button>
                    </Grid>
                </Grid>
                <Grid container xs={12} direction={"column"}>
                    <Grid item xs>
                        <RowButtons {...props} />
                    </Grid>
                    <Grid item xs className={hiddenComponent}>
                        <h4>Número de desvío</h4>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="standard"
                            fullWidth
                            inputProps={{
                                maxLength: 10,
                            }}
                            name="msisdnCallForwarding"
                            type="text"
                            className={hiddenComponent}
                            placeholder="Ingrese valor"
                            value={msisdnCallForwarding}
                            onChange={handleChangeNumberCallForwarding}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <h4>Servicios adicionales</h4>
                    </Grid>
                    <CheckBox {...props} />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                    <Collapse in={alert}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={handleCloseClean}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity={severityAlert}
                        >
                            {errorMssge}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={12} className="footer">
                    <button onClick={handleCloseClean} id="cancelBtn">
                        Cancelar
                    </button>
                    <LoadingButton
                        size="small"
                        onClick={handleSubmitChangeSupplementaryService}
                        endIcon={<SendIcon />}
                        loading={isLoadingButton}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Cambiar
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ModalVoiceServices;
