import React from "react";

// Material UI:
import { TextField, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// Components:
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";

const SectionFilterComponent = ({
    handleSubmit,
    onSubmit,
    register,
    errors,
}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} xl={1}>
                    <TextField
                        required
                        fullWidth
                        label="*Desde"
                        type="date"
                        {...register("from_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.from_date}
                        helperText={errors.from_date?.message}
                    />
                </Grid>

                <Grid item xs={12} xl={1}>
                    <TextField
                        required
                        fullWidth
                        label="*Hasta"
                        type="date"
                        {...register("to_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.to_date}
                        helperText={errors.to_date?.message}
                    />
                </Grid>

                <Grid item xs={12} xl={2}>
                    <TextField
                        fullWidth
                        label="Número de teléfono"
                        {...register("direct_number")}
                        error={!!errors.phone_number}
                        helperText={errors.phone_number?.message}
                    />
                </Grid>

                <Grid item xs={12} xl={1}>
                    <GenericAddButton
                        buttonText="Buscar"
                        backgroundColor="#4C2484"
                        size="medium"
                        isSubmit
                        icon={<SearchIcon />}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default SectionFilterComponent;
