import {
    CLEAN_DETAIL_TRANSACTIONS_STP,
    CLEAN_DETAIL_TRANSACTIONS_STP_ERROR,
} from "_models/redux/types";

export const cleanDetailSTPAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAIL_TRANSACTIONS_STP,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAIL_TRANSACTIONS_STP_ERROR,
        });
    }
};
