import axiosTokenInstance from "services/api/backoffice";
import {
    DETAILS_TRANSACTIONS_CHARGEBACK,
    DETAILS_TRANSACTIONS_CHARGEBACK_ERROR,
} from "_models/redux/types";

// Action function
export const postTransactionsChargeback = (params) => async (dispatch) => {
    const { descriptionTransaction, sim_order_id } = params || {};
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `api/orders/${sim_order_id}/chargeback`,
            { reason: descriptionTransaction }
        );

        const { data, status } = response || {};

        dispatch({
            type: DETAILS_TRANSACTIONS_CHARGEBACK,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        dispatch({
            type: DETAILS_TRANSACTIONS_CHARGEBACK_ERROR,
            payload: e || {},
        });
    }
};
