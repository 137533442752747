// Import: Middleware Action & Custom Types
import {
    CLEAN_REPORT_TRANSACTIONS_FRAUD_SUCCESS,
    CLEAN_REPORT_TRANSACTIONS_FRAUD_FAILURE,
} from "_models/redux/types";

// Función de acción
export const cleanReportTransactionsFraud = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_REPORT_TRANSACTIONS_FRAUD_SUCCESS,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_REPORT_TRANSACTIONS_FRAUD_FAILURE,
            payload: undefined,
        });
    }
};
