import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { LoadersContext } from "_models/context/app";

export const ActionResumeLineHook = (props) => {
    let params = useParams();
    const { ticketId } = props || {};
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const handleClickApn = () => {
        setModalOpen(true);
    };

    useEffect(() => {
        const actionResumeLine = props.postResumeLineReducer || {};

        if (actionResumeLine.postResumeLineObj.length !== 0) {
            if (actionResumeLine.loading === false) {
                setErrorMssge("Error");
                setSeverityAlert("error");
                setAlert(true);
                setIsLoading(false);
            } else {
                setSeverityAlert("success");
                setErrorMssge("Se reanudó la línea satisfactoriamente");
                setAlert(true);
                setIsLoading(false);
                props.getEvents({ ticket_id: params.ticketId });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.postResumeLineReducer]);

    const handleResumeLine = () => {
        setIsLoading(true);
        props.postResumeLineAction(ticketId);
    };
    const clearFunction = () => {
        setAlert(false);
    };

    return {
        modalOpen,
        setModalOpen,
        isLoading,
        setIsLoading,
        alert,
        setAlert,
        errorMssge,
        setErrorMssge,
        severityAlert,
        setSeverityAlert,
        handleClickApn,
        handleResumeLine,
        clearFunction,
    };
};
