import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { getMySalesDetails } from "_models/redux/my-sales-details/action";
import { cleanMySalesDetailsAction } from "_models/redux/my-sales-details/clean-action";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import { PageHook } from "./pageHook";

const MySalesDetailsPage = (props) => {
    const { isLoading, dateValue, handleSubmit, rows } = PageHook(props);

    return (
        <div>
            {isLoading ? ( // Show the loader if isLoading is true
                <div className="loader-container">
                    <CircularProgress size={80} />
                </div>
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <h2>Detalles de Mis Ventas</h2>
                    </Grid>

                    <Grid>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"es"}
                        >
                            <DatePicker
                                value={dateValue || ""}
                                onChange={(newValue) => {
                                    handleSubmit(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{ height: "500px", width: "auto" }}
                            className={"leads-list"}
                        >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fecha</TableCell>
                                            <TableCell>Lead ID</TableCell>
                                            <TableCell>Nombre </TableCell>
                                            <TableCell>
                                                Número de Orden
                                            </TableCell>
                                            <TableCell>Producto</TableCell>
                                            <TableCell>Tipo de SIM</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows?.length > 0 ? (
                                            rows?.map((row) => (
                                                <TableRow
                                                    key={row.date}
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            {
                                                                border: 0,
                                                            },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.date.split(
                                                            "T"
                                                        )[0] +
                                                            " " +
                                                            row.date
                                                                .split("T")[1]
                                                                .split(".")[0]}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.Lead_id}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.lead_client_name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.order_number}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.product}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.sim_type}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    colSpan={6}
                                                >
                                                    NO HAY REGISTROS
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    mySalesDetails: state?.mySalesDetails,
});

const mapDispatchToProps = {
    cleanMySalesDetailsAction,
    getMySalesDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MySalesDetailsPage);
