import React from "react";
import { LoadingButton } from "@mui/lab";
import ModalSim from "./modalNewIcc";

const SimActions = ({ setModalOpenSim, modalOpenSim, ticketId }) => {
    return (
        <>
            <LoadingButton
                fullWidth
                size="small"
                onClick={() => setModalOpenSim(true)}
                variant="outlined"
                className="button"
            >
                <h4>Cambio de SIM</h4>
            </LoadingButton>
            {modalOpenSim && (
                <ModalSim
                    modalOpen={modalOpenSim}
                    setOpenModal={setModalOpenSim}
                    ticketId={ticketId}
                />
            )}
        </>
    );
};

export default SimActions;
