import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";

const SelectState = ({ handleSelectState, stateValue, sepomex }) => {
    return (
        <Grid item xs={12}>
            <TextField
                variant="standard"
                select
                fullWidth
                id="select-stateValue"
                label="Estado"
                onChange={handleSelectState}
                value={stateValue}
            >
                {sepomex?.states.map((element) => {
                    return (
                        <MenuItem key={element.id} value={element.id}>
                            {element.estado}
                        </MenuItem>
                    );
                })}
            </TextField>
        </Grid>
    );
};

export default SelectState;
