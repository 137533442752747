import React from "react";
import { Avatar, Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const AvatarComponent = () => {
    return (
        <Grid item xs={1} mr={1}>
            <Avatar
                sx={{
                    bgcolor: "#441556",
                    width: 26,
                    height: 26,
                }}
            >
                <PersonIcon
                    sx={{
                        fontSize: 17,
                    }}
                />
            </Avatar>
        </Grid>
    );
};

export default AvatarComponent;
