import { createTheme } from "@mui/material/styles";

import { colors } from "./variables";

// Create a custom theme
export const theme = createTheme({
    palette: {
        primary: {
            main: colors?.color1,
        },
        secondary: {
            main: colors?.color2,
        },
        tertiary: {
            main: colors?.color3,
        },
        quaternary: {
            main: colors?.color4,
        },
        quinary: {
            main: colors?.color5,
        },
        senary: {
            main: colors?.color6,
        },
        septenary: {
            main: colors?.color7,
        },
        octonary: {
            main: colors?.color8,
        },
        nonary: {
            main: colors?.color9,
        },
        denary: {
            main: colors?.color10,
        },
        undenary: {
            main: colors?.color11,
        },
    },
    typography: {
        fontFamily: '"Poppins-Regular", "Helvetica", "Arial", sans-serif', // Change the default font family
        fontSize: 14, // Change the default font size
    },

    // Add more customizations here:
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1rem", // Change the font size
                    padding: "10px 20px", // Change the padding
                    borderRadius: "5px", // Change the border radius
                    fontFamily:
                        '"Poppins-Regular", "Helvetica", "Arial", sans-serif', // Change the default font family
                    textTransform: "none", // Change the text transform
                    // Add more styles here
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Change the color of the label when the TextField is focused
                    "& label.Mui-focused": {
                        color: colors?.color3,
                    },
                    // Change the color of the underline when the TextField is focused
                    "& .MuiInput-underline:after": {
                        borderBottomColor: colors?.color3,
                    },
                    // Change the color of the border when the TextField is focused
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: colors?.color3,
                        },
                    },
                },
            },
        },
    },
});
