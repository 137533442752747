import axiosTokenInstance from "../../../services/api/backoffice";
import { EVENTS, EVENTS_ERROR } from "_models/redux/types";

// Action function
export const getEvents = (params) => async (dispatch) => {
    const { ticket_id } = params || {
        ticket_id: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/${ticket_id}/mobile-suscriber-events`
        );

        const { data } = response || {};

        dispatch({
            type: EVENTS,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: EVENTS_ERROR,
            payload: e || {},
        });
    }
};
