import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";

export const PageListSaleTransferHook = ({
    cleanTransferSalesClean,
    listTransferSalesAction,
    listTransferSales,
}) => {
    const { payload } = listTransferSales || {};
    const { rows, pagination } = payload || {};
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    // Estados para los valores de los campos de texto
    const [dateValue, setDateValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [phoneValue, setPhoneValue] = useState("");
    const [nameValue, setNameValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [statusValue, setStatusValue] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        listTransferSalesAction(page, size, searchParams).then(() => {
            setLoading(false);
        });
        return () => {
            cleanTransferSalesClean();
        };
    }, [
        page,
        size,
        searchParams,
        listTransferSalesAction,
        cleanTransferSalesClean,
    ]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("es-ES");
    };

    // Define las columnas

    const columns = [
        {
            field: "id",
            headerName: "ID",
            type: "button", // Indica que este campo es un botón
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {params.row.id}
                </Button>
            ),
        },
        {
            field: "lead_name",
            headerName: "Cliente",
            type: "text",
            width: 150,
        },
        {
            field: "lead_contact_phone",
            headerName: "Teléfono",
            type: "text",
            width: 120,
        },
        {
            field: "back_office_name",
            headerName: "Nombre",
            width: 250,
        },
        {
            field: "sim_quantity",
            headerName: "SIM",
            type: "text",
            width: 110,
            valueGetter: (params) => {
                return params.value === null ? 0 : params.value;
            },
        },
        {
            field: "esim_quantity",
            headerName: "eSIM",
            type: "text",
            width: 200,
            valueGetter: (params) => {
                return params.value === null ? 0 : params.value;
            },
        },
        {
            field: "created",
            headerName: "Fecha",
            type: "text",
            width: 200,
            valueFormatter: (params) => {
                return formatDate(params.value);
            },
        },
        {
            field: "total_amount",
            headerName: "Total",
            type: "text",
            width: 200,
        },
        {
            field: "status",
            headerName: "Status",
            type: "text",
            width: 200,
        },
    ];

    // Función para manejar el clic en una fila
    const handleIdButtonClick = (id) => {
        if (id) {
            navigate(`/sales/ejecutivo/detail-transfer-sales/${id}`);
        }
    };

    // Función para manejar el clic en el botón
    const handleButtonClick = () => {
        navigate("/sales/ejecutivo/transfer-sales");
    };

    // Mapear los datos a las columnas definidas
    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleSearch = async (data) => {
        setLoading(true);

        const newSearchParams = {};

        if (data.date) {
            newSearchParams.date = data.date;
        }

        if (data.email) {
            newSearchParams.email = data.email;
        }

        if (data.phone) {
            newSearchParams.phone = data.phone;
        }

        if (data.name) {
            newSearchParams.name = data.name;
        }

        if (data.status) {
            newSearchParams.status = data.status;
        }

        // Verificar si no se ha ingresado ningún valor en los campos de búsqueda
        const hasNoSearchParams = Object.keys(newSearchParams).length === 0;

        if (hasNoSearchParams) {
            setErrorMessage(
                "Por favor, ingrese al menos un valor en uno de los campos."
            );
            setLoading(false);
            return;
        } else {
            setErrorMessage("");
        }

        setSearchParams(newSearchParams);

        await listTransferSalesAction(1, size, newSearchParams)
            .then(() => {
                setPage(1);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error al realizar la búsqueda:", error);
            });
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        listTransferSalesAction(newPage, size, searchParams)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error al cambiar de página:", error);
            });
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    return {
        loading,
        dateValue,
        setDateValue,
        emailValue,
        setEmailValue,
        phoneValue,
        setPhoneValue,
        nameValue,
        setNameValue,
        columns,
        mappedRows,
        handleSearch,
        handleIdButtonClick,
        handleButtonClick,
        statusValue,
        setStatusValue,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        page,
        size,
        setPage,
        setSize,
        handleSubmit,
        errors,
        register,
        errorMessage,
    };
};
