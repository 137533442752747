import { useState, useEffect } from "react";

import moment from "moment";

export const useTableHook = ({ props }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const { linksObj, getLinksAction } = props || {};
    const { payload } = linksObj || {};
    const { result } = payload || {};

    useEffect(() => {
        getLinksAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateColumns();
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);

    const generateColumns = () => {
        const columnsArray = [
            {
                field: "lead_id",
                headerName: "ID",
                width: 150,
                headerAlign: "center",
                align: "center",
            },
            {
                field: "lead_name",
                headerName: "Nombre",
                width: 160,
                headerAlign: "center",
                align: "left",
            },
            {
                field: "description",
                headerName: "Descripción",
                width: 160,
                headerAlign: "center",
                align: "left",
            },
            {
                field: "created",
                headerName: "Creado",
                width: 150,
                headerAlign: "center",
                align: "center",
            },
            {
                field: "updated",
                headerName: "Actualizado",
                width: 160,
                headerAlign: "center",
                align: "center",
            },
        ];

        setColumns(columnsArray);
    };

    const generateRows = () => {
        const rowsArray = [];
        result?.forEach((item, index) => {
            rowsArray.push({
                lead_id: item.lead_id,
                lead_name: item.lead_name,
                description: item.description,
                created: moment(item.created, "YYYYMMDDhmmss").fromNow(),
                updated: moment(item.updated, "YYYYMMDDhmmss").fromNow(),
            });
        });

        setRows(rowsArray);
    };

    return { columns, rows, generateColumns, generateRows };
};
