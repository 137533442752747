import {
    GET_SUPPORT_GIFT_ORDERS_DETAIL,
    GET_SUPPORT_GIFT_ORDERS_DETAIL_ERROR,
    GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN,
    GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    method: undefined,
    payload: undefined,
    response: undefined,
    object: undefined,
    status: undefined,
    state: undefined,
    loaded: undefined,
};

export default function getGiftOrdersDetailReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case GET_SUPPORT_GIFT_ORDERS_DETAIL:
            return {
                ...state,
                method: action?.method || null,
                payload: action?.payload || null,
                response: action?.response || null,
                object: action?.object || null,
                status: action?.status || null,
                state: "success",
                loaded: true,
            };

        case GET_SUPPORT_GIFT_ORDERS_DETAIL_ERROR:
            return {
                ...state,
                method: action?.method || null,
                payload: action?.payload || null,
                response: action?.response || null,
                object: action?.object || null,
                status: action?.status || null,
                state: "error",
                loaded: true,
            };

        case GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN:
            return {
                ...state,
                method: action?.method || undefined,
                payload: action?.payload || undefined,
                response: action?.response || undefined,
                object: action?.object || undefined,
                status: action?.status || undefined,
                state: "success",
                loaded: false,
            };

        case GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN_ERROR:
            return {
                ...state,
                method: action?.method || undefined,
                payload: action?.payload || undefined,
                response: action?.response || undefined,
                object: action?.object || undefined,
                status: action?.status || undefined,
                state: "error",
                loaded: false,
            };
        default:
            return state;
    }
}
