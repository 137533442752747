import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ActionsLeadDetail from "./widgets/actions";
import FormLeadDetail from "./widgets/form";
import { LoadersContext } from "_models/context/app";
import { getLead } from "_models/redux/lead/action";
import { cleanLeadsAction } from "_models/redux/lead/clean-action";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { getOffers } from "_models/redux/offers/action";
import { cleanOffersAction } from "_models/redux/offers/clean-action";
import { patchCheckImei } from "_models/redux/check-imei/action";
import { cleanCheckImeiAction } from "_models/redux/check-imei/clean_action";
import { patchBackofficeLeadStatus } from "_models/redux/backoffice-lead-status/action";
import { cleanBackofficeLeadStatusAction } from "_models/redux/backoffice-lead-status/clean-action";
import { getBackofficeStatus } from "_models/redux/backoffice-status/action";
import { cleanBackofficeStatusAction } from "_models/redux/backoffice-status/clean-action";
import { putLeadAction } from "_models/redux/put-lead/action";
import { patchLeadAction } from "_models/redux/patch-lead/action";
import { cleanPutLeadAction } from "_models/redux/put-lead/clean-action";
import { getCheckPaymentStatus } from "_models/redux/get-check-payment-status/action";
import { cleanCheckPaymentStatusAction } from "_models/redux/get-check-payment-status/clean-action";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const LeadDetailPage = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const [generatedUrl, setGeneratedUrl] = useState("");
    const [copySuccess, setCopySuccess] = useState("");
    // Modal State Variables

    const [open, setOpen] = useState(false);
    const [responseTitle, setResponseTitle] = useState();
    const [responseDescription, setResponseDescription] = useState();
    const [responseIcon, setResponseIcon] = useState();
    const [responseCls, setResponseCls] = useState();
    const [showRequired, setShowRequired] = useState();
    const [customErrors, setCustomErrors] = useState();
    const [openProductModal, setOpenProductModal] = useState(false);

    const { putLeadAction, cleanPutLeadAction, putLead, checkPaymentStatus } =
        props || {};

    const urlProps = {
        generatedUrl,
        setGeneratedUrl,
        copySuccess,
        setCopySuccess,
        setIsScreenLoading,
        open,
        setOpen,
        responseTitle,
        setResponseTitle,
        responseDescription,
        setResponseDescription,
        responseIcon,
        setResponseIcon,
        responseCls,
        setResponseCls,
        checkPaymentStatus,
    };

    const { payload } = putLead || {};

    const { payment_link } = payload || {};
    const { context, detail, title } = payload || {};

    const params = useParams();
    const history = useNavigate();
    const navProps = { params, history };

    const { leadId } = params || {};

    const {
        reset,
        value,
        setFocus,
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const formProps = {
        reset,
        value,
        setFocus,
        register,
        setValue,
        getValues,
        handleSubmit,
        errors,
    };

    const parentProps = {
        formProps,
        navProps,
        urlProps,
        props,
        setShowRequired,
        showRequired,
        customErrors,
        setCustomErrors,
    };

    //MODAL

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setOpen(false);
    };

    const CustomModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        {/* <Button onClick={handleOpen}>Open modal</Button> */}
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className={responseCls}
                        >
                            <Box sx={style}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-icon"}>
                                        {responseIcon}
                                    </span>
                                    <span className={"modal-modal-title"}>
                                        {responseTitle}
                                    </span>
                                </Typography>
                                <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                >
                                    <span className={"modal-modal-description"}>
                                        {responseDescription}
                                    </span>
                                </Typography>
                            </Box>
                        </Modal>
                    </div>
                </Grid>
            </Grid>
        );
    };

    const handleProductModalClose = () => {
        setOpenProductModal(false);
    };
    const ProductModal = () => (
        <Modal
            open={openProductModal}
            onClose={handleProductModalClose}
            aria-labelledby="product-modal-title"
            aria-describedby="product-modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="product-modal-title"
                    variant="h6"
                    component="h2"
                >
                    Selección de producto requerida
                </Typography>
                <Typography id="product-modal-description" sx={{ mt: 2 }}>
                    Es obligatorio seleccionar el producto.
                </Typography>
            </Box>
        </Modal>
    );

    const onSubmit = (data) => {
        if (payment_link === undefined || payment_link === "") {
            setIsScreenLoading(true);
        }

        const carrier_product_id = data?.offerInput || "";
        if (carrier_product_id === undefined || carrier_product_id === "") {
            setOpenProductModal(true);
            setIsScreenLoading(false);
            return;
        }

        const putLeadObj =
            data?.simTypeInput === 1
                ? {
                      lead_id: leadId || "",
                      body: {
                          basic_info: {
                              name: data?.nameInput || "",
                              contact_phone: data?.contactPhoneInput || "",
                              email: data?.emailInput || "",
                          },
                          carrier_product_id: carrier_product_id,
                          shipping_address: {
                              address: data?.addressInput || "",
                              neighborhood_id:
                                  parseInt(data?.neighborhoodInput) || "",
                              contact_phone: data?.contactForServiceInput || "",
                              refrence: data?.referenceInput || "",
                          },
                      },
                  }
                : {
                      lead_id: leadId || "",
                      body: {
                          basic_info: {
                              name: data?.nameInput || "",
                              contact_phone: data?.contactPhoneInput || "",
                              email: data?.emailInput || "",
                          },
                          carrier_product_id: carrier_product_id,
                      },
                  };

        putLeadAction(putLeadObj);
    };

    useEffect(() => {
        return () => {
            cleanPutLeadAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (context) {
            setOpen(true);
        }

        setIsScreenLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [putLead]);

    useEffect(() => {
        if (payment_link) {
            setGeneratedUrl(payment_link);
            setCopySuccess("¡Enlace generado exitosamente!");
            setIsScreenLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment_link]);

    useEffect(() => {
        if (context) {
            setOpen(true);
            setResponseTitle(title || "");
            setResponseDescription(detail || "");
            // setResponseIcon(<ErrorOutlineIcon />);
            setResponseCls("error-modal");
            setIsScreenLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context]);

    return (
        <Grid container className="lead-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    className="m-b-15"
                >
                    <a href="/leads">
                        <ArrowBackIosIcon className={"icon"} />
                    </a>
                    <h1>Lead ID: {leadId}</h1>
                </Grid>
                <Grid item xs={12} md={10}>
                    <FormLeadDetail parentProps={parentProps} />
                    {/* <CommentsLeadDetail /> */}
                </Grid>
                <Grid item xs={12} md={2}>
                    <ActionsLeadDetail parentProps={parentProps} />
                </Grid>
            </form>

            <CustomModal />
            <ProductModal />
        </Grid>
    );
};

////

const mapStateToProps = (state) => ({
    lead: state?.lead,
    offers: state?.offers,
    checkImei: state?.checkImei,
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    backofficeLeadStatus: state?.backofficeLeadStatus,
    backofficeStatus: state?.backofficeStatus,
    putLead: state?.putLead,

    checkPaymentStatus: state?.checkPaymentStatus,
    patchLeadObj: state?.patchLeadObj,
});

////

const mapDispatchToProps = {
    getLead,
    cleanLeadsAction,

    municipalityAction,
    neighborhoodAction,

    getOffers,
    cleanOffersAction,

    patchCheckImei,
    cleanCheckImeiAction,

    patchBackofficeLeadStatus,
    cleanBackofficeLeadStatusAction,

    getBackofficeStatus,
    cleanBackofficeStatusAction,

    putLeadAction,
    cleanPutLeadAction,

    getCheckPaymentStatus,
    cleanCheckPaymentStatusAction,

    patchLeadAction,
};

////

export default connect(mapStateToProps, mapDispatchToProps)(LeadDetailPage);
