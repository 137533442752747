import React from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import IncidenceTimeCounter from "./helpdesk-incidence-timecounter-component";
import { getStatusList } from "_models/redux/get-status/action";
import { changeStatusTicket } from "_models/redux/change-status/actions";
import TicketIncidenceInfo from "./helpdesk-Incidence-Info-component";
import CustomerProfileHeaderIncidence from "./helpdesk-profile-header-component";
import { helpdeskDetailContext } from "../data/helpdesk-detail-context";

const HelpDeskDetailPage = (props) => {
    const helpdeskValues = {};

    return (
        <helpdeskDetailContext.Provider value={helpdeskValues}>
            <Grid container>
                <Grid item xs={9} className="tabs-container">
                    <IncidenceTimeCounter />
                </Grid>
                <Grid item xs={3} className="ticket-bar">
                    <TicketIncidenceInfo />
                    <CustomerProfileHeaderIncidence />
                </Grid>
            </Grid>
        </helpdeskDetailContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    customerProfileReducer: state.customerProfile,
    incidence: state.incidence,
    statusList: state.statusList,
    changeStatusTicket: state.changeStatusTicket,
    ticketById: state.ticketById,
});
const mapDispatchToProps = {
    getCustomerProfile,
    getTicketById,
    getStatusList,
    changeStatusTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskDetailPage);
