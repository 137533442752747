import { FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR } from "_models/redux/types";
import axiosAuthInstance from "../../../services/api/authentication";

export const forgotPasswordAction = (params) => async (dispatch) => {
    const { user_email } = params || {
        user_email: undefined,
    };

    const paramsData = {
        email: user_email,
    };

    try {
        const response = await axiosAuthInstance.post(
            `/api/users/forgot-password`,
            paramsData
        );

        const { data, status } = response;

        dispatch({
            type: FORGOT_PASSWORD,
            payload: data,
            status: status,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: FORGOT_PASSWORD_ERROR,
            payload: e || {},
        });
    }
};
