import { Grid } from "@mui/material";
import { Chart } from "react-google-charts";

import { useDailySalesManagerChartHook } from "../hooks/chart-hook";

const DailySalesManagerChart = (props) => {
    const { resultChart, optionsGrid } = useDailySalesManagerChartHook();

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="auto"
                    data={resultChart}
                    options={optionsGrid}
                />
            </Grid>
        </Grid>
    );
};

export default DailySalesManagerChart;
