import axiosTokenInstance from "../../../services/api/backoffice";

import { LOCK_IMEI, LOCK_IMEI_ERROR } from "_models/redux/types";

// Action function
export const lockImei = (params) => async (dispatch) => {
    const { ticket_id, imei } = params || {
        token: undefined,
        key: undefined,
        new_password: undefined,
        ticket_id: undefined,
        imei: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `api/support/tickets/${ticket_id}/actions/imei/${imei}/lock`
        );

        const { data } = response || {};

        dispatch({
            type: LOCK_IMEI,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: LOCK_IMEI_ERROR,
            payload: e || {},
        });
    }
};
