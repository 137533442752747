import axiosTokenInstance from "../../../services/api/backoffice";
import {
    CHANGE_STATUS_TICKET,
    CHANGE_STATUS_TICKET_ERROR,
} from "_models/redux/types";

// Action function
export const changeStatusTicket = (params) => async (dispatch) => {
    const { ticketId, statusId } = params;
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.patch(
            `/api/support/tickets/${ticketId}/status/${statusId}`
        );

        const { data } = response || {};

        dispatch({
            type: CHANGE_STATUS_TICKET,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: CHANGE_STATUS_TICKET_ERROR,
            payload: e || {},
        });
    }
};
