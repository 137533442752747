import axiosTokenInstance from "services/api/backoffice";
import {
    GET_LEAD_STATUS_LIST,
    GET_LEAD_STATUS_LIST_ERROR,
} from "_models/redux/types";

// Action function
export const getLeadStatusList = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/marketing/lead_status?status=all`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: GET_LEAD_STATUS_LIST,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: GET_LEAD_STATUS_LIST_ERROR,
        });
    }
};
