import React from "react";

import { Grid } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";

const yearNow = new Date().getFullYear();

const FormFooterComponent = () => {
    return (
        <Grid item container xs={12} className={"form-footer-text-container"}>
            <p className={"form-footer-text"}>
                <CopyrightIcon className={"copyright-icon"} />
                Dalefon {yearNow} Todos los derechos reservados
            </p>
        </Grid>
    );
};

export default FormFooterComponent;
