import React from "react";
import RouterContainer from "../container";
import LoginPage from "modules/login/LoginPage";

const PrivateRoutes = ({ children }) => {
    return (
        <RouterContainer
            loggedChildren={children}
            unloggedChildren={<LoginPage />}
        />
    );
};

export default PrivateRoutes;
