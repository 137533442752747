import React from "react";
import { Grid } from "@mui/material";
import { Chart } from "react-google-charts";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardOffersHook } from "../hooks/cardOffersHook";
import OfferDetails from "./OfferDetails";

const CardOffers = ({ services, typeService }) => {
    const {
        testData,
        handleExpandClick,
        expanded,
        daysFrom,
        stringToDate,
        filterServiceType,
        PaddedGreyPaper,
        CenteredWhitePaper,
        colorsList,
        typeServiceSelect,
        getSpeedValue,
    } = CardOffersHook(services, typeService);

    return (
        <Grid container rowSpacing={5} columnSpacing={6}>
            {services.map((offer, index) => {
                const { freeUnit } = offer;
                const { totalAmt, unusedAmt } = freeUnit;

                let pValue = (unusedAmt * 100) / totalAmt;

                const options = {
                    legend: "none",
                    pieHole: 0.7,
                    pieSliceText: "none",
                    pieStartAngle: -125,
                    tooltip: { trigger: "none" },
                    slices: {
                        0: { color: colorsList[index % colorsList.length] },
                        1: { color: "#F1F1F1" },
                        2: { color: "transparent" },
                    },
                };
                return !filterServiceType(
                    typeServiceSelect,
                    offer.name
                ) ? null : (
                    <Grid item xs={4} key={index} className="offers-container">
                        <Grid container sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <CenteredWhitePaper sx={{ boxShadow: 0 }}>
                                    <h4 className="header">{offer.name}</h4>
                                    <Grid container className="chart-container">
                                        <Grid item xs={12} className="chart">
                                            <Chart
                                                chartType="PieChart"
                                                data={[
                                                    ["", "Percentage"],
                                                    ["", pValue * 0.7],
                                                    ["", 70 - pValue * 0.7],
                                                    ["", 30],
                                                ]}
                                                options={options}
                                                width={"100%"}
                                                height={"250px"}
                                                position={"relative"}
                                            />
                                        </Grid>

                                        <Grid container sx={{ zIndex: "1" }}>
                                            <Grid item xs={6}>
                                                <p className="colored">
                                                    {Math.round(
                                                        offer.freeUnit
                                                            .totalAmt -
                                                            offer.freeUnit
                                                                .unusedAmt
                                                    )}{" "}
                                                    {typeService}
                                                </p>
                                                <p>Consumidos </p>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <p className="colored">
                                                    {offer.freeUnit.totalAmt}{" "}
                                                    {typeService}
                                                </p>
                                                <p>Totales</p>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className="available m-t-20"
                                            display={"flex"}
                                            sx={{
                                                px: 2,
                                                py: 1,
                                            }}
                                        >
                                            <Grid xs={10}>
                                                <p>Disponibles </p>
                                                <h3>
                                                    {Math.round(
                                                        offer.freeUnit.unusedAmt
                                                    )}{" "}
                                                    {typeService}{" "}
                                                </h3>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                display={"flex"}
                                                justifyContent={"flex-end"}
                                                sx={{
                                                    mt: 1,
                                                }}
                                            >
                                                <ExpandMore
                                                    expand={expanded}
                                                    onClick={handleExpandClick}
                                                    aria-expanded={expanded}
                                                    aria-label="show more"
                                                >
                                                    <ExpandMoreIcon />
                                                </ExpandMore>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CenteredWhitePaper>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" rowSpacing={1}>
                            {offer.detailOfferings.map((offerDetail, i) => {
                                const startDate = stringToDate(
                                    offerDetail.effectiveDate
                                );
                                const endDate = stringToDate(
                                    offerDetail.expireDate
                                );
                                const remainderDays = daysFrom(
                                    startDate,
                                    endDate
                                );
                                return (
                                    <OfferDetails
                                        PaddedGreyPaper={PaddedGreyPaper}
                                        key={i}
                                        expanded={expanded}
                                        offerDetail={offerDetail}
                                        offer={offer}
                                        typeService={typeService}
                                        getSpeedValue={getSpeedValue}
                                        startDate={startDate}
                                        endDate={endDate}
                                        remainderDays={remainderDays}
                                        testData={testData}
                                        colorsList={colorsList}
                                        index={index}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default CardOffers;
