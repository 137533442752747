import React from "react";

const RechargeHistoryButton = ({ onClick }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={onClick}>Historial de recargas</button>
        </div>
    );
};

export default RechargeHistoryButton;
