import React from "react";
import { postResumeLineAction } from "_models/redux/request-resume-line/action";
import { connect } from "react-redux";
import { getEvents } from "_models/redux/events/action";
import { LoadingButton } from "@mui/lab";
import ModalCustomActions from "modules/support/my-tickets/flow/detailTickets/components/actions-tickets/modalCustom";
import { ActionResumeLineHook } from "../../hooks/actionsTicketsHooks/actionResumeLineHook";

const ResumeLine = (props) => {
    const {
        modalOpen,
        setModalOpen,
        isLoading,
        setIsLoading,
        alert,
        setAlert,
        errorMssge,
        setErrorMssge,
        severityAlert,
        setSeverityAlert,
        handleClickApn,
        handleResumeLine,
        clearFunction,
    } = ActionResumeLineHook(props);
    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickApn}
                variant="outlined"
                className="button"
            >
                <h4>Reanudar Línea</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalCustomActions
                    modalOpen={modalOpen}
                    setOpenModal={setModalOpen}
                    titleModal="Reanudar la línea"
                    subTitleModal="¿Está seguro que desea reanudar la línea?"
                    actionRequest={handleResumeLine}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    alert={alert}
                    setAlert={setAlert}
                    errorMssge={errorMssge}
                    setErrorMssge={setErrorMssge}
                    severityAlert={severityAlert}
                    setSeverityAlert={setSeverityAlert}
                    clearFunction={clearFunction}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    postResumeLineReducer: state.resumeLineAction,
    events: state.events,
});
const mapDispatchToProps = {
    postResumeLineAction,
    getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeLine);
