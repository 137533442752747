import { useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { postBindAssistedOrderAction } from "_models/redux/post-bind-assisted-order/action";
import { cleanPostBindAssistedOrderAction } from "_models/redux/post-bind-assisted-order/clean-action";
import NewAssistedLeadFormComponent from "./new-assisted-lead-form/new-assisted-lead-form-component";
import ModalWidgetComponent from "widgets/modal/modal-widget-component";

const NewAssistedLead = (props) => {
    const [open, setOpen] = useState(false);
    const [postErrorResponse, setPostErrorResponse] = useState();
    const [postSuccessResponse, setPostSuccessResponse] = useState();

    return (
        <Grid container>
            <Grid item xs={12}>
                {/* <button
                    className="openModalBtn"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <AddIcon className="svg" />
                    Venta asistida
                </button> */}

                <ModalWidgetComponent
                    inherit={{
                        open,
                        setOpen,
                        dialog: {
                            title: "Venta asistida",
                            description:
                                "Asignación de la orden de compra al actual usuario backoffice",
                            content: (
                                <NewAssistedLeadFormComponent
                                    inherit={{
                                        open,
                                        setOpen,
                                        postErrorResponse,
                                        postSuccessResponse,
                                        setPostErrorResponse,
                                        setPostSuccessResponse,
                                        properties: props,
                                    }}
                                />
                            ),
                            actions: <></>,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({
    postBindAssistedOrder: state.postBindAssistedOrder,
});
const mapDispatchToProps = {
    postBindAssistedOrderAction,
    cleanPostBindAssistedOrderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAssistedLead);
