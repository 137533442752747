import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";
import moment from "moment";

// Función para obtener detalles de la dirección
export const getAddressDetails = (orderDetails) => [
    {
        label: "Dirección",
        value: orderDetails?.address,
    },
    {
        label: "Municipio",
        value: orderDetails?.municipality,
    },
    {
        label: "Colonia",
        value: orderDetails?.neighborhood,
    },
    {
        label: "Estado",
        value: orderDetails?.state,
    },
    {
        label: "Código Postal",
        value: orderDetails?.zipcode,
    },
    {
        label: "Referencia",
        value: orderDetails?.reference,
    },
];

// Función para obtener detalles del contacto
export const getContactDetails = (orderDetails) => [
    {
        label: "Teléfono de Contacto",
        value: orderDetails?.contact_phone,
    },
    {
        label: "Correo Electrónico",
        value: orderDetails?.email,
    },
    {
        label: "Nombre",
        value: orderDetails?.name,
    },
    {
        label: "Nombre del Receptor",
        value: orderDetails?.receiver_name,
    },
];

// Función para obtener detalles del pedido
export const getOrderDetails = (orderDetails) => [
    {
        label: "Número de Pedido",
        value: orderDetails?.order_number,
    },
    {
        label: "Tipo de Orden",
        value: orderDetails?.payment_order_type,
    },
    {
        label: "Producto",
        value: orderDetails?.product_name,
    },
    {
        label: "Monto",
        value: <CurrencyFormatter amount={orderDetails?.amount} />,
    },
    {
        label: "Estado del Pago",
        value: orderDetails?.payment_status,
    },
];

// Función para obtener detalles del pago
export const getDateDetails = (orderDetails) => [
    {
        label: "Fecha de Creación",
        value: convertToMexicoTime(orderDetails?.created),
    },
];
