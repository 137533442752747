import {
    CLEAN_DETAILS_TRANSACTIONS_TOKU,
    CLEAN_DETAILS_TRANSACTIONS_TOKU_ERROR,
} from "_models/redux/types";

export const cleanDetailTokuAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAILS_TRANSACTIONS_TOKU,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAILS_TRANSACTIONS_TOKU_ERROR,
        });
    }
};
