import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "moment/locale/es";

import { LoadersContext } from "_models/context/app";

export const SalesDataTableHooks = ({
    getMySales,
    cleanMySalesAction,
    getMySalesDetails,
    mySales,
}) => {
    dayjs.locale("es");

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const currentDate = dayjs();

    const firstDayOfMonth = currentDate.startOf("month");
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState();
    const [options, setOptions] = useState([]);
    const [dropdownValue, setDropdownValue] = useState("");
    const [startValue, setStartValue] = useState(firstDayOfMonth);
    const [endValue, setEndValue] = useState(currentDate);
    const [fromTime, setFromTime] = useState(firstDayOfMonth.format());
    const [toTime, setToTime] = useState(currentDate.format());
    const [render, setRender] = useState(true);
    const [data, setData] = useState([]);
    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );
    const [dayDate, setDayDate] = useState("");
    const location = useLocation();
    const history = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const from_time_1 = queryParameters.get("from_time");
    const to_time_1 = queryParameters.get("to_time");
    const daySelected = queryParameters.get("date");

    useEffect(() => {
        if (from_time_1 !== null) {
            const fromDate = dayjs(from_time_1);
            const toDate = dayjs(to_time_1);

            const areNotFirstAndLastDay =
                !fromDate.isSame(firstDayOfMonth, "day") ||
                !toDate.isSame(currentDate.endOf("month"), "day");

            setFromTime(from_time_1);
            setToTime(to_time_1);

            if (areNotFirstAndLastDay) {
                setDisplayDatepickers("displayDatepickers shown");
                setStartValue(fromDate);
                setEndValue(toDate);
            } else {
                setDisplayDatepickers("displayDatepickers hidden");
            }
        } else if (daySelected !== null) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, from_time_1, to_time_1, daySelected]);

    useEffect(() => {
        populateDropdown();
        const urlContent = window.location.href;

        if (urlContent.includes("from_time")) {
            const urlSplit = urlContent.split("?")[1];
            setFromTime(urlSplit.split("&")[0].split("from_time=")[1]);
            setToTime(urlSplit.split("&")[1].split("to_time=")[1]);
            setStartValue(dayjs(urlSplit.split("&")[0].split("from_time=")[1]));
            setEndValue(dayjs(urlSplit.split("&")[1].split("to_time=")[1]));
        }

        return () => {
            cleanMySalesAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (location?.search === "" && !render) {
            if (
                fromTime.split("-")[2] !== "01T00:00:00" ||
                toTime.split("-")[2].split("T")[1] !== "23:59:59"
            ) {
                setDisplayDatepickers("displayDatepickers shown");
            }
            if (dropdownValue === 1) {
                getMySalesMethod();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromTime, toTime]);

    useEffect(() => {
        if (fromTime && toTime && render) {
            setRender(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dropdownValue !== null && dropdownValue !== "" && !render) {
            setRender(false);
            if (dropdownValue !== 1) {
                getMySalesMethod();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownValue]);

    useEffect(() => {
        const dataArray = [];
        dataArray.push(["Fecha", "# Venta diaria"]);
        const { payload } = mySales || {};
        const { result } = payload || {};
        if (mySales?.loaded) {
            setIsLoading(false);
            setRows(payload);
            if (result?.length > 0) {
                result.forEach((item) => {
                    dataArray.push([item?.date || 0, item?.total_sales]);
                });
            }
        }
        setData(dataArray);
    }, [mySales]);

    useEffect(() => {
        if (dayDate !== "") {
            getMySalesDetails({
                date: dayDate,
            });
            history(`/sales/dashboard/details?date=${dayDate}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayDate]);

    const getMySalesMethod = () => {
        const fromTimeFormatted = fromTime
            ? dayjs(fromTime).format("YYYY-MM-DD")
            : "";
        const toTimeFormatted = toTime
            ? dayjs(toTime).format("YYYY-MM-DD")
            : "";

        const queryParams = `from_time=${fromTimeFormatted}&to_time=${toTimeFormatted}`;

        const newUrl = `/sales/dashboard?${queryParams}`;
        window.history.pushState(null, "", newUrl);

        getMySales({
            from_time: fromTimeFormatted,
            to_time: toTimeFormatted,
        });
    };

    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
    };

    const handleMySalesDetails = (param) => {
        // window.history.pushState(
        //     null,
        //     "",
        //     `/sales/dashboard/details?date=${param}`
        // );

        setDayDate(param);
    };

    const populateDropdown = () => {
        let myArray = [];

        for (let i = 0; i <= 5; i++) {
            const forPreviousID = moment()
                .subtract(i, "months")
                .format("YYYY-MM");

            const forPreviousName = moment()
                .subtract(i, "months")
                .format("MMMM");

            const forPreviousMonth = moment()
                .subtract(i, "months")
                .format("MM");

            const forPreviousYear = moment()
                .subtract(i, "months")
                .format("YYYY");

            myArray.push({
                id: forPreviousID,
                name: forPreviousName,
                month: forPreviousMonth,
                year: forPreviousYear,
            });
        }

        const urlContent = window.location.href;

        if (urlContent.includes("?")) {
            const urlSplit = urlContent.split("?")[1];
            const queryParams = urlSplit.split("&");

            let urlMonth = null;

            queryParams.forEach((param) => {
                const [key, value] = param.split("=");
                if (key === "from_time") {
                    urlMonth = moment(value).format("YYYY-MM");
                }
            });

            const urlMonthExistsInArray = myArray.some(
                (option) => option.id === urlMonth
            );

            if (urlMonthExistsInArray) {
                setDropdownValue(urlMonth);
            } else {
                setDropdownValue("Personalizado");
            }
        } else {
            setDropdownValue(moment().format("YYYY-MM"));
        }

        setOptions(myArray);
    };

    const inputChange = ({ target }) => {
        const { value } = target || {};

        if (value === 1) {
            setDisplayDatepickers("displayDatepickers shown");
            setStartValue(dayjs(null));
            setEndValue(dayjs(null));
        } else if (value === 0) {
            setDisplayDatepickers("displayDatepickers hidden");
            setFromTime(moment().startOf("month").format());
            setToTime(moment().add(1, "months").startOf("month").format());

            setStartValue(
                dayjs(moment().startOf("month").format("MM-DD-YYYY"))
            );
            setEndValue(dayjs(moment().endOf("month").format("MM-DD-YYYY")));
        } else {
            setDisplayDatepickers("displayDatepickers hidden");
            setFromTime(
                moment(value + "-01")
                    .startOf("month")
                    .format()
            );
            setToTime(
                moment(value)
                    // .add(1, "months")
                    .endOf("month")
                    .format()
            );

            setStartValue(
                dayjs(
                    moment(value + "-01")
                        .startOf("month")
                        .format("MM-DD-YYYY")
                )
            );
            setEndValue(
                dayjs(
                    moment(value + "-01")
                        .endOf("month")
                        .format("MM-DD-YYYY")
                )
            );
        }

        setDropdownValue(value);
    };

    const optionsGrid = {
        height: 500,
        annotations: { style: "point" },
        legend: { position: "bottom" },
        hAxis: { textPosition: "out" },
    };

    return {
        optionsGrid,
        isLoading,
        rows,
        options,
        dropdownValue,
        handleClose,
        data,
        inputChange,
        handleMySalesDetails,
        displayDatepickers,
        startValue,
        setStartValue,
        setDropdownValue,
        setFromTime,
        open,
        endValue,
        setEndValue,
        setToTime,
    };
};
