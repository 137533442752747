import React from "react";
import { Alert, Collapse, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import SelectState from "./SelectState";
import SelectNir from "./SelectNir";

const ModalChangeNir = ({
    onSubmit,
    handleSubmit,
    closePopup,
    alert,
    error,
    changeNir,
    severityAlert,
    errorMssge,
    loaderButton,
    nirNumber,
    handleSelectState,
    stateValue,
    sepomex,
    handleSelectNIR,
    nirValueSelect,
    disableSelectNir,
    nirsMap,
}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="modalBackground">
                <Grid container xs={4} className="modalContainer">
                    <Grid container>
                        <Grid item xs={6} className="title">
                            <h3>Cambio de NIR</h3>
                        </Grid>
                        <Grid item xs={6} className="titleCloseBtn">
                            <button onClick={closePopup}>
                                <CloseIcon />
                            </button>
                        </Grid>{" "}
                    </Grid>
                    <Grid container xs={12} rowSpacing={2}>
                        <SelectState
                            handleSelectState={handleSelectState}
                            stateValue={stateValue}
                            sepomex={sepomex}
                        />
                        <SelectNir
                            handleSelectNIR={handleSelectNIR}
                            nirValueSelect={nirValueSelect}
                            disableSelectNir={disableSelectNir}
                            nirsMap={nirsMap}
                        />
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        direction={"column"}
                        rowSpacing={0}
                        textAlign={"start"}
                    >
                        <Grid item>
                            <h4>NUEVO NIR : {nirNumber}</h4>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                        <Collapse in={alert}>
                            {error !== undefined || changeNir !== 0 ? (
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={closePopup}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                    severity={severityAlert}
                                >
                                    {errorMssge}
                                </Alert>
                            ) : null}
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} className="footer">
                        <button onClick={closePopup} id="cancelBtn">
                            Cancelar
                        </button>

                        <LoadingButton
                            size="small"
                            onClick={onSubmit}
                            endIcon={<SendIcon />}
                            loading={loaderButton}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Cambiar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default ModalChangeNir;
