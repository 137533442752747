import React from "react";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import { cleanTicketsAction } from "_models/redux/my-tickets/clean-actions";
import { DataGridHook } from "../controllers/DataGridHook";
import TicketsList from "./TicketsList";
import EmptyContainer from "./EmptyContainer";
import LinearProgressContainer from "./LinearProgressContainer";

const TicketsDatagrid = (props) => {
    const { handleNavigate, rows, loader } = DataGridHook(props);

    return (
        <div>
            {loader ? (
                <LinearProgressContainer loader={loader} />
            ) : rows?.length > 0 ? (
                <TicketsList rows={rows} handleNavigate={handleNavigate} />
            ) : (
                <EmptyContainer />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({ ticketsReducer: state.ticketsReducer });

const mapDispatchToProps = { getMyTickets, cleanTicketsAction };

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDatagrid);
