import React from "react";
import { Grid } from "@mui/material";
import ForgotPasswordContainer from "./container";
import forgotPasswordImage from "assets/images/reset-password/reset-pwd.png";

const ForgotPasswordPage = () => {
    return (
        <Grid item container xs={12} className={"forgot-password-page"}>
            <Grid
                item
                container
                xs={0}
                sm={0}
                md={4}
                lg={6}
                className={"forgot-password-image-container"}
            >
                <img
                    title="Forgot Password"
                    alt="Person resetting password"
                    src={forgotPasswordImage}
                    className={"reset-password-image"}
                />
            </Grid>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={6}
                className={"forgot-password-component-container"}
            >
                <ForgotPasswordContainer />
            </Grid>
        </Grid>
    );
};

export default ForgotPasswordPage;
