// Función para el estilo de Grid
export const gridItemSX = {
    xs: 12,
    sm: 4,
};

export const getSimTypeStyle = (simTypeName) => {
    let backgroundColor = "inherit";
    if (simTypeName === "SIM") {
        backgroundColor = "#A34398";
    } else if (simTypeName === "E-SIM") {
        backgroundColor = "#4C2484";
    }

    return {
        backgroundColor,
        color: "white",
        border: "1px solid #ccc",
        padding: "4px 12px",
        borderRadius: "8px",
        fontSize: "15px",
    };
};

// Función para el estilo de las tarjetas
export const cardStyle = {
    borderRadius: "16px",
    width: "100%",
};

// Definir funciones para estilos SX
export const gridContainerSX = {
    padding: 2,
    justifyContent: "center",
};
export const centerTextSX = { textAlign: "center" };
