import React from "react";

// import ModalGeneric from "  ./_add_popup/_component";

import { useShippingDetailFooter } from "./_hook";
import ModalGeneric from "modules/my-sales/submodules/leads/list/widgets/popup";

const ShippingDetailFooterComponent = (props) => {
    const {
        modalOpen,
        cleanChannelLead,
        cleanCreateLead,
        setModalOpen,
        createLeadObj,
        selectChannel,
        setSelectChannel,
        CreateLead,
        setDropdownOptionsChannels,
        dropdownOptionsChannels,
    } = useShippingDetailFooter();

    return (
        <>
            {modalOpen && (
                <ModalGeneric
                    cleanChannelLead={cleanChannelLead}
                    cleanCreateLead={cleanCreateLead}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    createLeadObj={createLeadObj}
                    selectChannel={selectChannel}
                    setSelectChannel={setSelectChannel}
                    CreateLead={CreateLead}
                    setDropdownOptionsChannels={setDropdownOptionsChannels}
                    dropdownOptionsChannels={dropdownOptionsChannels}
                />
            )}
        </>
    );
};

export default ShippingDetailFooterComponent;
