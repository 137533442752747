import { CLEAN_NETPAY, CLEAN_NETPAY_ERROR } from "_models/redux/types";

export const cleanNetPayAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_NETPAY,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_NETPAY_ERROR,
        });
    }
};
