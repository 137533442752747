import {
    LEAD_ASSIGNMENT,
    LEAD_ASSIGNMENT_ERROR,
    CLEAN_LEAD_ASSIGNMENT,
    CLEAN_LEAD_ASSIGNMENT_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function leadAssignmentReducer(state = initialState, action) {
    switch (action.type) {
        case LEAD_ASSIGNMENT:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case LEAD_ASSIGNMENT_ERROR:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_LEAD_ASSIGNMENT:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success-clean",
            };

        case CLEAN_LEAD_ASSIGNMENT_ERROR:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error-clean",
            };
        default:
            return state;
    }
}
