import { Grid, Button } from "@mui/material";

import { useGiftOrdersHeaders } from "modules/support-gift-orders/hooks/gift-orders-header/useGiftOrdersHeader";

const GiftOrdersHeaderButtons = () => {
    // Hook propio de la funcionalidad del encabezado (filtros)
    const { cleanAll, cleanDatePickers } = useGiftOrdersHeaders();

    return (
        <Grid container>
            <Grid item xs={6} md={3} lg={2} className={"filter-container"}>
                <Button
                    variant="outlined"
                    onClick={() => cleanDatePickers(null)}
                >
                    Limpiar fechas
                </Button>
            </Grid>
            <Grid item xs={6} md={3} lg={2} className={"filter-container"}>
                <Button variant="outlined" onClick={cleanAll}>
                    Limpiar todo
                </Button>
            </Grid>
            <Grid item xs={6} md={3} lg={2} className={"filter-container"}>
                <Button variant="outlined" type="submit">
                    Buscar
                </Button>
            </Grid>
        </Grid>
    );
};

export default GiftOrdersHeaderButtons;
