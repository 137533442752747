import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TableComponent from "widgets/table/component";
import TableManagerPrepaidByDay from "./components/table-row-prepaids-by-day";
import TableManagerPrepaid from "./components/table.row-prepaid";
import { Chart } from "react-google-charts";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableTotalrechargesBySubDistributor from "modules/dashoboard/kpis/manager/prepaid/components/table-row-distributor";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PrepaidChart = ({ parentProperties: { form, redux } = {} }) => {
    const { prepaidByDay, prepaidByProduct, prepaidsBySubDistributor } =
        redux || {};

    //variables de Estados
    const [data, setData] = useState([]);
    const [dataDistributor, setDataDistributor] = useState([]);
    const [dataByProduct, setDataByProduct] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsDistributor, setColumnsDistributor] = useState([]);
    const [columnsByProduct, setColumnsByProduct] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsDistributor, setRowsDistributor] = useState([]);
    const [rowsByProduct, setRowsByProduct] = useState([]);
    const [dataForGrid, setDataForGrid] = useState([]);
    const [dataForGridDistributor, setDataForGridDistributor] = useState([]);
    const [dataForGridByProduct, setDataForGridByProduct] = useState([]);
    const [noDataForGrid, setNoDataForGrid] = useState(false);

    // Funciones para generar columnas y filas
    const generateColumns = (fields) => {
        return fields.map((field) => ({
            ...field,
            position: "sticky",
        }));
    };

    const generateColumnsByDay = () => {
        const columnsGrid = generateColumns([
            {
                field: "date",
                headerName: <span style={{ fontWeight: "bold" }}>Fecha</span>,
                width: 150,
                headerAlign: "center",
            },
            {
                field: "prepaid",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Recargas</span>
                ),
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ]);

        setColumns(columnsGrid);
    };

    const generateColumnsByProduct = () => {
        const columnsGrid = generateColumns([
            {
                field: "date",
                headerName: <span style={{ fontWeight: "bold" }}>Fecha</span>,
                width: 150,
                headerAlign: "center",
            },
            {
                field: "name",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Producto</span>
                ),
                width: 160,
                headerAlign: "center",
                align: "left",
            },
            {
                field: "quantity",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Recargas</span>
                ),
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ]);

        setColumnsByProduct(columnsGrid);
    };

    const generateColumnsBySubDistributor = () => {
        const columnsGrid = generateColumns([
            {
                field: "date",
                headerName: <span style={{ fontWeight: "bold" }}>Fecha</span>,
                width: 150,
                headerAlign: "center",
            },
            {
                field: "sub_distributor",
                headerName: <span style={{ fontWeight: "bold" }}>Portal</span>,
                width: 160,
                headerAlign: "center",
                align: "left",
            },
            {
                field: "prepaid",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Recargas</span>
                ),
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ]);

        setColumnsDistributor(columnsGrid);
    };

    useEffect(() => {
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForGrid, dataForGridDistributor, dataForGridByProduct]);

    const generateRows = () => {
        if (dataForGrid !== undefined) {
            let itemRows = [];
            dataForGrid?.forEach((element, index) => {
                itemRows.push({
                    key: index,
                    date: element[0],
                    quantity: element[1],
                });
            });
            setRows(itemRows);
        }

        if (dataForGridDistributor !== undefined) {
            let itemRowsDistributor = [];
            dataForGridDistributor?.forEach((element, index) => {
                itemRowsDistributor.push({
                    key: index,
                    date: element[0],
                    sub_distributor: element[1],
                    prepaid: element[2],
                });
            });
            setRowsDistributor(itemRowsDistributor);
        }

        if (dataForGridByProduct !== undefined) {
            let itemRowsByProduct = [];
            dataForGridByProduct?.forEach((element, index) => {
                itemRowsByProduct.push({
                    key: index,
                    date: element[0],
                    name: element[1],
                    quantity: element[2],
                });
            });
            setRowsByProduct(itemRowsByProduct);
        }
    };

    const options = { height: 500 };

    useEffect(() => {
        generateColumnsByDay();
        generateColumnsByProduct();
        generateColumnsBySubDistributor();

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { payload } = prepaidByDay || {};
        let totalA = 0;
        if (payload) {
            const rows = Object?.keys(payload).map((key) => payload[key]);

            const dataArray = [];
            const dataArrayGrid = [];

            dataArray.push(["Fecha", "Recargas"]);

            if (rows?.length > 0) {
                rows?.forEach((item, index) => {
                    const rowsObject = Object?.keys(item).map(
                        (key) => item[key]
                    );

                    rowsObject?.forEach((itm, indx) => {
                        const numberOfPrepaids = itm?.quantity || 0;

                        totalA = totalA + parseInt(numberOfPrepaids);

                        dataArray.push([itm?.date || 0, itm?.quantity || 0]);
                        dataArrayGrid.push([
                            itm?.date || 0,
                            itm?.quantity || 0,
                        ]);
                    });
                });
            } else {
                dataArray.push([0, 0]);
                dataArrayGrid.push([0, 0]);
            }
            dataArrayGrid.push([
                <span style={{ fontWeight: "bold" }}>Total</span>,
                <span style={{ fontWeight: "bold" }}>{totalA}</span>,
            ]);
            setData(dataArray);
            setDataForGrid(dataArrayGrid);
            if (payload.result.length === 0) {
                setNoDataForGrid(true);
            } else {
                setNoDataForGrid(false);
            }
        }
    }, [prepaidByDay]);

    useEffect(() => {
        const { payload } = prepaidByProduct || {};
        let totalA = 0;

        if (payload) {
            const rowsGrid = Object?.keys(payload).map((key) => payload[key]);
            const dataArrayGrid1 = [];

            if (rowsGrid?.length > 0) {
                rowsGrid?.forEach((item) => {
                    const rowsObject = Object?.keys(item).map(
                        (key) => item[key]
                    );

                    rowsObject?.forEach((itm) => {
                        const numberOfPrepaids1 = itm?.quantity || 0;
                        totalA = totalA + parseInt(numberOfPrepaids1);
                        dataArrayGrid1.push([
                            itm?.date || 0,
                            itm?.name || "",
                            itm?.quantity || 0,
                        ]);
                    });
                });
            } else {
                dataArrayGrid1.push([0, 0]);
            }

            dataArrayGrid1.push([
                <span style={{ fontWeight: "bold" }}>Total</span>,
                <span></span>,
                <span style={{ fontWeight: "bold" }}>{totalA}</span>,
            ]);
            setDataForGridByProduct(dataArrayGrid1);

            const dates = payload.result.map((entry) => entry.date);
            const uniqueDates = [...new Set(dates)];
            const products = [
                ...new Set(payload.result.map((entry) => entry.name)),
            ];

            const columns = ["Fecha", ...products];
            const dataArray = uniqueDates.map((date) => {
                const row = [date];

                products.forEach((product) => {
                    const totalQuantityForDateAndProduct = payload.result
                        .filter(
                            (entry) =>
                                entry.date === date && entry.name === product
                        )
                        .reduce(
                            (total, entry) => total + parseInt(entry.quantity),
                            0
                        );

                    row.push(totalQuantityForDateAndProduct);
                });

                return row;
            });

            // columns.push("Dale 200");

            dataArray.unshift(columns);
            setDataByProduct(dataArray);
            if (payload.result.length === 0) {
                setNoDataForGrid(true);
            } else {
                setNoDataForGrid(false);
            }
        }
    }, [prepaidByProduct]);

    useEffect(() => {
        const { payload } = prepaidsBySubDistributor || {};
        let totalA = 0;

        if (payload) {
            const rowsGrid = Object?.keys(payload).map((key) => payload[key]);
            const dataArrayGrid = [];

            if (rowsGrid?.length > 0) {
                rowsGrid?.forEach((item) => {
                    const rowsObject = Object?.keys(item).map(
                        (key) => item[key]
                    );
                    rowsObject?.forEach((itm) => {
                        const numberOfPrepaids = itm?.quantity || 0;

                        totalA = totalA + parseInt(numberOfPrepaids);

                        dataArrayGrid.push([
                            itm?.date || 0,
                            itm?.sub_distributor || "",
                            itm?.quantity || 0,
                        ]);
                    });
                });
            } else {
                dataArrayGrid.push([0, 0]);
            }

            dataArrayGrid.push([
                <span style={{ fontWeight: "bold" }}>Total</span>,
                <span></span>,
                <span style={{ fontWeight: "bold" }}>{totalA}</span>,
            ]);
            setDataForGridDistributor(dataArrayGrid);
            const dates = payload.result.map((entry) => entry.date);
            const uniqueDates = [...new Set(dates)];
            const distributors = [
                ...new Set(
                    payload.result.map((entry) => entry.sub_distributor)
                ),
            ];

            const columns = ["Fecha", ...distributors];
            const dataArray = uniqueDates.map((date) => {
                const row = [date];

                distributors.forEach((distributor) => {
                    const totalQuantityForDateAndDistributor = payload.result
                        .filter(
                            (entry) =>
                                entry.date === date &&
                                entry.sub_distributor === distributor
                        )
                        .reduce(
                            (total, entry) => total + parseInt(entry.quantity),
                            0
                        );

                    row.push(totalQuantityForDateAndDistributor);
                });

                return row;
            });

            dataArray.unshift(columns);
            setDataDistributor(dataArray);

            if (payload.result.length === 0) {
                setNoDataForGrid(true);
            } else {
                setNoDataForGrid(false);
            }
        }
    }, [prepaidsBySubDistributor]);

    const ChartSection = ({ title, data, options, columns, rowsComponent }) => (
        <Grid item xs={12}>
            <h3>{title}</h3>
            <span className="line"></span>
            <Grid container>
                {" "}
                <Grid item xs={12} md={12} mt={6}>
                    <Chart
                        chartType="Line"
                        width="100%"
                        height="auto"
                        data={data}
                        options={options}
                    />
                </Grid>
            </Grid>
            <Grid container mt={7}>
                {" "}
                <Grid
                    item
                    xs={12}
                    md={12}
                    className={"table-accordion-container"}
                >
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Ver Detalle</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableComponent
                                columns={columns}
                                rowsComponent={rowsComponent}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Grid>
    );

    const NoDataMessage = () => (
        <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            height={345}
        >
            <div className="kpi-empty">
                <p>
                    <BarChartIcon />{" "}
                </p>
                <p>No contiene datos, por favor intenta otra fecha </p>
            </div>
        </Grid>
    );

    return (
        <Grid container spacing={5}>
            {prepaidByDay.loaded === true && !noDataForGrid ? (
                <ChartSection
                    title="Recarga por día"
                    data={data}
                    options={options}
                    columns={columns}
                    rowsComponent={<TableManagerPrepaidByDay rows={rows} />}
                />
            ) : (
                noDataForGrid && <NoDataMessage />
            )}

            {prepaidByDay.loaded === true && !noDataForGrid ? (
                <ChartSection
                    title="Recarga por producto"
                    data={dataByProduct}
                    options={options}
                    columns={columnsByProduct}
                    rowsComponent={<TableManagerPrepaid rows={rowsByProduct} />}
                />
            ) : (
                noDataForGrid && <NoDataMessage />
            )}

            {prepaidsBySubDistributor.loaded === true && !noDataForGrid ? (
                <ChartSection
                    title="Recargas por subdistribuidor"
                    data={dataDistributor}
                    options={options}
                    columns={columnsDistributor}
                    rowsComponent={
                        <TableTotalrechargesBySubDistributor
                            rows={rowsDistributor}
                        />
                    }
                />
            ) : (
                noDataForGrid && <NoDataMessage />
            )}
        </Grid>
    );
};

export default PrepaidChart;
