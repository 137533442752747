import React from "react";
import { connect } from "react-redux";
import OrdersHeader from "./widgets/header";
import OrdersDatagrid from "./widgets/datagrid";

const OrdersListPage = (props) => {
    return (
        <div>
            <OrdersHeader />
            <OrdersDatagrid />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersListPage);
