import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";

import ResetPasswordContainer from "./container";

import resetPasswordImage from "assets/images/reset-password/reset-pwd.png";

const ResetPasswordPage = () => {
    let params = useParams();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);

    return (
        <Grid item container xs={12} className={"reset-password-page"}>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={6}
                className={"reset-password-component-container"}
            >
                <ResetPasswordContainer pageParams={params} />
            </Grid>
            <Grid
                item
                container
                xs={0}
                sm={0}
                md={4}
                lg={6}
                className={"reset-password-image-container"}
            >
                <img
                    title="Reset Password"
                    alt="Reset Password"
                    src={resetPasswordImage}
                    className={"reset-password-image"}
                />
            </Grid>
        </Grid>
    );
};

export default ResetPasswordPage;
