import axiosTokenInstance from "../../../services/api/backoffice";
import {
    TICKETS_BY_NUMBER,
    TICKETS_BY_NUMBER_ERROR,
} from "_models/redux/types";

export const getTicketsByNumber = (params) => async (dispatch) => {
    const { directNumber } = params || {
        directNumber: undefined,
    };
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/${directNumber}/support/tickets`
        );

        const { data } = response || {};

        dispatch({
            type: TICKETS_BY_NUMBER,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: TICKETS_BY_NUMBER_ERROR,
            payload: e || {},
        });
    }
};
