import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/es";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const LeadsByCampaingFilters = (props) => {
    dayjs.locale("es");

    const { parentProperties } = props || {};

    const { form, states } = parentProperties || {};

    const { register } = form || {};

    const {
        fromTime,
        setFromTime,
        toTime,
        setToTime,
        displayDatepickers,
        setDisplayDatepickers,
    } = states || {};

    const [options, setOptions] = useState([]);

    const [dropdownValue, setDropdownValue] = useState(0);

    useEffect(() => {
        populateDropdown();

        return () => {};
    }, []);

    useEffect(() => {}, [parentProperties]);

    const inputChange = ({ target }) => {
        const { value } = target || {};

        if (value === 1) {
            setDisplayDatepickers("displayDatepickers shown");
            setFromTime(moment().startOf("month").format("YYYY-MM-DD"));
            setToTime(
                moment().add(1, "months").startOf("month").format("YYYY-MM-DD")
            );
        } else if (value === 0) {
            setDisplayDatepickers("displayDatepickers hidden");
            setFromTime(null);
            setToTime(null);
        } else {
            setDisplayDatepickers("displayDatepickers hidden");
            const selectedDate = moment(value + "-01").format("YYYY-MM-DD");
            setFromTime(selectedDate);
            setToTime(moment(selectedDate).endOf("month").format());
        }

        setDropdownValue(value);
    };

    const populateDropdown = () => {
        let myArray = [];

        for (let i = 0; i <= 5; i++) {
            const forPreviousID = moment()
                .subtract(i, "months")
                .format("YYYY-MM");

            const forPreviousName = moment()
                .subtract(i, "months")
                .format("MMMM");

            const forPreviousYear = moment()
                .subtract(i, "months")
                .format("YYYY");

            myArray.push({
                id: forPreviousID,
                name: forPreviousName,
                year: forPreviousYear,
            });

            if (i === 0) {
                setDropdownValue(forPreviousID);
            }
        }

        setOptions(myArray);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    select
                    fullWidth
                    label="*Fecha"
                    id="monthFilter"
                    name="monthFilter"
                    {...register("monthFilter")}
                    value={dropdownValue}
                    onChange={(e) => inputChange(e)}
                    className="m-tb-20 form-control customDropdown"
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#6C63FF",
                            },
                        },
                        background: "white",
                    }}
                >
                    {options?.map((option, index) => (
                        <MenuItem
                            key={option?.id}
                            value={option?.id || 0}
                            className="customDropdownItem"
                        >
                            {option?.name} {option?.year}
                        </MenuItem>
                    ))}
                    <MenuItem key={1} value={1}>
                        {"Personalizado"}
                    </MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12} className={displayDatepickers}>
                <Grid container>
                    <Grid item xs={5}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"es"}
                        >
                            <DatePicker
                                value={fromTime ? dayjs(fromTime) : null}
                                onChange={(newValue) => {
                                    setFromTime(
                                        newValue
                                            ? moment(
                                                  newValue.format("YYYY-MM-DD")
                                              ).format()
                                            : null
                                    );
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"es"}
                        >
                            <DatePicker
                                value={toTime ? dayjs(toTime) : null}
                                onChange={(newValue) => {
                                    setToTime(
                                        newValue
                                            ? moment(
                                                  newValue.format("YYYY-MM-DD")
                                              ).format()
                                            : null
                                    );
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LeadsByCampaingFilters;
