import axiosTokenInstance from "services/api/backoffice";
import { NETPAY, NETPAY_ERROR } from "_models/redux/types";

// Action function
export const netPayAction =
    (page, size, filters = {}) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                `/api/reports/v2/transactions-netpay`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: NETPAY,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: NETPAY_ERROR,
                payload: e || {},
            });
        }
    };
