export const colors = {
    color1: "#441556", //morado fuerte
    color2: "#642ba5", //morado
    color3: "#7544ef", //morado claro
    color4: "#ff28df", //rosa fuerte
    color5: "#cc1616", //rojo
    color6: "#cc8c16", //naranja/amarillo
    color7: "#29b77b", //verde claro
    color8: "#2ad5de", //azul claro
    color9: "#062d51", //azul fuerte
    color10: "#050505", //negro
    color11: "#ffffff", //blanco
};
