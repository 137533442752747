export const useLayoutMethods = ({ layoutVariables }) => {
    const {
        openSaleTransfer,
        openMenuSupport,
        openMenuSales,
        openMenuMarketing,
        openMenuReportsSales,
        openMenuReportsLines,
        openMenuManager,
        openShipping,
        openMenuAccounting,
        paymentSuppliers,
        setOpenSaleTransfer,
        setOpenMenuSupport,
        setOpenMenuSales,
        setOpenMenuMarketing,
        setOpenMenuReportsSales,
        setOpenMenuReportsLines,
        setOpenMenuManager,
        setOpenShipping,
        setOpenMenuAccounting,
        setOpen,
        setOpenPaymentSuppliers,
    } = layoutVariables || {};

    const handleOpenSuppliers = () => {
        setOpenPaymentSuppliers(!paymentSuppliers);
    };

    const handleOpenSaleTransfer = () => {
        setOpenSaleTransfer(!openSaleTransfer);
    };

    const handleClickSupport = () => {
        setOpenMenuSupport(!openMenuSupport);
    };

    const handleClickSales = () => {
        setOpenMenuSales(!openMenuSales);
    };

    const handleClickMarketing = () => {
        setOpenMenuMarketing(!openMenuMarketing);
    };

    const handleClickReportsSales = () => {
        setOpenMenuReportsSales(!openMenuReportsSales);
    };

    const handleClickReportsLines = () => {
        setOpenMenuReportsLines(!openMenuReportsLines);
    };

    const handleClickManager = () => {
        setOpenMenuManager(!openMenuManager);
    };

    const handleClickShipping = () => {
        setOpenShipping(!openShipping);
    };

    const handleClickMenuAccounting = () => {
        setOpenMenuAccounting(!openMenuAccounting);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return {
        layoutMethods: {
            handleOpenSaleTransfer,
            handleClickSupport,
            handleClickSales,
            handleClickMarketing,
            handleClickReportsSales,
            handleClickReportsLines,
            handleClickManager,
            handleClickShipping,
            handleClickMenuAccounting,
            handleDrawerOpen,
            handleDrawerClose,
            handleOpenSuppliers,
        },
    };
};
