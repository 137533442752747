import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

//Hook - Layout app bar:
import { useLayoutAppBar } from "../hooks/useLayoutAppBar";

const LayoutAppBar = () => {
    //Properties from hook:
    const {
        AppBar,
        open,
        handleDrawerOpen,
        handleDrawerClose,
        layoutAppBarWrapper,
        routerTitle,
    } = useLayoutAppBar() || {};

    return (
        <AppBar
            position="fixed"
            open={open}
            className={"layout-header"}
            style={layoutAppBarWrapper}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                    edge="start"
                    xs={{
                        marginRight: 5,
                        ...(open && { display: "none" }),
                    }}
                    size="large"
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    {routerTitle}
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default LayoutAppBar;
