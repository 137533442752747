import CustomDialog from "widgets/component/CustomDialog.js";

import GiftOrdersDetailContent from "./GiftOrdersDetailContent";

import { useGiftOrdersDetail } from "modules/support-gift-orders/hooks/gift-orders-detail/useGiftOrdersDetail";

const GiftOrdersDetail = () => {
    // Hook propio de la funcionalidad del detalle destrucuturando los objetos que necesita la interfaz
    const { openModal, handleCloseModal, buttons } = useGiftOrdersDetail();

    return (
        // Modal genérico que recibe los parámetros del detalle
        <CustomDialog
            title="Detalle: Orden de regalo"
            open={openModal}
            onClose={handleCloseModal}
            content={<GiftOrdersDetailContent />}
            extraButtons={buttons}
            showCloseButton={true}
            fullWidth={true}
            maxWidth={"lg"}
        />
    );
};

export default GiftOrdersDetail;
