import { Grid } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useModalWidgetHook } from "./modal-widget-hook";

const ModalWidgetComponent = (props) => {
    const { inherit } = props || {};
    const { open, dialog } = inherit || {};
    const { title, description, content, actions } = dialog || {};

    const { handleClose } = useModalWidgetHook(inherit);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                    <Grid className="modal-widget-component">{content}</Grid>
                </DialogContent>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        </>
    );
};

export default ModalWidgetComponent;
