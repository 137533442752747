import { useContext } from "react";
//Login Context:
import { LoginContext } from "../data/loginContext";

import { useTheme } from "@mui/material/styles";
//Login Styles:
import { loginStyles } from "../styles/loginStyles";

export const useLoginForm = () => {
    //Context instance and descructuring props at once:
    //(I'mean, at the same time)
    const { formProps, stateProps, methods } = useContext(LoginContext) || {};
    //Destructuring form properties:
    const { errors, register, handleSubmit } = formProps || {};

    //Desructuring state props:
    const {
        variant,
        progress,
        loginResponse,
        loginResponseCls,
        visibilityValues,
    } = stateProps || {};

    //Desructuring methods:
    const {
        sendLogin,
        showPasswordClick,
        showCodeClick,
        passwordOnChange,
        codeOnChange,
    } = methods || {};

    //Instance of Material-UI theme:
    const theme = useTheme();
    //Destructuring corresponding styles: login form
    const { loginForm } = loginStyles({ theme }) || {};
    //Descructuring login form styles:
    const {
        loginFormButton,
        loginFormButtonContainer,
        loginForgotPassword,
        loginFormLoader,
        loginFormErrorContainer,
        loginFormError,
    } = loginForm || {};

    //Return content properties, methos and styles:
    return {
        //Form Props:
        errors,
        register,
        handleSubmit,

        //State Props:
        variant,
        progress,
        loginResponse,
        loginResponseCls,
        visibilityValues,

        //Methods:
        sendLogin,
        showPasswordClick,
        passwordOnChange,
        codeOnChange,
        showCodeClick,

        //Styles:
        loginFormButtonContainer,
        loginFormButton,
        loginForgotPassword,
        loginFormLoader,
        loginFormErrorContainer,
        loginFormError,
    };
};
