import axiosTokenInstance from "services/api/backoffice";
import {
    TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR,
    TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR,
} from "_models/redux/types";

// Action function
export const prepaidsbySubDistributorAction = (params) => async (dispatch) => {
    const { from_time, to_time } = params || {};

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `api/reports/sim-purchase/prepaids-by-sub-distributor?from_time=${from_time}&to_time=${to_time}`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR,
        });
    }
};
