import React from "react";
import {
    Grid,
    Typography,
    MenuItem,
    IconButton,
    TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    itemContainerStyle,
    typeTextStyle,
    iconButtonStyle,
} from "../style/PageTransferSaleStyle";

const DynamicProductFields = ({
    items,
    handleFieldChange,
    offersData,
    handleRemoveItem,
}) => {
    if (!Array.isArray(offersData)) {
        return null;
    }

    return (
        <Grid container spacing={2} xs={12} justifyContent="center">
            {items.map((item, index) => (
                <Grid
                    item
                    xs={12}
                    key={index}
                    marginTop={4}
                    marginLeft={4}
                    style={itemContainerStyle}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} lg={3}>
                            <Typography
                                variant="h6"
                                style={typeTextStyle(item.type)}
                            >
                                {item.type === "simValues" ? "SIM" : "eSIM"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <TextField
                                label="Cantidad"
                                variant="outlined"
                                fullWidth
                                value={item.quantity || ""}
                                onChange={(e) =>
                                    handleFieldChange(
                                        index,
                                        e.target.value,
                                        "quantity"
                                    )
                                }
                                sx={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ maxWidth: "100%" }}>
                            <TextField
                                select
                                label="Seleccionar paquete"
                                variant="outlined"
                                fullWidth
                                value={item.productId || ""}
                                onChange={(e) =>
                                    handleFieldChange(
                                        index,
                                        e.target.value,
                                        "productId"
                                    )
                                }
                                sx={{ width: "100%" }}
                            >
                                {offersData.map((offer) => (
                                    <MenuItem
                                        key={offer.offer_id}
                                        value={offer.name}
                                    >
                                        {offer.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={1}
                            style={{ textAlign: "center" }}
                        >
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveItem(index)}
                            >
                                <DeleteIcon style={iconButtonStyle} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default DynamicProductFields;
