import axiosTokenInstance from "services/api/backoffice";
import { RECHARGE_HISTORY, RECHARGE_HISTORY_ERROR } from "_models/redux/types";

// Action function
export const rechargeHistoryAction = (params) => async (dispatch) => {
    console.log("rechargeHistoryAction -> params", params);
    const { ticketId, dates } = params || {};
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/${ticketId}/actions/prepaid`,
            { params: dates }
        );

        const { data, status } = response || {};

        dispatch({
            type: RECHARGE_HISTORY,
            response: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: RECHARGE_HISTORY_ERROR,
            response: e || {},
        });
    }
};
