import React from "react";
import { connect } from "react-redux";
import TicketHeader from "./components/headers";
import TicketsDatagrid from "./components/datagrid";
import { changeUser } from "_models/redux/change-user/action";
import { getUsers } from "_models/redux/get-user-list/action";
import { getTickets } from "_models/redux/tickets/action";
import { cleanGeneralTicketsAction } from "_models/redux/tickets/clean-actions";

const UserTicketsPage = (props) => {
    return (
        <div>
            <TicketHeader />
            <TicketsDatagrid props={props} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    ticketsReducer: state.ticketsReducer,
    generalTickets: state?.generalTickets,
    userList: state.userList,
    changeUser_: state.changeUser_,
});

const mapDispatchToProps = {
    getTickets,
    cleanGeneralTicketsAction,
    getUsers,
    changeUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTicketsPage);
