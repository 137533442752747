import { useGiftOrdersError } from "modules/support-gift-orders/hooks/gift-orders-error/useGiftOrdersError";

const GiftOrdersErrorContent = () => {
    // Hook propio de la funcionalidad del manejo de errores:
    // destructurando el mensaje de error

    const { errorModalMessage } = useGiftOrdersError();

    return <>{errorModalMessage}</>;
};

export default GiftOrdersErrorContent;
