import React from "react";
import { Grid } from "@mui/material";
import noIssues from "assets/images/tickets/no-issues.png";

const EmptyContainer = () => {
    return (
        <Grid container className="no-issues">
            <Grid item xs={12}>
                <img
                    title="No Issues"
                    alt="No Issues"
                    src={noIssues}
                    className={"no-issues-img"}
                />
            </Grid>
            <Grid item xs={12}>
                <h2>¡Felicidades!</h2>
                <p>Todas tus incidencias han sido resueltas.</p>
            </Grid>
        </Grid>
    );
};

export default EmptyContainer;
