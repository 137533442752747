import { Grid } from "@mui/material";

import MonthlySalesReportChart from "./chart-component";
import MonthlySalesReportTable from "./table-component";

const MonthlySalesReportContent = (props) => {
    return (
        <Grid item xs={12}>
            <Grid container mt={7}>
                <Grid xs={12} md={12}>
                    <MonthlySalesReportChart props={props} />
                </Grid>
                <Grid xs={12} md={12}>
                    <MonthlySalesReportTable props={props} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MonthlySalesReportContent;
