import { useWeeklySalesReportPageHook } from "./hooks/page-hook";

import WeeklySalesReportContainer from "./components/container-component";

const ManagerSalesWeeklyKPIsPage = () => {
    const { permission, userPermissions } = useWeeklySalesReportPageHook();

    return userPermissions
        ?.split(",")
        ?.find((element) => element === permission) ? (
        <div>
            <h4> Dashboard Ventas por semana </h4>
            <WeeklySalesReportContainer />
        </div>
    ) : null;
};

export default ManagerSalesWeeklyKPIsPage;
