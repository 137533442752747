import React, { useContext } from "react";

import { Grid, Button, IconButton, CircularProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { ResetPasswordContext } from "./context";

import miniLogo from "assets/images/mini-logo.png";
import FieldComponent from "widgets/field/component";
import ErrorMessageComponent from "widgets/errorMessage/component";
import FormFooterComponent from "widgets/formFooter/component";

const ResetPasswordComponent = () => {
    const resetPasswordContext = useContext(ResetPasswordContext);

    const { variant, progress } = resetPasswordContext || {
        variant: undefined,
        progress: undefined,
    };

    return (
        <Grid item container xs={12} className={"reset-password-component"}>
            <Grid item container xs={12} className={"reset-password-form"}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={resetPasswordContext.handleSubmit(
                        resetPasswordContext.sendResetPassword
                    )}
                >
                    <Grid item container xs={12}>
                        <Grid
                            item
                            container
                            xs={8}
                            className={"reset-password-title-container"}
                        >
                            <h3 className={"reset-password-title"}>
                                Reestablecer contraseña
                            </h3>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={4}
                            className={"reset-password-logo-container"}
                        >
                            <img
                                title="Login"
                                alt="Login"
                                src={miniLogo}
                                className={"reset-password-logo"}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"reset-password-field-container"}
                    >
                        <FieldComponent
                            fieldContext={resetPasswordContext}
                            fielEndIcon={
                                <IconButton size="large">
                                    <VisibilityIcon />
                                </IconButton>
                            }
                            fieldStartIcon=""
                            fieldClassName="new-password-textfield"
                            fieldName="new_password"
                            fieldLabel="Nueva contraseña"
                            fieldPlaceholder="Contraseña"
                            fieldDisabled={false}
                            fileldDefaultValue=""
                            fieldRequired={{
                                required: "La nueva contraseña es requerida",
                            }}
                            fieldError={
                                <ErrorMessageComponent
                                    errorParams={resetPasswordContext || {}}
                                    errorType={"new_password"}
                                />
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"reset-password-field-container"}
                    >
                        <FieldComponent
                            fieldContext={resetPasswordContext}
                            fielEndIcon={
                                <IconButton size="large">
                                    <VisibilityIcon />
                                </IconButton>
                            }
                            fieldStartIcon=""
                            fieldClassName="password-confirmation-textfield"
                            fieldName="password_confirmation"
                            fieldLabel="Confirmar nueva contraseña"
                            fieldPlaceholder="Nueva contraseña"
                            fieldDisabled={false}
                            fileldDefaultValue=""
                            fieldRequired={{
                                required:
                                    "La confirmación de la contraseña es requerida",
                            }}
                            fieldError={
                                <ErrorMessageComponent
                                    errorParams={resetPasswordContext || {}}
                                    errorType={"password_confirmation"}
                                />
                            }
                        />
                    </Grid>

                    <Grid item container xs={12}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={"reset-password-button"}
                            // onClick={resetPasswordContext.sendResetPassword}
                        >
                            Reestablecer
                        </Button>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"reset-password-loader"}
                    >
                        <CircularProgress variant={variant} value={progress} />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        className={"reset-password-response"}
                    >
                        <span
                            className={
                                resetPasswordContext.resetPasswordResponseCls
                                    .container || ""
                            }
                        >
                            <h5
                                className={
                                    resetPasswordContext
                                        .resetPasswordResponseCls.text || ""
                                }
                            >
                                {resetPasswordContext.resetPasswordResponse ||
                                    ""}
                            </h5>
                        </span>
                    </Grid>

                    <FormFooterComponent />
                </form>
            </Grid>
        </Grid>
    );
};

export default ResetPasswordComponent;
