// Import: Middleware Action & Custom Types
import { CLEAN_LINKS, CLEAN_LINKS_ERROR } from "_models/redux/types";

// Action function
export const cleanLinksAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_LINKS,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_LINKS_ERROR,
            payload: undefined,
        });
    }
};
