import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Grid } from "@mui/material";

const CustomPaginationTable = ({
    page,
    pageSize,
    rowCount,
    onPageChange,
    onPageSizeChange,
}) => {
    // Calculando el índice de inicio y fin de los elementos mostrados
    const startIndex = (page - 1) * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, rowCount);
    // Calculando el nuevo número total de páginas
    const totalPages = Math.ceil(rowCount / pageSize);

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Grid container sx={{ py: 2 }}>
                <Grid
                    item
                    xs={6}
                    md={"auto"}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box>
                        {rowCount === 0
                            ? "No se encontraron resultados"
                            : `${startIndex} - ${endIndex} de ${rowCount}`}
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={6}
                    md={"auto"}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mx: { xs: 0, md: 3 }
                    }}
                >
                    <Select
                        variant="standard"
                        size="small"
                        defaultValue={pageSize}
                        onChange={onPageSizeChange}
                        MenuProps={{
                            PaperProps: { sx: { maxHeight: 360 } },
                        }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={75}>75</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={"auto"}
                    sx={{
                        mt: { xs: 2, md: 0 },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => onPageChange(value)}
                        boundaryCount={3}
                        siblingCount={1}
                        showFirstButton
                        showLastButton
                        color="secondary"
                        variant="outlined"
                        shape="rounded"
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default CustomPaginationTable;
