import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

import TicketStatus from "./ticketStatus";

const TicketInfo = (props) => {
    const { ticketId } = props || {};

    const [ticketStatus, setTicketStatus] = useState();

    let params = useParams();

    useEffect(() => {
        setTicketStatus(params.ticketId);
    }, [params]);

    return (
        <Grid container className="ticket-id-status">
            <Grid item xs={6}>
                <h2>
                    <ConfirmationNumberIcon className="svg" /> {ticketId}
                </h2>
            </Grid>
            <Grid item xs={6}>
                <TicketStatus ticketId={ticketStatus} />
            </Grid>
        </Grid>
    );
};

export default TicketInfo;
