import axiosTokenInstance from "services/api/backoffice";
import { GET_LANDING_LIST, GET_LANDING_LIST_ERROR } from "_models/redux/types";

// Action function
export const getLandingList = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/marketing/landings?status=all`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: GET_LANDING_LIST,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: GET_LANDING_LIST_ERROR,
        });
    }
};
