import { RESET_PASSWORD, RESET_PASSWORD_ERROR } from "_models/redux/types";
import axiosAuthInstance from "../../../services/api/authentication";

export const resetPasswordAction = (params) => async (dispatch) => {
    const { token, key, new_password } = params || {
        token: undefined,
        key: undefined,
        new_password: undefined,
    };

    const paramsData = {
        token,
        key,
        new_password,
    };

    try {
        const response = await axiosAuthInstance.post(
            `/api/users/reset-password`,
            paramsData
        );

        const { data, status } = response;

        dispatch({
            type: RESET_PASSWORD,
            payload: data,
            status: status,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: RESET_PASSWORD_ERROR,
            payload: e || {},
        });
    }
};
