import moment from "moment-timezone";

// Función helper para convertir la fecha a hora local de México
export const convertToMexicoTime = (isoDate) => {
    // Parseamos la fecha ISO
    const date = moment.utc(isoDate);

    // Convertimos a hora local de México
    const mexicoTime = date
        .tz("America/Mexico_City")
        .format("YYYY-MM-DD HH:mm:ss");

    return mexicoTime;
};
