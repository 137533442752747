import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

//Component - Layout content (child):
import LayoutContent from "./LayoutContent";

import LayoutAppBar from "./LayoutAppBar";
import LayoutMenu from "./LayoutMenu";

const LayoutComponent = () => {
    //Main Layout Wrapper
    return (
        <Box sx={{ pt: 8 }}>
            <CssBaseline />

            <LayoutAppBar />
            <LayoutMenu />

            <LayoutContent />
        </Box>
    );
};

export default LayoutComponent;
