import { useContext } from "react";
import { RouterContext } from "router/context";
import { LayoutContext } from "modules/layout/data/layoutContext";

import { useTheme } from "@mui/material/styles";
//Layout Styles:
import { AppBar, layoutStyles } from "../styles/layoutStyles";

export const useLayoutAppBar = () => {
    const { routerTitle } = useContext(RouterContext);

    const layoutContext = useContext(LayoutContext) || {};
    const { layoutVariables, methods } = layoutContext || {};
    const { layoutMethods } = methods || {};
    const { open } = layoutVariables || {};
    const { handleDrawerOpen, handleDrawerClose } = layoutMethods || {};

    //Instance of Material-UI theme:
    const theme = useTheme();
    //Destructuring corresponding styles: layout container
    const { layoutAppBarWrapper } = layoutStyles({ theme }) || {};

    return {
        AppBar,
        open,
        handleDrawerOpen,
        handleDrawerClose,
        layoutAppBarWrapper,
        routerTitle,
    };
};
