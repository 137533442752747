import axiosTokenInstance from "../../../services/api/backoffice";

import { COMMENT_TICKETS, COMMENT_TICKETS_ERROR } from "_models/redux/types";

// Action function
export const postCommentsTikets =
    (params = undefined, sessionIncidence = undefined) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(
                `/api/support/tickets/${sessionIncidence}/comments`,
                params
            );

            const { data } = response || {};

            dispatch({
                type: COMMENT_TICKETS,
                payload: data || {},
            });
        } catch (e) {
            dispatch({
                type: COMMENT_TICKETS_ERROR,
                payload: e || {},
            });
        }
    };
