import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TableComponent from "widgets/table/component";
import TableManagerContacPhone from "modules/dashoboard/kpis/manager/purchase-report/components/table-row-contact-phone";
import TableManagerEmail from "modules/dashoboard/kpis/manager/purchase-report/components/table.row-email";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableTotalIpRestriction from "modules/dashoboard/kpis/manager/purchase-report/components/table-row-ip-restriction";

const PrepaidChart = ({ parentProperties: { form, redux } = {} }) => {
    const { getContactPhone, getEmailReport, ipRestriction } = redux || {};

    //variables de Estados

    const [columns, setColumns] = useState([]);
    const [columnsIpRestriction, setColumnsIpRestriction] = useState([]);
    const [columnsByEmail, setColumnsByEmail] = useState([]);
    const [rows, setContactPhone] = useState([]);
    const [rowsIpRestriction, setRowsIpRestriction] = useState([]);
    const [rowsEmail, setRowsEmail] = useState([]);
    const [dataForGrid, setDataForGrid] = useState([]);
    const [dataForGridIpRestriction, setDataForGridIpRestriction] = useState(
        []
    );
    const [dataForGridByEmail, setDataForGridByEmail] = useState([]);
    const [noDataForGrid, setNoDataForGrid] = useState(false);

    // Funciones para generar columnas y filas
    const generateColumns = (fields) => {
        return fields.map((field) => ({
            ...field,
            position: "sticky",
        }));
    };

    const generateColumnsContactPhone = () => {
        const columnsGrid = generateColumns([
            {
                field: "contact_phone",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Teléfono </span>
                ),
                width: 150,
                headerAlign: "center",
            },
            {
                field: "quantity",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Recargas</span>
                ),
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ]);

        setColumns(columnsGrid);
    };

    const generateColumnsTrackerEmail = () => {
        const columnsGrid = generateColumns([
            {
                field: "tracker_email",
                headerName: <span style={{ fontWeight: "bold" }}>Correo</span>,
                width: 150,
                headerAlign: "center",
            },
            {
                field: "quantity",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Recargas</span>
                ),
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ]);

        setColumnsByEmail(columnsGrid);
    };

    const generateColumnsByIpRestriction = () => {
        const columnsGrid = generateColumns([
            {
                field: "ip",
                headerName: <span style={{ fontWeight: "bold" }}>Ip</span>,
                width: 150,
                headerAlign: "center",
            },
            {
                field: "quantity",
                headerName: (
                    <span style={{ fontWeight: "bold" }}>Cantidad</span>
                ),
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ]);

        setColumnsIpRestriction(columnsGrid);
    };

    useEffect(() => {
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForGrid, dataForGridIpRestriction, dataForGridByEmail]);

    const generateRows = () => {
        if (dataForGrid !== undefined) {
            let itemRows = [];
            dataForGrid?.forEach((element, index) => {
                itemRows.push({
                    key: index,
                    contact_phone: element[0],
                    quantity: element[1],
                });
            });
            setContactPhone(itemRows);
        }

        if (dataForGridByEmail !== undefined) {
            let itemRowsByProduct = [];
            dataForGridByEmail?.forEach((element, index) => {
                itemRowsByProduct.push({
                    key: index,
                    tracker_email: element[0],
                    quantity: element[1],
                });
            });
            setRowsEmail(itemRowsByProduct);
        }

        if (dataForGridIpRestriction !== undefined) {
            let itemRowsDistributor = [];
            dataForGridIpRestriction?.forEach((element, index) => {
                itemRowsDistributor.push({
                    key: index,
                    ip: element[0],
                    quantity: element[1],
                });
            });
            setRowsIpRestriction(itemRowsDistributor);
        }
    };

    useEffect(() => {
        generateColumnsContactPhone();
        generateColumnsTrackerEmail();
        generateColumnsByIpRestriction();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { payload } = getContactPhone || {};
        let totalA = 0;

        if (payload) {
            const rows = Object?.keys(payload).map((key) => payload[key]);

            const dataArray = [];
            const dataArrayGrid = [];

            dataArray.push(["teléfono ", "Recargas"]);

            if (rows?.length > 0) {
                rows?.forEach((item, index) => {
                    const rowsObject = Object?.keys(item).map(
                        (key) => item[key]
                    );

                    rowsObject?.forEach((itm, indx) => {
                        const numberOfPrepaids = itm?.quantity || 0;

                        totalA = totalA + parseInt(numberOfPrepaids);

                        dataArray.push([
                            itm?.contact_phone || 0,
                            itm?.quantity || 0,
                        ]);
                        dataArrayGrid.push([
                            itm?.contact_phone || 0,
                            itm?.quantity || 0,
                        ]);
                    });
                });
            } else {
                dataArray.push([0, 0]);
                dataArrayGrid.push([0, 0]);
            }
            dataArrayGrid.push([
                <span style={{ fontWeight: "bold" }}>Total</span>,
                <span style={{ fontWeight: "bold" }}>
                    {Number(totalA).toLocaleString("en-US")}
                </span>,
            ]);

            setDataForGrid(dataArrayGrid);
            if (payload.result.length === 0) {
                setNoDataForGrid(true);
            } else {
                setNoDataForGrid(false);
            }
        }
    }, [getContactPhone]);

    useEffect(() => {
        const { payload } = getEmailReport || {};
        let totalEmail = 0;

        if (payload) {
            const dataArrayGrid1 = [];
            const products = [
                ...new Set(payload.result.map((entry) => entry.name)),
            ];

            if (payload.result.length > 0) {
                const columns = ["Correo Electrónico", ...products];
                const dataArray = payload.result.reduce((result, item) => {
                    const quantity = item.quantity || 0;
                    totalEmail += parseInt(quantity);
                    dataArrayGrid1.push([item.tracker_email || "", quantity]);

                    // Busca el producto actual en el resultado actual
                    const existingRow = result.find(
                        (row) => row[0] === item.date
                    );

                    if (existingRow) {
                        const productIndex = products.indexOf(item.name) + 1;
                        existingRow[productIndex] = quantity;
                    } else {
                        const newRow = new Array(products.length + 1).fill(0);
                        newRow[0] = item.date;
                        const productIndex = products.indexOf(item.name) + 1;
                        newRow[productIndex] = quantity;
                        result.push(newRow);
                    }

                    return result;
                }, []);

                dataArrayGrid1.push([
                    <span style={{ fontWeight: "bold" }}>Total</span>,
                    <span style={{ fontWeight: "bold" }}>
                        {Number(totalEmail).toLocaleString("en-US")}
                    </span>,
                ]);

                dataArray.unshift(columns);
            } else {
                dataArrayGrid1.push([0, 0]);
                setNoDataForGrid(true);
            }

            setDataForGridByEmail(dataArrayGrid1);
        } else {
            setNoDataForGrid(true);
        }
    }, [getEmailReport]);

    useEffect(() => {
        const { payload } = ipRestriction || {};
        let totalA = 0;

        if (payload) {
            const rows = Object.keys(payload).map((key) => payload[key]);
            const dataArray = [];
            const dataArrayGrid = [];

            dataArray.push(["IP", "Recargas"]);

            if (rows?.length > 0) {
                rows?.forEach((item, index) => {
                    const rowsObject = Object.keys(item).map(
                        (key) => item[key]
                    );

                    rowsObject?.forEach((itm, indx) => {
                        const numberOfPrepaids = itm?.quantity || 0;
                        totalA = totalA + parseInt(numberOfPrepaids);

                        dataArray.push([itm?.ip || 0, itm?.quantity || 0]);
                        dataArrayGrid.push([itm?.ip || 0, itm?.quantity || 0]);
                    });
                });
            } else {
                dataArray.push([0, 0]);
                dataArrayGrid.push([0, 0]);
            }

            dataArrayGrid.push([
                <span style={{ fontWeight: "bold" }}>Total</span>,
                <span style={{ fontWeight: "bold" }}>
                    {Number(totalA).toLocaleString("en-US")}
                </span>,
            ]);
            setDataForGridIpRestriction(dataArrayGrid);

            if (payload.result.length === 0) {
                setNoDataForGrid(true);
            } else {
                setNoDataForGrid(false);
            }
        }
    }, [ipRestriction]);

    const ChartSection = ({ title, columns, rowsComponent }) => (
        <Grid item xs={12}>
            <h3>{title}</h3>
            <span className="line"></span>
            <Grid container mt={7}>
                <Grid item xs={12} md={12} mt={6}></Grid>
                <Grid item xs={12} md={12}>
                    <TableComponent
                        columns={columns}
                        rowsComponent={rowsComponent}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    const NoDataMessage = () => (
        <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            height={345}
        >
            <div className="kpi-empty">
                <p>
                    <BarChartIcon />{" "}
                </p>
                <p>No contiene datos, por favor intenta otra fecha </p>
            </div>
        </Grid>
    );

    return (
        <Grid container spacing={5}>
            {getContactPhone.loaded === true && !noDataForGrid ? (
                <ChartSection
                    title="Reporte por Teléfono"
                    columns={columns}
                    rowsComponent={<TableManagerContacPhone rows={rows} />}
                />
            ) : (
                noDataForGrid && <NoDataMessage />
            )}

            {getEmailReport.loaded === true && !noDataForGrid ? (
                <ChartSection
                    title="Reporte por Correo Electrónico"
                    columns={columnsByEmail}
                    rowsComponent={<TableManagerEmail rows={rowsEmail} />}
                />
            ) : (
                noDataForGrid && <NoDataMessage />
            )}

            {ipRestriction.loaded === true && !noDataForGrid ? (
                <ChartSection
                    title="Reporte por estricción de IP"
                    columns={columnsIpRestriction}
                    rowsComponent={
                        <TableTotalIpRestriction rows={rowsIpRestriction} />
                    }
                />
            ) : (
                noDataForGrid && <NoDataMessage />
            )}
        </Grid>
    );
};

export default PrepaidChart;
