import React from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { DataTableDeviceInfoHook } from "../hooks/dataTableDeviceInfoHook";

const DataTableDeviceInfo = (props) => {
    const {
        brandDevice,
        modelDevice,
        homologateDevice,
        subcategoryDevice,
        imeiDevice,
        esimDevice,
        statusDevice,
        band28Device,
        volteCapableDevice,
        supportedBearersDevice,
        radioStackDevice,
        deviceType,
        osDevice,
    } = DataTableDeviceInfoHook(props);
    return (
        <Grid container spacing={5} className="device-info-container m-b-15">
            <Grid item xs={6} className="m-t-20">
                <Grid container rowSpacing={2} spacing={2}>
                    <h3>Características del equipo</h3>
                    <Grid item xs={12}>
                        <Grid container className="device-info">
                            <Grid item xs={6}>
                                <p>Marca</p>
                                <span> {brandDevice} </span>
                            </Grid>
                            <Grid item xs={6} className="device-right-data">
                                <p>Modelo</p>
                                <span>{modelDevice}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>IMEI</p>
                            <span>{imeiDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>Estatus</p>
                            <span>{statusDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="device-info">
                            <p>Homologado</p>
                            <span>
                                {homologateDevice}
                                {" / "}
                                {subcategoryDevice}
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="device-info">
                            <p>Soporta Banda28</p>
                            <span>{band28Device}</span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="device-info">
                            <p>Soporta VoLTE</p>
                            <span>{volteCapableDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="device-info">
                            <p>Soporta eSIM</p>
                            <span>{esimDevice}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} className="m-t-20">
                <Grid container rowSpacing={2} spacing={2}>
                    <h3>Características soportadas</h3>
                    <Grid item xs={12}>
                        <div className="device-info">
                            <p>SupportedBearers</p>
                            <span>{supportedBearersDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            overflow="auto"
                            className="device-info device-overflow"
                        >
                            <p>RadioStack</p>
                            <span>{radioStackDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>DeviceType</p>
                            <span>{deviceType}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>OS</p>
                            <span>{osDevice}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    getActionImei: state.getActionImei,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDeviceInfo);
