import { useEffect } from "react";
import { setLoginDataHelper } from "_controllers/helpers/setLoginData";

export const useLoginEffects = (props) => {
    //Destructuring input props
    const { reduxProps, navProps, stateProps } = props || {};
    const { login } = reduxProps || {};
    const { history } = navProps || {};
    const { setVariant, setLoginResponse, setLoginResponseCls } =
        stateProps || {};

    //USEEFFECTS:

    //This useEffect is activated when the redux login response is received:
    //If the response is successful, the user is redirected to the dashboard.
    //If the response is unsuccessful, the user is redirected to the login page.
    useEffect(() => {
        if (typeof login?.login == "undefined") {
            return false;
        }

        const { token, expires_at, refresh_token, response } =
            login?.login || {};

        if (
            typeof token != "undefined" &&
            typeof expires_at != "undefined" &&
            typeof refresh_token != "undefined"
        ) {
            setLoginResponse("¡Bienvenido!");
            setLoginResponseCls({
                container: "success-container",
                text: "success-text",
            });
            setVariant("determinate");
            setLoginDataHelper(login?.login, "LoginContainer");
            history("/dashboard");
        } else if (typeof response != "undefined") {
            const { data } = response || {};
            const { detail } = data || {};
            setLoginResponse(detail || "");
            setLoginResponseCls({
                container: "error-container",
                text: "error-text",
            });
            setVariant("determinate");
            setLoginDataHelper(
                { token: null, expires_at: null, refresh_token: null },
                "LoginContainer"
            );
            history("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [login]);

    return {};
};
