import { useContext, useEffect } from "react";
import { DailySalesContext } from "../data/context";

export const useDailySalesManagerHook = () => {
    const dailySalesContext = useContext(DailySalesContext);

    const { redux, states } = dailySalesContext || {};
    const { getSalesDaily } = redux || {};
    const {
        headers,
        setHeaders,
        columns,
        setColumns,
        rows,
        setRows,
        data,
        setData,
        noDataForGrid,
        setNoDataForGrid,
    } = states || {};

    const generateColumns = () => {
        let columnsArray = [
            {
                field: "type_esim",
                headerName: "eSIM",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "type_sim",
                headerName: "SIM",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "total",
                headerName: "Total",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ];

        setColumns(columnsArray);
    };

    const generateRows = () => {
        const { payload, state } = getSalesDaily || {};
        const { result } = payload || {};

        let headersSet = new Set();
        let rowsSet = new Set();

        result?.forEach((element) => {
            const { date, product_name } = element || {};

            headersSet.add(date);
            rowsSet.add(product_name);
        });

        let headersArray = Array.from(headersSet);
        let rowsArray = Array.from(rowsSet);

        setHeaders(headersArray);
        setRows(rowsArray);

        let records = result?.reduce((obj, item) => {
            const { date, product_name, sim_type, quantity } = item || {};

            const hName = date;
            const rName = product_name?.replaceAll(" ", "_");

            const key = `${hName}=${rName}`;
            const existingRecord = obj[key];

            if (!existingRecord) {
                obj[key] = {
                    esim: sim_type === "E-SIM" ? quantity : 0,
                    sim: sim_type === "E-SIM" ? 0 : quantity,
                    total: quantity,
                    hName,
                    rName,
                };
            } else {
                existingRecord.esim += sim_type === "E-SIM" ? quantity : 0;
                existingRecord.sim += sim_type === "E-SIM" ? 0 : quantity;
                existingRecord.total += quantity;
            }

            return obj;
        }, {});

        setData(records);

        if (result?.length === 0 || state === "error") {
            setNoDataForGrid(true);
        } else {
            setNoDataForGrid(false);
        }
    };

    useEffect(() => {
        generateColumns();
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSalesDaily]);

    return { headers, columns, rows, data, noDataForGrid, getSalesDaily };
};
