import { UPDATE_APN, UPDATE_APN_ERROR } from "_models/redux/types";

const initialState = {
    postUpdateApnObj: [],
    loading: false,
};

export default function updateApnReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_APN:
            return {
                ...state,
                postUpdateApnObj: action.payload,
                loading: true,
            };

        case UPDATE_APN_ERROR:
            return {
                ...state,
                postUpdateApnObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
