import React from "react";
import { Grid, Fade, LinearProgress } from "@mui/material";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import moment from "moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { getUsers } from "_models/redux/get-user-list/action";
import { changeUser } from "_models/redux/change-user/action";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import { getEvents } from "_models/redux/events/action";
import { LineEventsHook } from "../hooks/lineEventsHook";

const LineEvents = (props) => {
    const { eventsList, loader } = LineEventsHook(props);

    return (
        <Grid container direction="row">
            <Grid item xs={12} className="ticket-detail-header">
                <h4> Eventos de la línea</h4>
            </Grid>
            <Grid
                item
                xs={12}
                className="events-box"
                textAlign={"center"}
                paddingLeft={2}
            >
                <Fade in={loader}>
                    <LinearProgress
                        sx={{
                            backgroundColor: "white",
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: "#673ab7",
                            },
                        }}
                    />
                </Fade>
                <List
                    sx={{
                        width: "100%",
                        minHeight: "300px",
                        bgcolor: "background.paper",
                        position: "relative",
                        "& ul": { padding: 0 },
                    }}
                    subheader={<li />}
                >
                    {!loader && eventsList.length > 0
                        ? eventsList.map((event, index) => {
                              const dateTime = moment
                                  .unix(event.created)
                                  .format("DD/MM/YYYY, h:mm:ss a");

                              return (
                                  <ListItem
                                      sx={{
                                          borderBottom: 1,
                                          borderColor: "divider",
                                          padding: 0,
                                      }}
                                      key={index}
                                  >
                                      <ListItemText
                                          sx={{
                                              padding: 2,
                                          }}
                                          primary={event.data.message}
                                          secondary={dateTime}
                                      />
                                  </ListItem>
                              );
                          })
                        : !loader && <p>No hay eventos</p>}
                </List>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    tickets: state.tickets,
    events: state.events,
    customerProfile: state.customerProfile,
    userList: state.userList,
    changeUser_: state.changeUser_,
    ticketById: state.ticketById,
});

const mapDispatchToProps = {
    getMyTickets,
    getEvents,
    getCustomerProfile,
    getUsers,
    changeUser,
    getTicketById,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvents);
