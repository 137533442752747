import React from "react";
import GenericListItemButton from "./MenuItemWithSubmenu";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";

const MarketingMenu = ({
    userPermissions,
    permission,
    handleClick,
    title,
    IconComponent,
    openMenu,
    activeStyle,
}) => {
    return userPermissions?.includes(permission) ? (
        <>
            <GenericListItemButton
                onClick={handleClick}
                className={"layout-menu-submenu"}
                title={title}
                IconComponent={IconComponent}
                openMenu={openMenu}
            />
            <GenericCollapse
                className={"layout-menu-submenu-options"}
                openMenu={openMenu}
                userPermissions={userPermissions}
            >
                <GenericNavListItem
                    userPermissions={userPermissions}
                    permission={permission}
                    to="kpis/marketing"
                    title="Canal/campañas"
                    IconComponent={IconComponent}
                    activeStyle={activeStyle}
                />
                <GenericNavListItem
                    userPermissions={userPermissions}
                    permission={permission}
                    to="/kpis/marketing/status-lead"
                    title="Status de lead"
                    IconComponent={IconComponent}
                    activeStyle={activeStyle}
                />
            </GenericCollapse>
        </>
    ) : null;
};

export default MarketingMenu;
