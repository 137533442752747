import axiosTokenInstance from "services/api/backoffice";
import {
    ORDERS_BY_PRODUCT,
    ORDERS_BY_PRODUCT_ERROR,
} from "_models/redux/types";

// Action function
export const getOrdersByProductAction = (params) => async (dispatch) => {
    const { from_time, to_time, channel_id, campaign_id, landing_id } =
        params || {};

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `api/reports/orders-by-product-and-sim-type?from_time=${from_time}&to_time=${to_time}&channel_id=${channel_id}&campaign_id=${campaign_id}&landing_id=${landing_id}`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: ORDERS_BY_PRODUCT,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: ORDERS_BY_PRODUCT_ERROR,
        });
    }
};
