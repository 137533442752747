import {
    GET_SUPPORT_GIFT_ORDERS_CLEAN,
    GET_SUPPORT_GIFT_ORDERS_CLEAN_ERROR,
} from "_models/redux/types";

export const getGiftOrdersCleanAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: null,
            type: GET_SUPPORT_GIFT_ORDERS_CLEAN,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: GET_SUPPORT_GIFT_ORDERS_CLEAN_ERROR,
        });
    }
};
