import { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";

export const ActionSendApnHook = (props) => {
    const { actionPostUpdateApn, ticketId } = props || {};
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const handleClickApn = () => {
        setModalOpen(true);
    };

    useEffect(() => {
        const { postUpdateApnObj } = actionPostUpdateApn || {};

        const { message } = postUpdateApnObj || {};

        if (message === "success") {
            setSeverityAlert("success");
            setErrorMssge("Se actualizó APN satisfactoriamente");
            setAlert(true);
        } else if (message === "Request failed with status code 409") {
            setErrorMssge("Error");
            setSeverityAlert("error");
            setAlert(true);
            setIsLoading(false);
        }
    }, [actionPostUpdateApn]);

    const handleSubmit = () => {
        setIsLoading(true);
        props.postUpdateApn(ticketId);
    };
    const clearFunction = () => {
        setAlert(false);
    };

    return {
        modalOpen,
        setModalOpen,
        isLoading,
        setIsLoading,
        alert,
        setAlert,
        errorMssge,
        setErrorMssge,
        severityAlert,
        setSeverityAlert,
        handleClickApn,
        handleSubmit,
        clearFunction,
    };
};
