import { useEffect, useState } from "react";

export const DataTableDeviceInfoHook = (props) => {
    const [brandDevice, setBrandDevice] = useState("NA");
    const [modelDevice, setModelDevice] = useState("NA");
    const [homologateDevice, setHomolagateDevice] = useState("NA");
    const [subcategoryDevice, setSubcategoryDevice] = useState("NA");
    const [imeiDevice, setImeiDevice] = useState("NA");
    const [esimDevice, setEsimDevice] = useState("");
    const [statusDevice, setStatusDevice] = useState("NA");
    const [band28Device, setBand28Device] = useState("NA");
    const [volteCapableDevice, setVolteCapableDevice] = useState("NA");
    const [supportedBearersDevice, setSupportedBearersDevice] = useState("NA");
    const [radioStackDevice, setRadioStackDevice] = useState("NA");
    const [deviceType, setDeviceType] = useState("NA");
    const [osDevice, setOsDevice] = useState("NA");

    useEffect(() => {
        const { imeiObj } = props.getActionImei || {};
        const imei = imeiObj.imei || {};
        const deviceFeatures = imeiObj.deviceFeatures || {};
        setBrandDevice(imeiObj.brand);
        setModelDevice(imeiObj.model);
        setHomolagateDevice(imei.homologated);
        setSubcategoryDevice(imei.sub_category);
        setImeiDevice(imei.imei);
        setEsimDevice(imei.soportaESIM);
        setStatusDevice(imei.status);
        setBand28Device(deviceFeatures.band28);
        setVolteCapableDevice(deviceFeatures.volteCapable);
        setSupportedBearersDevice(deviceFeatures.supportedBearers);
        setRadioStackDevice(deviceFeatures.radioStack);
        setDeviceType(deviceFeatures.deviceType);
        setOsDevice(deviceFeatures.os);
    }, [props.getActionImei]);

    return {
        brandDevice,
        modelDevice,
        homologateDevice,
        subcategoryDevice,
        imeiDevice,
        esimDevice,
        statusDevice,
        band28Device,
        volteCapableDevice,
        supportedBearersDevice,
        radioStackDevice,
        deviceType,
        osDevice,
    };
};
