import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ResetPasswordContext } from "./context";
import ResetPasswordComponent from "./component";
import { useResetPassword } from "./hook";
import { resetPasswordAction } from "_models/redux/reset-password/action";

const ResetPassword = (props) => {
    const history = useNavigate();

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [urlKey, setUrlKey] = useState("");
    const [urlToken, setUrlToken] = useState("");

    const [resetPasswordResponse, setResetPasswordResponse] = useState();
    const [resetPasswordResponseCls, setResetPasswordResponseCls] = useState({
        container: "",
        text: "",
    });

    const [variant, setVariant] = useState("determinate");
    const [progress, setProgress] = useState(0);

    const { resetPasswordAction, resetPassword } = props;

    const { sendMethod } = useResetPassword({
        resetPassword,
        resetPasswordAction,
    });

    const sendResetPassword = (data) => {
        setVariant("indeterminate");
        sendMethod(data, urlToken, urlKey);
    };

    const resetPasswordValues = {
        sendResetPassword,

        resetPasswordResponse,
        setResetPasswordResponse,
        resetPasswordResponseCls,
        setResetPasswordResponseCls,

        variant,
        setVariant,
        progress,
        setProgress,

        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    };

    useEffect(() => {
        const { pageParams } = props || { pageParams: undefined };
        const { token, key } = pageParams || {
            token: undefined,
            key: undefined,
        };

        setUrlKey(key);
        setUrlToken(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof resetPassword.resetPassword == "undefined") {
            return false;
        }
        // const { message } = resetPassword.resetPassword || {
        //     message: undefined,
        // };

        const { message, response } = resetPassword.resetPassword || {
            message: undefined,
            response: undefined,
            status: undefined,
        };

        const { data } = response || {
            data: undefined,
            statusText: undefined,
        };

        const { context } = data || {
            additional_details: undefined,
            context: undefined,
            detail: undefined,
            title: undefined,
            type: undefined,
        };

        if (message === "success") {
            setResetPasswordResponse("Contraseña reestablecida con éxito");
            setResetPasswordResponseCls({
                container: "success-container",
                text: "success-text",
            });
            setVariant("determinate");
            history("/reset-password-success-page");
        } else if (context === "json_schema_validation_error") {
            setResetPasswordResponse(
                "La contraseña debe contener por lo menos 8 dígitos"
            );
            setResetPasswordResponseCls({
                container: "error-container",
                text: "error-text",
            });
            setVariant("determinate");
            // history("/reset-password-error-page");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetPassword]);

    return (
        <ResetPasswordContext.Provider value={resetPasswordValues}>
            <ResetPasswordComponent />
        </ResetPasswordContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    resetPassword: state.resetPassword,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    resetPasswordAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
