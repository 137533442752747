import React from "react";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import { useTimePassed } from "utils/helpers/useTimePassed/useTimePassedHelper";

const IncidenceTimeCounter = (props) => {
    var now = moment().format("1691667117");
    const created = now;
    const timePassed = useTimePassed(created);

    return (
        <Grid container className="page-header ticket-detail">
            <Grid
                item
                xs={6}
                textAlign={"start"}
                display="flex"
                alignItems="center"
                paddingTop={"15px"}
            >
                <a href="/incidencias">
                    <ArrowBackIosIcon className={"icon"} />
                </a>
                {/* <h3>{titleTicket}</h3> */}
            </Grid>
            <Grid
                item
                xs={6}
                className="right"
                display="flex"
                justifyContent="flex-end"
                paddingTop={"15px"}
            >
                <div className="counter" style={{ textAlign: "end" }}>
                    <Button disabled variant="outlined" className="item">
                        Abierto hace: {timePassed}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default IncidenceTimeCounter;
