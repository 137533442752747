import axiosTokenInstance from "services/api/backoffice";
import { LINKS, LINKS_ERROR } from "_models/redux/types";

// Action function
export const getLinksAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/callcenter/leads/payment/open-purchase-links`
        );

        const { data, status } = response || {};

        dispatch({
            type: LINKS,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: LINKS_ERROR,
            payload: e || {},
        });
    }
};
