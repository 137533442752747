import { CLEAN_ATN_PORTABILITY, CLEAN_ATN_PORTABILITY_ERROR } from "_models/redux/types";

export const cleanAtnPortabilityAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: [],
            type: CLEAN_ATN_PORTABILITY,
        });
    } catch (e) {
        dispatch({
            payload: [],
            type: CLEAN_ATN_PORTABILITY_ERROR,
        });
    }
};
