export const setLoginDataHelper = (params = {}, from = "default") => {
    if (typeof params.token != "undefined" && params.token == null)
        return false;
    if (typeof params.expires_at != "undefined" && params.expires_at == null)
        return false;
    if (
        typeof params.refresh_token != "undefined" &&
        params.refresh_token == null
    )
        return false;
    if (typeof params.user_id != "undefined" && params.user_id == null)
        return false;

    localStorage.setItem("user_token", params.token);
    localStorage.setItem("user_expires_at", params.expires_at);
    localStorage.setItem("user_refresh_token", params.refresh_token);
    localStorage.setItem("user_id", params.user_id);
    localStorage.setItem("user_permissions", params.permissions);
    localStorage.setItem("user_message", "logged");
    localStorage.setItem("user_logged", true);

    return true;
};
