import React from "react";
import { Grid } from "@mui/material";
import ShippingSearchHeaderComponent from "./_sections/_header/_component";
import ShippingSearchDatagridComponent from "./_sections/_datagrid/_component";
import ShippingSearchFooterComponent from "./_sections/_footer/_component";

const ShippingSearchComponent = (props) => {
    return (
        <Grid container>
            <ShippingSearchHeaderComponent />
            <ShippingSearchDatagridComponent />
            <ShippingSearchFooterComponent />
        </Grid>
    );
};

export default ShippingSearchComponent;
