import React from "react";
import { Grid, TextField } from "@mui/material";

const PhoneInput = ({ register, registerOptions, phoneValue, errors }) => {
    return (
        <Grid container>
            <Grid item xs={12} className="body p-tb-15">
                <TextField
                    variant="standard"
                    fullWidth
                    type="number"
                    className="form-control"
                    placeholder="Número de celular anterior"
                    label="Número de celular anterior"
                    name="phoneInput"
                    value={phoneValue}
                    {...register("phoneInput", registerOptions?.phoneInput)}
                />
            </Grid>
            <Grid item xs={12}>
                <span>
                    {errors?.phoneInput && (
                        <p className="required-incidence m-0">
                            {" "}
                            {errors?.phoneInput.message}
                        </p>
                    )}
                </span>
            </Grid>
        </Grid>
    );
};

export default PhoneInput;
