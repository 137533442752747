import { useLoginForm } from "../hooks/useLoginForm";

// Login Form Components:
import LoginFormFields from "./login-form/LoginFormFields";
import LoginFormActions from "./login-form/LoginFormActions";
import LoginFormResponses from "./login-form/LoginFormResponses";

const LoginForm = () => {
    //Properties from hook:
    const hookProps = useLoginForm() || {};
    const {
        //Form Props:
        handleSubmit,
        //Methods:
        sendLogin,
    } = hookProps || {};

    //Form Layout (sections):
    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(sendLogin)}>
            <LoginFormFields hookProps={hookProps} />

            <LoginFormActions hookProps={hookProps} />

            <LoginFormResponses hookProps={hookProps} />
        </form>
    );
};

export default LoginForm;
