import React from "react";
import {
    SupervisorAccountOutlined as SupervisorAccountOutlinedIcon,
    CreditCardOutlined as CreditCardOutlinedIcon,
    CurrencyExchangeOutlined as CurrencyExchangeOutlinedIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";

const SuppliersMenu = ({
    userPermissions,
    paymentSuppliers,
    handleOpenSuppliers,
    activeStyle,
}) => {
    return (
        <>
            {userPermissions?.includes("LIST_PAYMENTS") && (
                <>
                    <GenericListItemButton
                        onClick={handleOpenSuppliers}
                        className={"layout-menu-submenu"}
                        title="Proveedores pago"
                        IconComponent={SupervisorAccountOutlinedIcon}
                        openMenu={paymentSuppliers}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={paymentSuppliers}
                        userPermissions={userPermissions}
                    >
                        {userPermissions?.includes("LIST_PAYMENTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="LIST_PAYMENTS"
                                to="transacciones/netpay"
                                title="NetPay"
                                IconComponent={CreditCardOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("LIST_PAYMENTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="LIST_PAYMENTS"
                                to="/transacciones/stp"
                                title="STP"
                                IconComponent={CurrencyExchangeOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("LIST_PAYMENTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="LIST_PAYMENTS"
                                to="/transacciones/toku"
                                title="Toku"
                                IconComponent={CurrencyExchangeOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default SuppliersMenu;
