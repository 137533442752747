export const getLoginDataHelper = () => {
    let userToken = localStorage.getItem("user_token");
    let userExpiresAt = localStorage.getItem("user_expires_at");
    let userRefreshToken = localStorage.getItem("user_refresh_token");
    let userId = localStorage.getItem("user_id");
    let userLogged = localStorage.getItem("user_logged");
    let userMessage = localStorage.getItem("user_message");
    let userPermissions = localStorage.getItem("user_permissions");

    let params = {
        token: userToken,
        expires_at: userExpiresAt,
        refresh_token: userRefreshToken,
        user_id: userId,
        userMessage,
        userLogged,
        userPermissions,
    };

    return params;
};
