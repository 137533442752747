import { Grid, Typography } from "@mui/material";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CopyrightIcon from "@mui/icons-material/Copyright";

//Component - Login form (child):
import LoginForm from "./LoginForm";
//Hook - Login content:
import { useLoginContent } from "../hooks/useLoginContent";

const LoginContent = () => {
    //Properties from hook:
    const {
        //Data:
        currentYear,
        //Image:
        miniLogo,
        //Styles:
        loginContent,
    } = useLoginContent() || {};

    //Styles from hook props:
    const {
        loginCard,
        loginCardHeader,
        loginCardContent,
        loginCardTitle,
        loginCardLogo,
        loginCardLogoImg,
        loginFooter,
        loginFooterIcon,
    } = loginContent || {};

    //Right Card Container, this wraps:
    // - Welcome text and logo image
    // - Login Form
    // - Login Footer
    return (
        <Card sx={loginCard || {}}>
            <CardContent sx={loginCardContent || {}}>
                <Grid container sx={loginCardHeader || {}}>
                    <Grid item xs={8} sx={loginCardTitle || {}}>
                        <Typography variant="h5" component="div">
                            Bienvenido
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={loginCardLogo || {}}>
                        <img
                            title="Mini Logo"
                            alt="Mini Logo"
                            src={miniLogo || ""}
                            style={loginCardLogoImg || {}}
                        />
                    </Grid>
                </Grid>

                <LoginForm />
            </CardContent>
            <CardActions>
                <p style={loginFooter || {}}>
                    <CopyrightIcon style={loginFooterIcon || {}} />
                    Dalefon {currentYear} Todos los derechos reservados
                </p>
            </CardActions>
        </Card>
    );
};

export default LoginContent;
