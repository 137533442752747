import React from "react";
import { TextField, Button, Grid } from "@mui/material";
import { useHelpdeskAddPopup } from "./_hook";

export const FormHelpDesk = () => {
    const {
        onSubmit,
        register,
        errors,
        handleSubmit,
        isSubmitting,
        CircularProgress,
        handleCancel,
    } = useHelpdeskAddPopup();

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px",
            }}
        >
            <Grid
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "16px",
                }}
            >
                <TextField
                    id="outlined-select-currency"
                    {...register("clientName", {
                        required: "Este campo es obligatorio",
                    })}
                    label="Nombre del cliente"
                    variant="outlined"
                />
                {errors.clientName && (
                    <p className="required-incidence">
                        {errors.clientName.message}
                    </p>
                )}
            </Grid>

            <Grid
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "16px",
                }}
            >
                <TextField
                    {...register("phoneNumber", {
                        required: "Este campo es obligatorio",
                    })}
                    label="Número telefónico"
                    variant="outlined"
                />
                {errors.phoneNumber && (
                    <p className="required-incidence">
                        {errors.phoneNumber.message}
                    </p>
                )}
            </Grid>

            <Grid
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "16px",
                }}
            >
                <TextField
                    {...register("issueTitle", {
                        required: "Este campo es obligatorio",
                    })}
                    label="Título de la incidencia"
                    variant="outlined"
                />
                {errors.issueTitle && (
                    <p className="required-incidence">
                        {errors.issueTitle.message}
                    </p>
                )}
            </Grid>

            <Grid
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "16px",
                }}
            >
                <TextField
                    {...register("description", {
                        required: "Este campo es obligatorio",
                    })}
                    label="Descripción"
                    multiline
                    rows={4}
                    variant="outlined"
                />
                {errors.description && (
                    <p className="required-incidence">
                        {errors.description.message}
                    </p>
                )}
            </Grid>

            <Grid
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "16px",
                    marginBottom: "16px",
                }}
            >
                <Button
                    variant="text"
                    onClick={handleCancel}
                    style={{
                        marginRight: "30px",
                        color: "#642BA5",
                    }}>
                    Cancelar
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                        position: "relative",
                        backgroundColor: "#642BA5",
                    }}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? <CircularProgress size={24} /> : "Guardar"}
                </Button>
            </Grid>
        </form>
    );
};
