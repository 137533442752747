import { useEffect } from "react";

export const useResetPassword = (props = {}) => {
    const { resetPasswordAction } = props;

    useEffect(() => {}, []);

    const sendMethod = (data = {}, token = "", key = "") => {
        const { new_password } = data || { new_password: undefined };

        resetPasswordAction({ new_password, token, key });
    };

    return { sendMethod };
};
