import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getLoginDataHelper } from "_controllers/helpers/getLoginData";
import ActivationsAndDeactivationsPage from "./dashboard";

// import LeadsByCampaingPage from "../../leadsByCampaing/page";
// import LeadsByCampaignChannelPage from "../../campaignChannel/page";

const ManagerKPIsPage = () => {
    const permission = "MANAGEMENT_REPORTS";
    // const permission = "MARKETING_REPORTS";

    const history = useNavigate();

    const { userPermissions } = getLoginDataHelper();

    useEffect(() => {
        if (
            typeof userPermissions
                ?.split(",")
                ?.find((element) => element === permission) === "undefined"
        ) {
            history("/");
        }

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return userPermissions
        ?.split(",")
        ?.find((element) => element === permission) ? (
        <div>
            <h4> Dashboard Lineas Dalefon </h4>
            <ActivationsAndDeactivationsPage />
        </div>
    ) : null;
};

export default ManagerKPIsPage;
