import {
    TICKET_BY_ID,
    TICKET_BY_ID_ERROR,
    CLEAN_TICKET_BY_ID,
    CLEAN_TICKET_BY_ID_ERROR,
} from "_models/redux/types";

const initialState = {
    response: [],
    error: undefined,
    loading: false,
};

export default function ticketByIdReducer(state = initialState, action) {
    switch (action.type) {
        case TICKET_BY_ID:
        case CLEAN_TICKET_BY_ID:
            return {
                ...state,
                response: action.payload,
                status: action?.status || null,
                loading: true,
            };

        case TICKET_BY_ID_ERROR:
        case CLEAN_TICKET_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
                status: action?.status || null,
                loading: false,
            };
        default:
            return state;
    }
}
