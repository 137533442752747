export const useLoginMethods = (props) => {
    //Destructuring input props
    const { reduxProps, formProps, stateProps } = props || {};
    const { loginAction } = reduxProps || {};
    const { getValues } = formProps || {};
    const { setVariant, setVisibilityValues, visibilityValues } =
        stateProps || {};

    //METHODS:
    //Call redux action to send login data:
    const sendLogin = (data) => {
        loginAction(data);
        setVariant("indeterminate");
    };

    //Show or hide password characters:
    const showPasswordClick = () => {
        setVisibilityValues({
            ...visibilityValues,
            showPassword: !visibilityValues.showPassword,
        });
    };

    //Show or hide pin code characters:
    const showCodeClick = () => {
        setVisibilityValues({
            ...visibilityValues,
            showCode: !visibilityValues.showCode,
        });
    };

    //Password input change event:
    const passwordOnChange = (e, field) => {
        const passwordValue = getValues(field);

        if (passwordValue === "") {
            setVisibilityValues({
                ...visibilityValues,
                showPassword: false,
            });
        }
    };

    //Pin code input change event:
    const codeOnChange = (e, field) => {
        const codeValue = getValues(field);

        if (codeValue === "") {
            setVisibilityValues({
                ...visibilityValues,
                showCode: false,
            });
        }
    };

    //Return methods:
    return {
        sendLogin,
        showPasswordClick,
        showCodeClick,
        passwordOnChange,
        codeOnChange,
    };
};
