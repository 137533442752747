import React, { useContext } from "react";

import CustomDialog from "widgets/component/CustomDialog";

import { HelpdeskContext } from "../../_context";
import { FormHelpDesk } from "../_add_popup/helpdesk-form";

const HelpdeskFooterComponent = (props) => {
    const helpdeskContext = useContext(HelpdeskContext);
    const { modalOpen, handleClose } = helpdeskContext;

    return (
        <>
            {modalOpen && (
                <CustomDialog
                    title="Incidencia"
                    onClose={handleClose}
                    open={modalOpen}
                    extraButtons={
                        [
                            // {
                            //     label: "Botón 1",
                            //     onClick: handleExtraButtonClick,
                            //     variant: "contained",
                            // },
                            // {
                            //     label: "cerrar",
                            //     onClick: handleClose,
                            //     variant: "text",
                            // },
                            // Agrega más botones si es necesario
                        ]
                    }
                    showCloseButton={false}
                    fullWidth={true}
                    maxWidth={"sm"}
                    content={<FormHelpDesk />}
                />
            )}
        </>
    );
};

export default HelpdeskFooterComponent;
