// Import: Middleware Action & Custom Types
import {
    SUPPLEMENTARY_SERVICE_CLEAN,
    SUPPLEMENTARY_SERVICE_CLEAN_ERROR,
} from "_models/redux/types";

// Action function
export const cleanSupplementaryService = (params) => async (dispatch) => {
    // Endpoint: Try & Catch

    try {
        dispatch({
            type: SUPPLEMENTARY_SERVICE_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: SUPPLEMENTARY_SERVICE_CLEAN_ERROR,
            payload: [],
        });
    }
};
