import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import { useNewAssistedLeadFormHook } from "./new-assisted-lead-form-hook";

const NewAssistedLeadFormComponent = (props) => {
    const { inherit } = props || {};

    const {
        register,
        onSubmit,

        handleSubmit,
        registerRequired,

        isLoading,
        postErrorResponse,
        postSuccessResponse,

        handleClose,
    } = useNewAssistedLeadFormHook(inherit);

    return (
        <>
            {postSuccessResponse ? (
                postSuccessResponse
            ) : (
                <Grid container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12}>
                            <p>Introduzca el número de orden</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    {...register(
                                        "orderNumber",
                                        registerRequired.orderNumber
                                    )}
                                    label="Número de orden"
                                ></TextField>
                            </Grid>

                            <Grid item xs={12} className="footer">
                                <button type="submit">Asignar</button>
                                <button
                                    id="cancelBtn"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    type="button"
                                >
                                    Cancelar
                                </button>
                            </Grid>

                            <Grid item xs={12}>
                                {isLoading ? <CircularProgress /> : null}
                            </Grid>

                            {postErrorResponse}
                        </Grid>
                    </form>
                </Grid>
            )}
        </>
    );
};

export default NewAssistedLeadFormComponent;
