import { connect } from "react-redux";

//Main Hook (Container):
import { useLoginContainer } from "./hooks/useLoginContainer";
//Redux Action:
import { loginAction } from "_models/redux/login/action";
//Context:
import { LoginContext } from "./data/loginContext";
//Component:
import LoginComponent from "./components/LoginComponent";

const LoginPage = (props) => {
    //Hook Instance:
    const loginValues = useLoginContainer(props);

    //Set Hook Values to Context:
    return (
        <LoginContext.Provider value={loginValues || {}}>
            <LoginComponent />
        </LoginContext.Provider>
    );
};

//REDUX:
//Redux map state and dispatch to props
const mapStateToProps = (state) => ({
    login: state.login,
});
const mapDispatchToProps = {
    loginAction,
};
//Export component connected to redux
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
