import axiosTokenInstance from "services/api/backoffice";
import {
    GET_SUPPORT_GIFT_ORDERS_DETAIL,
    GET_SUPPORT_GIFT_ORDERS_DETAIL_ERROR,
} from "_models/redux/types";

export const getGiftOrdersDetailAction = (params) => async (dispatch) => {
    const { order_id } = params || {};

    const endpointUrl = "/api/reports/esim-gifts-reports-details/" + order_id;

    try {
        const response = await axiosTokenInstance.get(`${endpointUrl}`);

        dispatch({
            method: "get",
            payload: params || null,
            response: response?.data || null,
            status: response?.status || null,
            object: response,
            type: GET_SUPPORT_GIFT_ORDERS_DETAIL,
        });
    } catch (e) {
        console.error(e);

        dispatch({
            method: "get",
            payload: params || null,
            response: e?.response || null,
            status: e?.response?.status || null,
            object: e,
            type: GET_SUPPORT_GIFT_ORDERS_DETAIL_ERROR,
        });
    }
};
