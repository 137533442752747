import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//Login Methods Hook:
import { useLoginMethods } from "./useLoginMethods";
//Login Effects Hook:
import { useLoginEffects } from "./useLoginEffects";

export const useLoginContainer = (props) => {
    //Instance navigaction property:
    const history = useNavigate();

    //Instance and destructuring useForm Library props:
    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    //STATE properties:
    //Login Response:
    const [loginResponse, setLoginResponse] = useState();
    const [loginResponseCls, setLoginResponseCls] = useState({
        container: "",
        text: "",
    });
    //Circular Progress Bar:
    const [variant, setVariant] = useState("determinate");
    const [progress, setProgress] = useState(0);
    //Visibility Password and Pin code Values:
    const [visibilityValues, setVisibilityValues] = useState({
        showPassword: false,
        showCode: false,
    });

    //Container properties:
    // This properties are passed to:
    // -useLoginEffects hook:
    // -LoginForm component (later modefied by the useLoginMethods hook):
    const containerProps = {
        reduxProps: props,
        navProps: {
            history,
        },
        formProps: {
            watch,
            reset,
            control,
            setValue,
            getValues,
            register,
            handleSubmit,
            setError,
            errors,
        },
        stateProps: {
            variant,
            setVariant,
            progress,
            setProgress,
            loginResponse,
            setLoginResponse,
            loginResponseCls,
            setLoginResponseCls,
            visibilityValues,
            setVisibilityValues,
        },
    };

    //Instance and destructuring login methods at once:
    //(I'mean, at the same time)
    containerProps.methods = useLoginMethods(containerProps) || {};

    //Instance login effects:
    //(This hook doesn't need to be destructured, because it does not return anything)
    useLoginEffects(containerProps);

    //Return container properties:
    //(Values embebed in the module main component context)
    return containerProps;
};
