import axiosTokenInstance from "../../../services/api/backoffice";

import { CHANGE_SIM, CHANGE_SIM_ERROR } from "_models/redux/types";

// Action function
export const postChangeSim = (params) => async (dispatch) => {
    const { ticket_id, icc_number } = params || {
        ticket_id: undefined,
        icc_number: undefined,
    };

    const paramsData = {
        icc_number: icc_number,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `/api/support/tickets/${ticket_id}/actions/sim-change`,
            paramsData
        );

        const { data } = response || {};

        dispatch({
            type: CHANGE_SIM,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: CHANGE_SIM_ERROR,
            payload: e || {},
        });
    }
};
