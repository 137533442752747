import { useEffect } from "react";

export const useForgotPassword = (props = {}) => {
    const { forgotPasswordAction } = props;

    useEffect(() => {}, []);

    const sendMethod = (data = {}) => {
        forgotPasswordAction(data);
    };

    return { sendMethod };
};
