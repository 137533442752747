import {
    LEADS,
    LEADS_ERROR,
    CLEAN_LEADS,
    CLEAN_LEADS_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function leadsReducer(state = initialState, action) {
    switch (action.type) {
        case LEADS:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case LEADS_ERROR:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };
        case CLEAN_LEADS:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success-clean",
            };

        case CLEAN_LEADS_ERROR:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error-clean",
            };
        default:
            return state;
    }
}
