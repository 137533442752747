import axiosTokenInstance from "services/api/backoffice";
import {
    SALE_ORDER_DETAIL,
    SALE_ORDER_DETAIL_ERROR,
} from "_models/redux/types";

export const getSaleOrderDetailAction = (params) => async (dispatch) => {
    const number_order = params || 0;

    const url = "/api/orders/";

    try {
        const response = await axiosTokenInstance.get(`${url}${number_order}`);

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || null,
            type: SALE_ORDER_DETAIL,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            status: e?.response?.status || null,
            type: SALE_ORDER_DETAIL_ERROR,
        });
    }
};
