// Import: Middleware Action & Custom Types
import {
    CLEAN_ACTIVATIONS_AND_DEACTIVATIONS,
    CLEAN_ACTIVATIONS_AND_DEACTIVATIONS_ERROR,
} from "_models/redux/types";

// Action function
export const cleanActivationsAndDeactivations =
    (params) => async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            dispatch({
                payload: undefined,
                type: CLEAN_ACTIVATIONS_AND_DEACTIVATIONS,
            });
        } catch (e) {
            dispatch({
                payload: undefined,
                type: CLEAN_ACTIVATIONS_AND_DEACTIVATIONS_ERROR,
            });
        }
    };
