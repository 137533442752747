import React from "react";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SecondaryInfoComponent = ({
    isLoading,
    carrier_name,
    seeLineStatus,
    activated_at,
    expanded,
    handleExpandClick,
}) => {
    return (
        <Grid container className="secondary">
            <Grid item xs={6}>
                {isLoading ? <CircularProgress /> : <h4> {carrier_name}</h4>}
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                <p className="status">{seeLineStatus()}</p>
            </Grid>
            <Grid item xs={6}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <p className="data">
                        {" Se activó el "}
                        {!isLoading && activated_at
                            ? moment(activated_at)
                                  .tz("America/Mexico_City")
                                  .format("DD/MM/YYYY HH:mm")
                            : "N/A"}
                    </p>
                )}
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </Grid>
        </Grid>
    );
};

export default SecondaryInfoComponent;
