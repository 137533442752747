import { Grid } from "@mui/material";
import GiftOrdersDetailData from "./GiftOrdersDetailData";
import GiftOrdersDetailClient from "./GiftOrdersDetailClient";

const GiftOrdersDetailContent = () => {
    return (
        <Grid container spacing={5} className="device-info-container m-b-15">
            <Grid item xs={6} direction={"column"}>
                <GiftOrdersDetailData />
            </Grid>
            <Grid item xs={6} direction={"column"}>
                <GiftOrdersDetailClient />
            </Grid>
        </Grid>
    );
};

export default GiftOrdersDetailContent;
