import axiosTokenInstance from "services/api/backoffice";
import {
    GET_IP_RESTRICTION_REPORT,
    GET_IP_RESTRICTION_REPORT_ERROR,
} from "_models/redux/types";

// Action function
export const getIpRestrictionReportAction = (params) => async (dispatch) => {
    const { from_time, to_time, min_ocurrences } = params || {};

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/reports/sim-purchase/ip-restriction?from_time=${from_time}&to_time=${to_time}&min_ocurrences=${min_ocurrences}`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: GET_IP_RESTRICTION_REPORT,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { status } = response || {};

        dispatch({
            method: "get",
            payload: e || {},
            status: status,
            type: GET_IP_RESTRICTION_REPORT_ERROR,
        });
    }
};
