import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, SvgIcon } from "@mui/material";

import miniLogo from "assets/images/mini-logo.png";
import resetPasswordSuccessImage from "assets/images/login/login-image.png";
import FormFooterComponent from "widgets/formFooter/component";

import { ReactComponent as resetPasswordSuccessIcon } from "assets/icons/login/success.svg";

const SuccessResetPasswordPage = () => {
    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToLogin = () => {
        history("/");
    };
    return (
        <Grid item container xs={12} className={"reset-password-page"}>
            <Grid
                item
                container
                xs={0}
                sm={0}
                md={4}
                lg={6}
                className={"reset-password-image-container"}
            >
                <img
                    title="Reset Password Success"
                    alt="Illustration of successful password reset"
                    src={resetPasswordSuccessImage}
                    className={"reset-password-image"}
                />
            </Grid>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={6}
                className={"reset-password-component-container"}
            >
                <Grid
                    item
                    container
                    xs={12}
                    className={"reset-password-component"}
                >
                    <Grid
                        item
                        container
                        xs={12}
                        className={"reset-password-form no-form"}
                    >
                        <Grid item container xs={12}>
                            <Grid item container xs={6} className={""}></Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                className={
                                    "reset-password-logo-container custom-logo"
                                }
                            >
                                <img
                                    title="Mini Logo"
                                    alt="Company mini logo"
                                    src={miniLogo}
                                    className={"reset-password-logo"}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            className={"reset-password-icon-container"}
                        >
                            <SvgIcon
                                className={"reset-password-icon success-icon"}
                                component={resetPasswordSuccessIcon}
                                viewBox="0 0 60 60"
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            className={"reset-password-title-container"}
                        >
                            <h3 className={"reset-password-title"}>
                                Contraseña reestablecida con éxito
                            </h3>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={"reset-password-button-container"}
                        >
                            <Button
                                variant="contained"
                                className={"reset-password-button"}
                                onClick={goToLogin}
                            >
                                Iniciar sesión
                            </Button>
                        </Grid>
                        <FormFooterComponent />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SuccessResetPasswordPage;
