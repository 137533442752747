import { useContext } from "react";

import { Grid } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";

import MonthlySalesReportContent from "./content-component";

import { MonthlySalesContext } from "../data/context";
import { useMonthlySalesReportHook } from "../hooks/hook";

const MonthlySalesReportComponent = (props) => {
    useMonthlySalesReportHook();
    const monthlySalesContext = useContext(MonthlySalesContext);
    const { redux, states } = monthlySalesContext || {};
    const { getSalesMonthly } = redux || {};
    const { noDataForGrid } = states;

    return (
        <Grid container spacing={5}>
            {getSalesMonthly.loaded === true && noDataForGrid === false ? (
                <MonthlySalesReportContent />
            ) : noDataForGrid ? (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    height={345}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>No contiene datos, por favor intenta otra fecha</p>
                    </div>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={363}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>Selecciona una fecha para búsqueda de datos</p>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default MonthlySalesReportComponent;
