import axiosTokenInstance from "services/api/backoffice";
import {
    CHANGE_ESIM_TO_SIM,
    CHANGE_ESIM_TO_SIM_ERROR,
} from "_models/redux/types";

export const changeEsimToSimAction = (params) => async (dispatch) => {
    const { reason, sim_order_id, shipping } = params || {};

    try {
        const response = await axiosTokenInstance.patch(
            `/api/orders/${sim_order_id}/change-esim-to-sim`,
            { reason, shipping }
        );

        const { data, status } = response || {};

        dispatch({
            method: "patch",
            payload: data || {},
            status: status || null,
            type: CHANGE_ESIM_TO_SIM,
        });
    } catch (e) {
        dispatch({
            method: "patch",
            payload: e || {},
            status: e?.response?.status || null,
            type: CHANGE_ESIM_TO_SIM_ERROR,
        });
    }
};
