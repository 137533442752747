import React from "react";
import { Grid, TextField } from "@mui/material";
import { ClientFormStyle } from "../style/PageTransferSaleStyle";

const ClientForm = (props) => {
    const {
        registerOptions,
        register,
        errors,
        inputChange,
        nameShrink,
        contactPhoneShrink,
        emailShrink,
    } = props;
    return (
        <Grid item xs={12}>
            <h1>Cliente</h1>

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Nombre completo"
                        placeholder="Nombre completo"
                        id="nameInput"
                        name="nameInput"
                        {...register("nameInput", registerOptions?.nameInput)}
                        onChange={(e) => inputChange(e)}
                        InputLabelProps={{
                            shrink: nameShrink,
                        }}
                        className="m-tb-20 form-control data-input"
                        sx={ClientFormStyle.textField}
                    />
                </Grid>

                <Grid item xs={12}>
                    <span>
                        {errors?.nameInput && (
                            <p> {errors?.nameInput.message}</p>
                        )}
                    </span>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Número de contacto"
                        placeholder="Número de contacto"
                        id="contactPhoneInput"
                        name="contactPhoneInput"
                        {...register(
                            "contactPhoneInput",
                            registerOptions?.contactPhoneInput
                        )}
                        onChange={(e) => inputChange(e)}
                        InputLabelProps={{
                            shrink: contactPhoneShrink,
                        }}
                        className="m-tb-20 form-control data-input"
                        sx={ClientFormStyle.textField}
                    />
                </Grid>

                <Grid item xs={12}>
                    <span>
                        {errors?.contactPhoneInput && (
                            <p className="required-incidence m-0">
                                {" "}
                                {errors?.contactPhoneInput.message}
                            </p>
                        )}
                    </span>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Correo electrónico (opcional)"
                        placeholder="Correo electrónico  (opcional) "
                        id="emailInput"
                        name="emailInput"
                        {...register("emailInput")}
                        onChange={(e) => inputChange(e)}
                        InputLabelProps={{
                            shrink: emailShrink,
                        }}
                        className="m-tb-20 form-control data-input"
                        sx={ClientFormStyle.textField}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ClientForm;
