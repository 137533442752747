import { Grid } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useGiftOrdersHeaders } from "modules/support-gift-orders/hooks/gift-orders-header/useGiftOrdersHeader";

const GiftOrdersHeaderDatePickers = () => {
    // Hook propio de la funcionalidad del encabezado (filtros)
    const { datePickerOnChange, fromDateValue, toDateValue } =
        useGiftOrdersHeaders();

    return (
        <>
            <Grid item xs={6} lg={2} className={"filter-container"}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"es"}
                >
                    <DatePicker
                        value={fromDateValue}
                        onChange={(newValue) =>
                            datePickerOnChange(newValue, "from")
                        }
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6} lg={2} className={"filter-container"}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"es"}
                >
                    <DatePicker
                        value={toDateValue}
                        onChange={(newValue) =>
                            datePickerOnChange(newValue, "to")
                        }
                    />
                </LocalizationProvider>
            </Grid>
        </>
    );
};

export default GiftOrdersHeaderDatePickers;
