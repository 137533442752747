// Estilos MUI del datagrid
export const datagridStyles = {
    "& .MuiDataGrid-columnHeaders": {
        position: "sticky",
        zIndex: "999",
        background: "white",
    },
    "& .MuiDataGrid-virtualScroller": {
        marginTop: "0!important",
    },

    "& .MuiDataGrid-main": {
        overflow: "hidden",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        width: "0.4em",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
        background: "#f1f1f1",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
        backgroundColor: "#E7DEE7",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
        background: "#DFD4DF",
    },
};
