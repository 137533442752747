//Hook - Layout app bar:
import { useLayoutMenu } from "../hooks/useLayoutMenu";
// import DrawerHeaderComponent from "./drawerHeaderComponent";
// import MenuListComponent from "./menuList";

import LayoutMenuHeader from "./menu/LayoutMenuHeader";
import LayoutMenuList from "./menu/LayoutMenuList";

const LayoutMenu = () => {
    //Properties from hook:
    const { Drawer, open } = useLayoutMenu() || {};

    return (
        <Drawer variant="permanent" anchor="left" open={open} className={"layout-menu"}>
            <LayoutMenuHeader />
            {/* <Divider /> */}
            <LayoutMenuList />
        </Drawer>
    );
};

export default LayoutMenu;
