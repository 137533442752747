import { TextField, Button, Grid } from "@mui/material";

export const TransferIdSection = ({
    transferId,
    onTransferIdChange,
    onSendTransferId,
    user_permissions,
    transaction_status_id,
}) => {
    // Divide la cadena de permisos en un array
    const permissionsArray = user_permissions.split(",");

    // Verifica si el usuario tiene la capacidad de supervisor de leads del centro de llamadas
    const hasSupervisorCapability = permissionsArray.includes(
        "CALLCENTER_LEADS_SUPERVISOR"
    );

    // Verifica si el botón debe estar habilitado
    const isButtonEnabled =
        transaction_status_id === 1 && hasSupervisorCapability;

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <TextField
                    label="ID de Transferencia Bancaria"
                    value={transferId}
                    onChange={onTransferIdChange}
                    fullWidth
                />
            </Grid>
            <Grid marginTop={2}>
                {/* Habilita o deshabilita el botón según la condición */}
                <Button
                    variant="contained"
                    onClick={onSendTransferId}
                    disabled={!isButtonEnabled}
                >
                    Enviar ID de Transferencia
                </Button>
            </Grid>
        </Grid>
    );
};
