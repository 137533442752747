import React from "react";
import { connect } from "react-redux";
import { Grid, Container, Button } from "@mui/material";
import "moment/locale/es";

import DailySalesManagerComponent from "./component";
import LeadsByCampaingFilters from "modules/dashoboard/filters/filters";

import { DailySalesContext } from "../data/context";
import { useDailySalesManagerContainerHook } from "../hooks/container-hook";

import { getSalesDailyAction } from "_models/redux/get-sales-daily/action";
import { cleanGetSalesDailyAction } from "_models/redux/get-sales-daily/clean-action";

const DailySalesManagerContainer = (props) => {
    const { parentProperties } = useDailySalesManagerContainerHook(props);
    const { methods } = parentProperties || {};
    const { loadData } = methods || {};

    return (
        <DailySalesContext.Provider value={parentProperties}>
            <Container className="kpi-container">
                <Grid container>
                    <Grid item xs={12}>
                        <LeadsByCampaingFilters
                            parentProperties={parentProperties}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className="filters-button-container">
                            <Button
                                type="button"
                                variant="contained"
                                onClick={loadData}
                            >
                                Cargar
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <DailySalesManagerComponent
                            parentProperties={parentProperties}
                        />
                    </Grid>
                </Grid>
            </Container>
        </DailySalesContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    getSalesDaily: state.getSalesDaily,
});

const mapDispatchToProps = {
    getSalesDailyAction,
    cleanGetSalesDailyAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DailySalesManagerContainer);
