import { Grid } from "@mui/material";

import WeeklySalesReportChart from "./chart-component";
import WeeklySalesReportTable from "./table-component";

const WeeklySalesReportContent = (props) => {
    return (
        <Grid item xs={12}>
            <Grid container mt={7}>
                <Grid xs={12} md={12}>
                    <WeeklySalesReportChart props={props} />
                </Grid>
                <Grid xs={12} md={12}>
                    <WeeklySalesReportTable props={props} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default WeeklySalesReportContent;
