import axiosTokenInstance from "services/api/backoffice";
import {
    SALE_ORDER_ESIMQR,
    SALE_ORDER_ESIMQR_ERROR,
} from "_models/redux/types";

// Action function
export const getEsimQr = (params) => async (dispatch) => {
    const url = `/api/orders/${params}/esim-qr`;

    try {
        const response = await axiosTokenInstance.get(`${url}`);

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: SALE_ORDER_ESIMQR,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: SALE_ORDER_ESIMQR_ERROR,
        });
    }
};
