import React from "react";
import { LinearProgress, Fade } from "@mui/material";

const LinearProgressContainer = ({ loader }) => {
    return (
        <Fade in={loader}>
            <LinearProgress
                sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: "#673ab7",
                    },
                }}
            />
        </Fade>
    );
};

export default LinearProgressContainer;
