import React from "react";
import { Grid } from "@mui/material";

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 10,
        width: "100%",
        backgroundColor: "#EFEFEF",
        borderRadius: 50,
        marginBottom: 20,
        marginTop: 10,
    };

    const fillerStyles = {
        height: "100%",
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: "inherit",
    };

    return (
        <Grid style={containerStyles}>
            <Grid style={fillerStyles}></Grid>
        </Grid>
    );
};

export default ProgressBar;
