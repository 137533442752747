// Import: Middleware Action & Custom Types
import {
    CLEAN_DETAILS_PREPAID_CHARGEBACK,
    CLEAN_DETAILS_PREPAID_CHARGEBACK_ERROR,
} from "_models/redux/types";

// Action function
export const cleanReportPrepaidChargebackDetails =
    (params) => async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            dispatch({
                type: CLEAN_DETAILS_PREPAID_CHARGEBACK,
                payload: undefined,
            });
        } catch (e) {
            dispatch({
                type: CLEAN_DETAILS_PREPAID_CHARGEBACK_ERROR,
                payload: undefined,
            });
        }
    };
