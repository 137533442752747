import {
    CUSTOMER_PROFILE,
    CUSTOMER_PROFILE_ERROR,
    CUSTOMER_PROFILE_CLEAN,
    CUSTOMER_PROFILE_ERROR_CLEAN,
} from "_models/redux/types";

const initialState = {
    customerProfile: undefined,
    loading: false,
    customerProfileStatus: undefined,
};

export default function customerProfileReducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOMER_PROFILE:
            return {
                ...state,
                customerProfile: action.payload,
                customerProfileStatus: action.status,
                loading: true,
            };

        case CUSTOMER_PROFILE_ERROR:
            return {
                ...state,
                customerProfile: action.payload,
                customerProfileStatus: action.status,
                loading: false,
            };

        case CUSTOMER_PROFILE_CLEAN:
        case CUSTOMER_PROFILE_ERROR_CLEAN:
            return {
                ...state,
                customerProfile: action.payload,
                customerProfileStatus: action.status,
                loading: false,
            };

        default:
            return state;
    }
}
