import axiosTokenInstance from "services/api/backoffice";
import {
    CREATE_LEAD_BY_CHANNEL,
    CREATE_LEAD_BY_CHANNEL_ERROR,
} from "_models/redux/types";

// Action function
export const ChannelLeadAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/callcenter/channels`
        );

        const { data, status } = response || {};

        dispatch({
            type: CREATE_LEAD_BY_CHANNEL,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: CREATE_LEAD_BY_CHANNEL_ERROR,
            payload: e || {},
        });
    }
};
