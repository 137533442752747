import axiosTokenInstance from "../../../services/api/backoffice";
import {
    LIST_COMMENT_TICKETS,
    LIST_COMMENT_TICKETS_ERROR,
} from "_models/redux/types";

// Action function
export const listcommentsTikets = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/${params}/comments`
        );

        const { data } = response || {};

        dispatch({
            type: LIST_COMMENT_TICKETS,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: LIST_COMMENT_TICKETS_ERROR,
            payload: e || {},
        });
    }
};
