// Import: Middleware Action & Custom Types
import { CLEAN_MY_SALES, CLEAN_MY_SALES_ERROR } from "_models/redux/types";

// Action function
export const cleanMySalesAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_MY_SALES,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_MY_SALES_ERROR,
            payload: undefined,
        });
    }
};
