import { LOGIN, LOGIN_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const loginAction = (params) => async (dispatch) => {
    const { user_email, user_password, user_verification_code } = params || {
        user_email: undefined,
        user_password: undefined,
        user_verification_code: undefined,
    };

    const paramsData = {
        email: user_email,
        password: user_password,
        pin: user_verification_code,
    };

    try {
        const response = await axiosAuthInstance.post(`/api/login`, paramsData);

        const { data, status } = response;

        dispatch({
            type: LOGIN,
            payload: data,
            status: status,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: LOGIN_ERROR,
            payload: e || {},
        });
    }
};
