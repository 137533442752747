// MenuItemWithSubmenu.jsx
import React from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const GenericListItemButton = ({
    onClick,
    className,
    title,
    IconComponent,
    openMenu,
}) => (
    <ListItemButton onClick={onClick} className={className} title={title}>
        <ListItemIcon>
            <IconComponent />
        </ListItemIcon>
        <ListItemText primary={title} />
        {openMenu ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
);

export default GenericListItemButton;
