import React from "react";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";

const RowButtons = ({ typeCallForwarding, handleChangeCallForwarding }) => {
    return (
        <FormControl variant="standard">
            <FormLabel id="demo-row-radio-buttons-group-label">
                <h4> Desviar la llamada a:</h4>
            </FormLabel>
            <RadioGroup
                defaultValue="none"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={typeCallForwarding}
                onChange={handleChangeCallForwarding}
            >
                <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="Ninguno"
                />
                <FormControlLabel
                    value="voice-mail"
                    control={<Radio />}
                    label="Correo de voz"
                />
                <FormControlLabel
                    value="call-forwarding"
                    control={<Radio />}
                    label="Re dirección de llamadas"
                />
                <FormControlLabel
                    value="unconditional-call-forwarding"
                    control={<Radio />}
                    label="Incondicional"
                />
            </RadioGroup>
        </FormControl>
    );
};

export default RowButtons;
