import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import FiltroNetPay from "./list-transactions-netpay/components/FiltroNetPay";
import { netPayAction } from "_models/redux/list-transactions-netpay/action";
import { cleanNetPayAction } from "_models/redux/list-transactions-netpay/clean-action";
import {
    Box,
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    DialogActions,
} from "@mui/material";
import { PageNetPayHook } from "./list-transactions-netpay/hooks/PageNetPayHook";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressStyle } from "./list-transactions-netpay/style/style";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import { detailNetPayAction } from "_models/redux/detail-transactions-netpay/action";
import { cleanDetailNetPayAction } from "_models/redux/detail-transactions-netpay/clean-action";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/componentDialog";
import { actionReportTransactionsFraud } from "_models/redux/report-transaction-fraud/action";
import { cleanReportTransactionsFraud } from "_models/redux/report-transaction-fraud/clean_action";
import { cleanReportPrepaidChargebackDetails } from "_models/redux/report-transaction-chargeback/clean_action";
import { actionReportTransactionsChargeback } from "_models/redux/report-transaction-chargeback/action";

const PageNetPay = ({
    netPayAction,
    netPay,
    cleanNetPayAction,
    cleanDetailNetPayAction,
    detailNetPayAction,
    detailTransactionsNetpay,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    reportTransactionsFraud,
    reportTransactionsChargeback,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
    transactionId,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        openActionFraud,
        setDescriptionTransaction,
        setError,
        error,
        handleConfirm,
        loadingFraudAndChargeback,
        isError,
        handleCloseFraudChargeback,
        setOpenActionFraud,
        handleOpenPopUp,
        setIdReverso,
        setActiveAction,
        setShouldReturnData,
        activeAction,
        shouldReturnData,
    } = PageNetPayHook({
        netPayAction,
        cleanNetPayAction,
        netPay,
        cleanDetailNetPayAction,
        detailNetPayAction,
        detailTransactionsNetpay,
        actionReportTransactionsFraud,
        cleanReportTransactionsFraud,
        reportTransactionsFraud,
        reportTransactionsChargeback,
        cleanReportPrepaidChargebackDetails,
        actionReportTransactionsChargeback,
        transactionId,
        onClose,
    });

    return (
        <Box>
            <Grid>
                <h4> Transacciones de NetPay </h4>
            </Grid>
            <Box sx={{ mb: 10 }}>
                <FiltroNetPay
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Box>

            <>
                {loading ? (
                    <CircularProgress
                        sx={CircularProgressStyle.CircularProgress}
                    />
                ) : (
                    <>
                        {mappedRows?.length > 0 ? (
                            <DataTable
                                rows={mappedRows}
                                columns={Columns}
                                onRowClick={handleRowClick}
                                page={page}
                                pageSize={size}
                                rowCount={pagination?.total_rows ?? 0}
                                onPageChange={pagination?.handleChangePage}
                                handleChangePageSize={
                                    pagination?.handleChangePageSize
                                }
                            />
                        ) : (
                            <Typography variant="h6">
                                No hay registros disponibles
                            </Typography>
                        )}
                    </>
                )}
            </>

            <CustomDialog
                fullWidth
                title={
                    detailTransactionsNetpay?.response?.payment_info === null
                        ? "No hay datos"
                        : "Transacción netpay"
                }
                content={
                    loading ? (
                        <CircularProgress
                            sx={CircularProgressStyle.CircularProgress}
                        />
                    ) : (
                        <DataTableDetailOrder
                            type="detailsNetPay"
                            handleOpenPopUp={handleOpenPopUp}
                            setOpenActionFraud={setOpenActionFraud}
                            response={detailTransactionsNetpay?.response}
                            idTransaction={
                                detailTransactionsNetpay?.response
                                    ?.transaction_type?.transaction_id
                            }
                            onClose={handleClose}
                            setIdReverso={setIdReverso}
                            setActiveAction={setActiveAction}
                            setShouldReturnData={setShouldReturnData}
                            activeAction={activeAction}
                            shouldReturnData={shouldReturnData}
                        />
                    )
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
            <Dialog open={openActionFraud} onClose={handleCloseFraudChargeback}>
                <DialogTitle>
                    {"¿Estás seguro de que quieres ejecutar esta acción?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            "Una vez que ejecutes esta acción, no podrás deshacerla."
                        }
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Descripción"
                        type="text"
                        fullWidth
                        onChange={(event) => {
                            setDescriptionTransaction(event.target.value);
                            setError(event.target.value.length < 5);
                        }}
                        error={error}
                        helperText={
                            error
                                ? "Este campo requiere al menos 5 caracteres"
                                : ""
                        }
                    />
                    {loadingFraudAndChargeback && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    {dialogMessage && (
                        <DialogContentText
                            style={{
                                color: isError ? "red" : "green",
                            }}
                        >
                            {dialogMessage}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseFraudChargeback}
                        color="primary"
                    >
                        Cerrar
                    </Button>

                    {reportTransactionsFraud?.status !== 200 &&
                        reportTransactionsChargeback?.status !== 200 && (
                            <Button
                                onClick={handleConfirm}
                                color="primary"
                                autoFocus
                                disabled={error}
                            >
                                Aceptar
                            </Button>
                        )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    netPay: state?.netPay,
    detailTransactionsNetpay: state?.detailTransactionsNetpay,
    reportTransactionsFraud: state?.reportTransactionsFraud,
    reportTransactionsChargeback: state?.reportTransactionsChargeback,
});

const mapDispatchToProps = {
    cleanNetPayAction,
    netPayAction,
    cleanDetailNetPayAction,
    detailNetPayAction,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNetPay);
