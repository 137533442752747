import React from "react";
import { Grid } from "@mui/material";
import ShippingHeaderComponent from "./_sections/_header/_component";
import ShippingPendingFooterComponent from "./_sections/_footer/_component";
import ShippingPendingDatagridComponent from "./_sections/_datagrid/_component";

const ShippingPendingComponent = (props) => {
    return (
        <Grid container>
            <ShippingHeaderComponent />
            <ShippingPendingDatagridComponent />
            <ShippingPendingFooterComponent />
        </Grid>
    );
};

export default ShippingPendingComponent;
