import React from "react";
import { Collapse, Grid } from "@mui/material";
import ProgressBar from "../components/progress-bar";

const OfferDetails = ({
    expanded,
    offerDetail,
    offer,
    typeService,
    getSpeedValue,
    startDate,
    endDate,
    remainderDays,
    testData,
    colorsList,
    index,
    PaddedGreyPaper,
    key,
}) => {
    return (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid item xs={12} key={key}>
                <PaddedGreyPaper
                    sx={{
                        boxShadow: 0,
                        background: "#fbf9ff",
                        borderRadius: 5,
                        paddingLeft: 2,
                        paddingRight: 2,
                    }}
                >
                    <Grid container>
                        <Grid item xs={6} textAlign={"start"} className="info">
                            <h3>OFFERID</h3>
                            <h4>{Math.round(offerDetail.offeringId)}</h4>
                        </Grid>
                        <Grid item xs={6} textAlign={"end"} className="info">
                            <h3>VELOCIDAD</h3>
                            <h4>{getSpeedValue(offer.name)}</h4>
                        </Grid>
                        <Grid item xs={6} textAlign={"start"} className="info">
                            <h3>CONSUMIDOS</h3>
                            <h4>
                                {Math.round(
                                    offerDetail.initialAmt -
                                        offerDetail.unusedAmt
                                )}{" "}
                                {typeService}
                            </h4>
                        </Grid>
                        <Grid item xs={6} textAlign={"end"} className="info">
                            <h3>TOTALES</h3>
                            <h4>
                                {offerDetail.initialAmt} {typeService}
                            </h4>
                        </Grid>
                        <Grid item xs={6} className="info">
                            <h3>INICIO</h3>
                            <h4>{startDate}</h4>
                        </Grid>
                        <Grid item xs={6} className="info" textAlign={"end"}>
                            <h3>FIN</h3>
                            <h4>{endDate}</h4>
                        </Grid>

                        <Grid container className="info bar">
                            <Grid item xs={12} textAlign={"end"}>
                                <h3 className="m-t-15">
                                    {" "}
                                    Quedan {remainderDays} días
                                </h3>
                            </Grid>
                            <Grid item xs={12} className="bar">
                                {testData.map((item, idx) => (
                                    <ProgressBar
                                        key={idx}
                                        bgcolor={
                                            colorsList[
                                                index % colorsList.length
                                            ]
                                        }
                                        completed={item.completed}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </PaddedGreyPaper>
            </Grid>
        </Collapse>
    );
};

export default OfferDetails;
