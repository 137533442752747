import React from "react";
import { connect } from "react-redux";
import { Grid, Container, Button } from "@mui/material";
import "moment/locale/es";

import WeeklySalesReportComponent from "./component";
import LeadsByCampaingFilters from "modules/dashoboard/filters/filters";

import { WeeklySalesContext } from "../data/context";
import { useWeeklySalesReportContainerHook } from "../hooks/container-hook";

import { getSalesWeeklyAction } from "_models/redux/get-sales-weekly/action";
import { cleanGetSalesWeeklyAction } from "_models/redux/get-sales-weekly/clean-action";

const WeeklySalesReportContainer = (props) => {
    const { parentProperties } = useWeeklySalesReportContainerHook(props);
    const { methods } = parentProperties || {};
    const { loadData } = methods || {};

    return (
        <WeeklySalesContext.Provider value={parentProperties}>
            <Container className="kpi-container">
                <Grid container>
                    <Grid item xs={12}>
                        <LeadsByCampaingFilters
                            parentProperties={parentProperties}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className="filters-button-container">
                            <Button
                                type="button"
                                variant="contained"
                                onClick={loadData}
                            >
                                Cargar
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <WeeklySalesReportComponent
                            parentProperties={parentProperties}
                        />
                    </Grid>
                </Grid>
            </Container>
        </WeeklySalesContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    getSalesWeekly: state.getSalesWeekly,
});

const mapDispatchToProps = {
    getSalesWeeklyAction,
    cleanGetSalesWeeklyAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WeeklySalesReportContainer);
