import React, { useState } from "react";
import { Chart } from "react-google-charts";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ColumnStackedChart = ({ data }) => {
    const [logScale, setLogScale] = useState(false);

    const handleCheckboxChange = () => {
        setLogScale(!logScale);
    };

    // Obtener todas las categorías únicas
    const categories = Array.from(
        new Set(
            Object.values(data).flatMap((subcategories) =>
                Object.keys(subcategories)
            )
        )
    );

    // Transformar los datos para que sean compatibles con react-google-charts
    const chartData = [
        [
            "Source",
            ...categories.flatMap((category) => [
                `${category} Quantity`,
                `${category} Recovered`,
            ]),
        ],
        ...Object.entries(data).map(([source, subcategories]) => {
            const sourceData = [source];
            categories.forEach((category) => {
                sourceData.push(
                    subcategories[category]?.quantity || 0,
                    subcategories[category]?.recovered || 0
                );
            });
            return sourceData;
        }),
    ];

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
                <Typography variant="subtitle1">Escala logaritmica:</Typography>
                <Checkbox
                    checked={logScale}
                    onChange={handleCheckboxChange}
                    label="Enable Log Scale"
                />
            </Box>
            <Box mb={2} width={"100%"}>
                {/* Agregar margen inferior a la gráfica */}
                <Chart
                    width={"100%"}
                    height={"400px"}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart...</div>}
                    data={chartData}
                    options={{
                        isStacked: true,
                        legend: { position: "top", maxLines: 3 },
                        vAxis: {
                            minValue: 0,
                            scaleType: logScale ? "log" : undefined,
                        },
                        series: {
                            0: { targetAxisIndex: 0 },
                            1: { targetAxisIndex: 1 },
                        },
                        axes: {
                            y: {
                                0: { label: "Quantity" },
                                1: { label: "Recovered" },
                            },
                        },
                    }}
                />
            </Box>
            <Grid width="100%">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="subtitle1">
                            Detalles de la tabla
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Source</TableCell>
                                        {categories.map((category) => (
                                            <React.Fragment key={category}>
                                                <TableCell>
                                                    {category} Quantity
                                                </TableCell>
                                                <TableCell>
                                                    {category} Recovered
                                                </TableCell>
                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(data).map(
                                        ([source, subcategories]) => (
                                            <TableRow key={source}>
                                                <TableCell>{source}</TableCell>
                                                {categories.map((category) => (
                                                    <React.Fragment
                                                        key={category}
                                                    >
                                                        <TableCell>
                                                            {subcategories[
                                                                category
                                                            ]?.quantity || 0}
                                                        </TableCell>
                                                        <TableCell>
                                                            {subcategories[
                                                                category
                                                            ]?.recovered || 0}
                                                        </TableCell>
                                                    </React.Fragment>
                                                ))}
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Box>
    );
};

export default ColumnStackedChart;
