import axiosTokenInstance from "services/api/backoffice";
import {
    DETAIL_TRANSFER_SALES,
    DETAIL_TRANSFER_SALES_ERROR,
} from "_models/redux/types";

// Action function
export const detailTransferSalesAction = (transfer_id) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/callcenter/sims/orders/purchase-by-transfer/${transfer_id}`
        );

        const { data, status } = response || {};

        dispatch({
            type: DETAIL_TRANSFER_SALES,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: DETAIL_TRANSFER_SALES_ERROR,
            payload: e || {},
        });
    }
};
