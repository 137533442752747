import GiftOrdersHeader from "./gift-orders-header/GiftOrdersHeader";
import GiftOrdersList from "./gift-orders-list/GiftOrdersList";
import GiftOrdersDetail from "./gift-orders-detail/GiftOrdersDetail";
import GiftOrdersError from "./gift-orders-error/GiftOrdersError";

const GiftOrdersContent = () => {
    return (
        <>
            <GiftOrdersHeader />
            <GiftOrdersList />
            <GiftOrdersDetail />
            <GiftOrdersError />
        </>
    );
};

export default GiftOrdersContent;
