import { Grid } from "@mui/material";
import { useGiftOrdersDetail } from "modules/support-gift-orders/hooks/gift-orders-detail/useGiftOrdersDetail";

const GiftOrdersDetailData = () => {
    // Hook propio de la funcionalidad del detalle,
    // destrucuturando el objeto que necesita la interfaz
    const { order_details } = useGiftOrdersDetail();

    return (
        <>
            <h2>Orden de compra</h2>

            <Grid container className="device-info">
                <Grid item xs={12}>
                    <div>
                        <span>Número Dalefon: </span>{" "}
                        {order_details?.direct_number}
                    </div>
                    <div>
                        <span>Número de orden: </span>{" "}
                        {order_details?.order_number}
                    </div>
                    <div>
                        <span>ID:</span> {order_details?.id}{" "}
                    </div>
                    <hr></hr>
                    <div>
                        <p>Se compró el {order_details?.purchase_created} </p>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default GiftOrdersDetailData;
