import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { closeLoggedSession } from "_controllers/helpers/closeLoggedSession";

const ClosePage = () => {
    useEffect(() => {
        closeLoggedSession();
    }, []);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="75vh"
        >
            <CircularProgress />
        </Box>
    );
};

export default ClosePage;
