import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import dayjs from "dayjs";
import { Grid, Container, TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const FiltersPage = (props) => {
    const history = useNavigate();

    const { register } = useForm({
        defaultValues: {},
    });

    const [options, setOptions] = useState([]);

    const [dropdownValue, setDropdownValue] = useState(0);

    const [startValue, setStartValue] = useState(null);
    const [endValue, setEndValue] = useState(null);

    useEffect(() => {
        populateDropdown();

        return () => {};
    }, []);

    const onClickHandler = (e = undefined, link = "/samples") => {
        history(link);
    };

    const inputChange = ({ target }) => {
        const { value } = target || {};

        if (value === 1) {
            setStartValue(dayjs(null));
            setEndValue(dayjs(null));
        } else {
            const sampleStart = moment(value + "-01")
                .startOf("month")
                .format("MM-DD-YYYY");

            const sampleEnd = moment(value + "-01")
                .endOf("month")
                .format("MM-DD-YYYY");

            setStartValue(dayjs(sampleStart));
            setEndValue(dayjs(sampleEnd));

            dayjs("2022-04-17");
        }

        setDropdownValue(value);
    };

    const populateDropdown = () => {
        let myArray = [];

        for (let i = 1; i <= 6; i++) {
            const forPreviousID = moment()
                .subtract(i, "months")
                .format("YYYY-MM");

            const forPreviousName = moment()
                .subtract(i, "months")
                .format("MMMM");

            const forPreviousMonth = moment()
                .subtract(i, "months")
                .format("MM");

            const forPreviousYear = moment()
                .subtract(i, "months")
                .format("YYYY");

            myArray.push({
                id: forPreviousID,
                name: forPreviousName,
                month: forPreviousMonth,
                year: forPreviousYear,
            });
        }

        setOptions(myArray);
    };

    return (
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <Button onClick={(e) => onClickHandler(e, "/samples")}>
                        <ArrowBackIcon />
                        Regresar
                    </Button>
                    <h4>Sample Filters</h4>
                </Grid>
            </Grid>

            <Grid container>
                <h5>Filtros</h5>

                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        // label="Estatus"
                        placeholder="Estatus"
                        id="monthFilter"
                        name="monthFilter"
                        {...register("monthFilter")}
                        value={dropdownValue}
                        onChange={(e) => inputChange(e)}
                        className="m-tb-20 form-control customDropdown"
                        sx={{
                            mb: 2,
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                    borderColor: "#6C63FF",
                                },
                            },
                        }}
                    >
                        <MenuItem key={0} value={0}>
                            {"Seleccione una opción"}
                        </MenuItem>

                        {options?.map((option, index) => (
                            <MenuItem
                                key={option?.id}
                                value={option?.id || 0}
                                className="customDropdownItem"
                            >
                                {option?.name} {option?.year}
                            </MenuItem>
                        ))}

                        <MenuItem key={1} value={1}>
                            {"Personalizado"}
                        </MenuItem>
                    </TextField>

                    <Grid item xs={12}>
                        <Grid container xs={12}>
                            <Grid item xs={4}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        value={startValue}
                                        onChange={(newValue) => {
                                            setStartValue(newValue);
                                            setDropdownValue(0);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={4}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        value={endValue}
                                        onChange={(newValue) => {
                                            setEndValue(newValue);
                                            setDropdownValue(0);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPage);
