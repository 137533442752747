import React from "react";
import { connect } from "react-redux";
import { unlockImei } from "_models/redux/request-unlock-imei/action";
import { LoadingButton } from "@mui/lab";
import { getEvents } from "_models/redux/events/action";
import { ActionUnlockImeiHook } from "../../hooks/actionsTicketsHooks/actionUnlockImeiHook";
import ModalUnblockImei from "../ModalUnblockImei";

const UnlockImeiContainer = (props) => {
    const {
        modalOpenUnlockImei,
        setModalOpenUnlockImei,
        isLoading,
        alert,
        errorMssge,
        severityAlert,
        handleUnlockImei,
        closePopup,
        imeiNumber,
    } = ActionUnlockImeiHook(props);

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={() => {
                    setModalOpenUnlockImei(true);
                }}
                variant="outlined"
                className="button"
            >
                <h4>Desbloquear IMEI</h4>
            </LoadingButton>

            {modalOpenUnlockImei && (
                <ModalUnblockImei
                    closePopup={closePopup}
                    imeiNumber={imeiNumber}
                    alert={alert}
                    errorMssge={errorMssge}
                    severityAlert={severityAlert}
                    isLoading={isLoading}
                    handleUnlockImei={handleUnlockImei}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    unlockImeiReducer: state.unlockImei,
    events: state.events,
});
const mapDispatchToProps = {
    unlockImei,
    getEvents,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnlockImeiContainer);
