import axiosTokenInstance from "../../../services/api/backoffice";

import { UNLOCK_IMEI, UNLOCK_IMEI_ERROR } from "_models/redux/types";

// Action function
export const unlockImei = (params) => async (dispatch) => {
    const { ticket_id, imei } = params || {
        imei: undefined,
    };

    const paramsData = {
        ticket_id,
        imei,
    };
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `api/support/tickets/${ticket_id}/actions/imei/${imei}/unlock`,
            paramsData
        );

        const { data } = response || {};

        dispatch({
            type: UNLOCK_IMEI,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: UNLOCK_IMEI_ERROR,
            payload: e || {},
        });
    }
};
