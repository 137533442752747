import React from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import CustomDialog from "widgets/component/CustomDialog";
import CustomFormApplyPackage from "modules/support/my-tickets/flow/detailTickets/components/customFormApplyPackage";
import { postPortabilityAction } from "_models/redux/portability/action";
import { cleanPortabilityAction } from "_models/redux/portability/clean-action";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ApplyPackageHook } from "../../hooks/applyPackageHook";

const ActionApplyPackage = (props) => {
    const {
        myWrapper,
        successDialogOpen,
        handleSuccessDialogClose,
        success,
        isDialogOpen,
        setIsDialogOpen,
        handleAccept,
        control,
        register,
        setValue,
        watch,
        reset,
        getValues,
        errors,
    } = ApplyPackageHook(props);

    return (
        <Grid item>
            <CustomDialog
                title="Aplicar paquete"
                wrapperCallback={myWrapper}
                content={
                    <CustomFormApplyPackage
                        control={control}
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        reset={reset}
                        getValues={getValues}
                        errors={errors}
                    />
                }
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                fullWidth
                maxWidth="sm"
                buttonPosition="end"
                extraButtons={[
                    {
                        label: "Cancelar",
                        variant: "text",
                        onClick: () => {
                            setIsDialogOpen(false);
                        },
                        buttonColor: "#ffff",
                    },
                    {
                        label: "Aceptar",
                        variant: "contained",
                        buttonColor: "#502CAA",
                        textColor: "white",
                        onClick: handleAccept,
                        type: "submit",
                    },
                ]}
            />
            <CustomDialog
                title=""
                content={
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            {success ? (
                                <CheckCircleIcon
                                    sx={{ fontSize: 40, color: "#724ECB" }}
                                />
                            ) : (
                                <CancelIcon
                                    sx={{ fontSize: 40, color: "#CB4E4E" }}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <Typography align="center">
                                {success
                                    ? "Se ha aplicado correctamente el paquete Dale 50 con el número de autorización 12345"
                                    : "Ocurrió un problema al aplicar el paquete, reportar a TI."}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                maxWidth="xs"
                buttonPosition="center"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "text",
                        onClick: handleSuccessDialogClose,
                        textColor: success ? "#724ECB" : "#CB4E4E",
                    },
                ]}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    portability: state?.portability,
});
const mapDispatchToProps = {
    postPortabilityAction,
    cleanPortabilityAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionApplyPackage);
