import {
    CHANGE_SUPPLEMENTARY_SERVICE,
    CHANGE_SUPPLEMENTARY_SERVICE_ERROR,
} from "_models/redux/types";

const initialState = {
    changeSupplementaryService: [],
    loading: false,
};

export default function changeSupplementaryServiceReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case CHANGE_SUPPLEMENTARY_SERVICE:
            return {
                ...state,
                changeSupplementaryService: action.payload,
                loading: true,
            };

        case CHANGE_SUPPLEMENTARY_SERVICE_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
