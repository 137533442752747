import React from "react";
import {
    WaterfallChart as WaterfallChartIcon,
    SwitchAccessShortcut as SwitchAccessShortcutIcon,
    Smartphone as SmartphoneIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";

const LineReportsMenu = ({
    userPermissions,
    openMenuReportsLines,
    handleClickReportsLines,
    activeStyle,
}) => {
    return (
        <>
            {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                <>
                    <GenericListItemButton
                        onClick={handleClickReportsLines}
                        className={"layout-menu-submenu"}
                        title="R. Líneas"
                        IconComponent={WaterfallChartIcon}
                        openMenu={openMenuReportsLines}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={openMenuReportsLines}
                        userPermissions={userPermissions}
                    >
                        {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="MANAGEMENT_REPORTS"
                                to="/dashboard/manager/activations"
                                title="Activaciones/Desactivaciones"
                                IconComponent={SwitchAccessShortcutIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="MANAGEMENT_REPORTS"
                                to="/dashboard/manager/prepaid"
                                title="Recarga"
                                IconComponent={SmartphoneIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default LineReportsMenu;
