import React from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";

const FieldComponent = (props = {}) => {
    const {
        fieldOnBlur,
        fieldOnChange,
        fieldContext,
        fielEndIcon,
        fieldStartIcon,
        fieldClassName,
        fieldName,
        fieldLabel,
        fieldPlaceholder,
        fieldDisabled,
        fileldDefaultValue,
        fieldRequired,
        fieldError,
        fieldType,
    } = props;

    return (
        <Grid item container xs={12} className={"custom-field"}>
            <TextField
                variant="standard"
                type={fieldType}
                className={"custom-textfield " + fieldClassName || ""}
                label={fieldLabel || ""}
                placeholder={fieldPlaceholder || ""}
                disabled={fieldDisabled}
                defaultValue={fileldDefaultValue || ""}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {fieldStartIcon || ""}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {fielEndIcon || ""}
                        </InputAdornment>
                    ),
                }}
                {...fieldContext.register(fieldName || "", {
                    onBlur: (e) =>
                        typeof fieldOnBlur == "function"
                            ? fieldOnBlur(e, fieldName)
                            : {},
                    onChange: (e) =>
                        typeof fieldOnChange == "function"
                            ? fieldOnChange(e, fieldName)
                            : {},
                    fieldRequired,
                })}
            />
            {fieldError}
        </Grid>
    );
};

export default FieldComponent;
