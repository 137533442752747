import { Grid } from "@mui/material";
import dashboardImage from "../../assets/images/dashboard/backoffice.png";

const DashboardPage = () => {
    return (
        <div>
            <Grid container className="dashboard-container">
                <Grid item xs={6} className="dashboard-text">
                    <h1>¡Hola!</h1>
                    <p>
                        Te damos la bienvenida a tu tablero principal,
                        próximamente podrás encontrar información y estadísticas
                        necesarias para brindar un mejor servicio a tus
                        clientes.
                    </p>
                </Grid>
                <Grid item xs={6} className="image-container">
                    <img src={dashboardImage} alt="Dashboard" />
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardPage;
