import axiosTokenInstance from "services/api/backoffice";
import { LEAD_BY_CAMPAING, LEAD_BY_CAMPAING_ERROR } from "_models/redux/types";

// Action function
export const getLeadsByCampaingAction = (params) => async (dispatch) => {
    const { from_time, to_time, channel_id, campaign_id, landing_id } =
        params || {};

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `api/reports/lead-status-by-campaing-channel?from_time=${from_time}&to_time=${to_time}&channel_id=${channel_id}&campaign_id=${campaign_id}&landing_id=${landing_id}`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: LEAD_BY_CAMPAING,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: LEAD_BY_CAMPAING_ERROR,
        });
    }
};
