import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "moment/locale/es";
import moment from "moment";
import { useLocation } from "react-router-dom";
export const PageHook = (props) => {
    const { cleanMySalesDetailsAction, mySalesDetails, getMySalesDetails } =
        props || {};

    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState();
    const [dateValue, setDateValue] = useState();

    const location = useLocation();

    const queryParameters = new URLSearchParams(window.location.search);
    const daySelected = queryParameters.get("date");

    useEffect(() => {
        cleanMySalesDetailsAction();
        const urlContent = window.location.href;

        if (urlContent.includes("date")) {
            setDateValue(dayjs(urlContent.split("=")[1]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (daySelected !== null) {
            setIsLoading(true);
            getMySalesDetails({
                date: daySelected,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        setIsLoading(true);
        if (mySalesDetails.loaded) {
            setIsLoading(false);
            const { payload } = mySalesDetails || {};
            setRows(payload?.result);
        }
    }, [mySalesDetails]);

    const handleSubmit = (params) => {
        setIsLoading(true);
        const formatDateValue = moment(params.format("YYYY-MM-DD")).format();

        const formatDateString = formatDateValue.split("T")[0];
        setDateValue(formatDateString);

        setIsLoading(false);

        window.history.replaceState(
            null,
            "",
            `/sales/dashboard/details?date=${formatDateString}`
        );
        getMySalesDetails({
            date: formatDateString,
        });
    };

    return { isLoading, dateValue, handleSubmit, rows };
};
