import React from "react";
import {
    WaterfallChart as WaterfallChartIcon,
    AppRegistration as AppRegistrationIcon,
    LocalAtm as LocalAtmIcon,
    Money as MoneyIcon,
    Paid as PaidIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";

const SalesReportsSimMenu = ({
    userPermissions,
    openMenuReportsSales,
    handleClickReportsSales,
    activeStyle,
}) => {
    return (
        <>
            {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                <>
                    <GenericListItemButton
                        onClick={handleClickReportsSales}
                        className={"layout-menu-submenu"}
                        title="R. Ventas SIM"
                        IconComponent={WaterfallChartIcon}
                        openMenu={openMenuReportsSales}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={openMenuReportsSales}
                        userPermissions={userPermissions}
                    >
                        {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="MANAGEMENT_REPORTS"
                                to="/dashboard/manager/sales/reports-incidence"
                                title="Incidencias"
                                IconComponent={AppRegistrationIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="MANAGEMENT_REPORTS"
                                to="/dashboard/manager/sales/monthly"
                                title="Ventas por mes"
                                IconComponent={LocalAtmIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="MANAGEMENT_REPORTS"
                                to="/dashboard/manager/sales/weekly"
                                title="Ventas por semana"
                                IconComponent={MoneyIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("MANAGEMENT_REPORTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="MANAGEMENT_REPORTS"
                                to="/dashboard/manager/sales/daily"
                                title="Ventas por día"
                                IconComponent={PaidIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default SalesReportsSimMenu;
