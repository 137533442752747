import {
    GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN,
    GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN_ERROR,
} from "_models/redux/types";

export const getGiftOrdersDetailCleanAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: null,
            type: GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN_ERROR,
        });
    }
};
