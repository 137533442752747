import axiosTokenInstance from "services/api/backoffice";
import { SIM_ACTIVATION, SIM_ACTIVATION_ERROR } from "_models/redux/types";

// Action function
export const simActivationAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `api/orders/${params}/force-activation`
        );

        const { data, status } = response || {};

        dispatch({
            type: SIM_ACTIVATION,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        dispatch({
            type: SIM_ACTIVATION_ERROR,
            payload: e || {},
        });
    }
};
