import { Grid, Button } from "@mui/material";

const LoginFormActions = ({ hookProps }) => {
    //Desructuring hook props, inherit from component parent:
    const {
        //Styles:
        loginFormButtonContainer,
        loginFormButton,
        loginForgotPassword,
    } = hookProps || {};

    //Action items (components) for login form:
    // - Login Button
    // - Forgot Password Link
    return (
        <>
            <Grid item container xs={12} sx={loginFormButtonContainer || {}}>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    style={loginFormButton || {}}
                >
                    Iniciar sesión
                </Button>
            </Grid>
            <Grid item container xs={12}>
                <a href="/forgot-password" style={loginForgotPassword || {}}>
                    ¿Olvidaste contraseña?
                </a>
            </Grid>
        </>
    );
};

export default LoginFormActions;
