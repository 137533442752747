import { Grid, TextField, Button } from "@mui/material";

const DateFormComponent = ({ register, errors, handleSubmit, onSubmit }) => (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} marginTop={2} alignItems="center">
            <Grid item xs={12} sm={6}>
                <TextField
                    id="from-date"
                    label="Fecha Desde"
                    type="date"
                    fullWidth
                    {...register("from_date", { required: true })}
                    InputLabelProps={{ shrink: true }}
                />
                {errors.from_date && (
                    <span style={{ color: "red" }}>Es obligatorio</span>
                )}
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="to-date"
                    label="Fecha Hasta"
                    type="date"
                    fullWidth
                    {...register("to_date", { required: true })}
                    InputLabelProps={{ shrink: true }}
                />
                {errors.to_date && (
                    <span style={{ color: "red" }}>Es obligatorio</span>
                )}
            </Grid>
        </Grid>
        <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "10px" }}
        >
            Generar Reporte
        </Button>
    </form>
);

export default DateFormComponent;
