import { useEffect, useContext } from "react";

import { Grid } from "@mui/material";
import OffersWidget from "modules/support/my-tickets/flow/detailTickets/components/offers_widget";
import { SessionContext } from "_models/context/app";

const MenuDirectNumberInfoWidget = ({ directNumber, error }) => {
    const sessionContext = useContext(SessionContext);
    const { setTypeServiceSelect } = sessionContext || {};

    useEffect(() => {
        setTypeServiceSelect("data");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={3} className="device-info-container">
            <Grid item xs={12} md={12}>
                {error ? (
                    <>
                        <h4 className="error-incidence">Error</h4>
                        <p className="error-incidence">{error.detail}</p>
                    </>
                ) : (
                    <OffersWidget directNumber={directNumber} />
                )}
            </Grid>
        </Grid>
    );
};

export default MenuDirectNumberInfoWidget;
