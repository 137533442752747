import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";

const SelectNir = ({
    handleSelectNIR,
    nirValueSelect,
    disableSelectNir,
    nirsMap,
}) => {
    return (
        <Grid item xs={12}>
            <TextField
                variant="standard"
                select
                fullWidth
                disabled={disableSelectNir}
                id="select-nir"
                label="Municipio"
                onChange={handleSelectNIR}
                value={nirValueSelect}
            >
                {nirsMap?.map((element) => {
                    return (
                        <MenuItem
                            key={element.id}
                            value={element.id + "_" + element.nir}
                        >
                            {element.municipality_name}
                        </MenuItem>
                    );
                })}
            </TextField>
        </Grid>
    );
};

export default SelectNir;
