import React from "react";
import { Grid } from "@mui/material";
import DropdownAgentSelect from "./DropdownAgentSelect";
import { checkPermission } from "_controllers/helpers/checkPermission";

const AgentAssignment = ({ handleChange, valueAgent, agentList }) => {
    return (
        <Grid container direction="row" alignItems="center" className="m-b-10">
            <Grid item xs={3} textAlign="end">
                <h5>Asignar a:</h5>
            </Grid>
            <Grid item xs={9} paddingLeft={1}>
                {checkPermission("SUPPORT_TICKETS_SUPERVISOR") && (
                    <DropdownAgentSelect
                        valueAgent={valueAgent}
                        handleChange={handleChange}
                        agentList={agentList}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default AgentAssignment;
