import {
    CHANGE_STATUS_TICKET,
    CHANGE_STATUS_TICKET_ERROR,
} from "_models/redux/types";

const initialState = {
    response: [],
    error: undefined,
    loading: false,
};

export default function changeStatusTicketReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case CHANGE_STATUS_TICKET:
            return {
                ...state,
                response: action.payload,
                loading: true,
            };

        case CHANGE_STATUS_TICKET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
