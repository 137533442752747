import {
    LOCK_IMEI,
    LOCK_IMEI_ERROR,
    CLEAN_LOCK_IMEI,
    CLEAN_LOCK_IMEI_ERROR,
} from "_models/redux/types";

const initialState = {
    lockImei_: [],
    loading: false,
};

export default function lockImeiReducer(state = initialState, action) {
    switch (action.type) {
        case LOCK_IMEI:
        case CLEAN_LOCK_IMEI:
            return {
                ...state,
                lockImei_: action.payload,
                loading: true,
            };

        case LOCK_IMEI_ERROR:
        case CLEAN_LOCK_IMEI_ERROR:
            return {
                ...state,
                lockImei_: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
