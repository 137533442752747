import React from "react";
import { Grid } from "@mui/material";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { connect } from "react-redux";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import { CustomerProfileHeaderClientHook } from "../controllers/customerProfileHeaderClientHook";
import AvatarComponent from "./AvatarComponent";
import CustomerInfoComponent from "./CustomerInfoComponent";
import SecondaryInfoComponent from "./SecondaryInfoComponent";
import ExpandableInfoComponent from "./ExpandableInfoComponent";

const CustomerProfileHeaderClient = (props) => {
    const {
        seeLineStatus,
        labelSimType,
        handleExpandClick,
        name,
        last_name,
        mothers_last_name,
        isLoading,
        directNumber,
        carrier_name,
        activated_at,
        expanded,
        imsi,
        imei,
        icc_number,
    } = CustomerProfileHeaderClientHook(props);
    return (
        <Grid container className="m-t-20">
            <Grid item xs={12} className="ticket-data">
                <Grid
                    className="basic"
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <AvatarComponent />
                    <CustomerInfoComponent
                        isLoading={isLoading}
                        name={name}
                        last_name={last_name}
                        mothers_last_name={mothers_last_name}
                        directNumber={directNumber}
                        labelSimType={labelSimType}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className="ticket-data">
                <SecondaryInfoComponent
                    isLoading={isLoading}
                    carrier_name={carrier_name}
                    seeLineStatus={seeLineStatus}
                    activated_at={activated_at}
                    expanded={expanded}
                    handleExpandClick={handleExpandClick}
                />
                <ExpandableInfoComponent
                    expanded={expanded}
                    isLoading={isLoading}
                    imsi={imsi}
                    imei={imei}
                    icc_number={icc_number}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    customerProfileReducer: state.customerProfile,
    ticketById: state.ticketById,
    incidence: state.incidence,
});

const mapDispatchToProps = {
    getCustomerProfile,
    getTicketById,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerProfileHeaderClient);
