import React from "react";
import { Grid, Typography } from "@mui/material";
import { getPaymentDetailsSTP } from "./data/DetailsSTP";
import { getPaymentDetailsToku } from "./data/DetailsToku";
import { getPaymentDetails } from "./data/DetailsNetPay";
import { getDistributorDetails } from "./data/DetailsHistoryPrepaid";
import { getAddressDetails } from "./data/DetailsSimIpb";

const titleStyle = { color: "#7A3C92" };
const spanStyle = { fontSize: 15, wordWrap: "break-word" };
const valueStyle = {
    textAlign: "right",
    marginTop: "8px",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const PaymentInfo = ({ orderDetails, title, type }) => {
    let fields = [];

    if (type === "detailsSTP") {
        fields = getPaymentDetailsSTP(orderDetails);
    } else if (type === "detailsToku") {
        fields = getPaymentDetailsToku(orderDetails);
    } else if (type === "detailsNetPay") {
        fields = getPaymentDetails(orderDetails);
    } else if (type === "detailsHistoryPrepaid") {
        fields = getDistributorDetails(orderDetails);
    } else if (type === "detailsSimIpb") {
        fields = getAddressDetails(orderDetails);
    }

    return (
        <Grid container className="device-info">
            <Grid item xs={12}>
                <span style={titleStyle}>{title}</span>
            </Grid>
            {fields.map((field, index) => (
                <Grid container key={index}>
                    <Grid item xs={6}>
                        <span style={spanStyle}>{field.label}</span>
                    </Grid>
                    <Grid item xs={6} style={valueStyle}>
                        <Typography variant="body1">
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : "N/A"}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default PaymentInfo;
