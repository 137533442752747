import { useTheme } from "@mui/material/styles";
//Login Styles:
import { loginStyles } from "../styles/loginStyles";

export const useLoginHook = () => {
    //Instance of Material-UI theme:
    const theme = useTheme();
    //Destructuring corresponding styles: login container
    const { loginContainer } = loginStyles({ theme }) || {};

    //Return styles container properties:
    return loginContainer || {};
};
