import axiosTokenInstance from "services/api/backoffice";
import { CREATE_LEAD, CREATE_LEAD_ERROR } from "_models/redux/types";

// Action function
export const CreateLead = (params) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.post(
            `/api/callcenter/leads`,
            params
        );

        const { data, status } = response || {};

        dispatch({
            type: CREATE_LEAD,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        dispatch({
            type: CREATE_LEAD_ERROR,
            payload: e.response || {},
        });
    }
};
