import axiosTokenInstance from "services/api/backoffice";
import { CHECK_IMEI, CHECK_IMEI_ERROR } from "_models/redux/types";

// Action function
export const patchCheckImei = (params) => async (dispatch) => {
    const { lead_id, imei } = params || {};

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.patch(
            `/api/callcenter/leads/${lead_id || ""}/check-imei`,
            {
                imei: imei || "",
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: CHECK_IMEI,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: CHECK_IMEI_ERROR,
            payload: e || {},
        });
    }
};
