import { COMMENT_TICKETS, COMMENT_TICKETS_ERROR } from "_models/redux/types";

const initialState = {
    commentTiketsObj: [],
    loading: false,
};

export default function commentTicketsReducer(state = initialState, action) {
    switch (action.type) {
        case COMMENT_TICKETS:
            return {
                ...state,
                commentTiketsObj: action.payload,
                loading: true,
            };

        case COMMENT_TICKETS_ERROR:
            return {
                ...state,
                commentTiketsObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
