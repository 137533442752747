import React from "react";
import Collapse from "@mui/material/Collapse";

const GenericCollapse = ({
    className,
    openMenu,
    userPermissions,
    children,
}) => (
    <Collapse className={className} in={openMenu} timeout="auto" unmountOnExit>
        {children}
    </Collapse>
);
export default GenericCollapse;
