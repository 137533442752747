import React from "react";
import { Grid, TextField } from "@mui/material";

const NipInput = ({ register, registerOptions, nipValue, errors }) => {
    return (
        <Grid container>
            <Grid item xs={12} className="body p-tb-15">
                <TextField
                    variant="standard"
                    fullWidth
                    type="number"
                    className="form-control"
                    placeholder="NIP"
                    label="NIP"
                    name="nipInput"
                    value={nipValue}
                    {...register("nipInput", registerOptions?.nipInput)}
                />
            </Grid>
            <Grid item xs={12}>
                <span>
                    {errors?.nipInput && (
                        <p className="required-incidence m-0">
                            {" "}
                            {errors?.nipInput.message}
                        </p>
                    )}
                </span>
            </Grid>
        </Grid>
    );
};

export default NipInput;
