import React from "react";
import { postUpdateApn } from "_models/redux/request-upadate-apn/action";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import ModalCustomActions from "modules/support/my-tickets/flow/detailTickets/components/actions-tickets/modalCustom";
import { ActionSendApnHook } from "../../hooks/actionsTicketsHooks/actionSendApnHook";

const ActionSendApn = (props) => {
    const {
        modalOpen,
        setModalOpen,
        isLoading,
        setIsLoading,
        alert,
        setAlert,
        errorMssge,
        setErrorMssge,
        severityAlert,
        setSeverityAlert,
        handleClickApn,
        handleSubmit,
        clearFunction,
    } = ActionSendApnHook(props);
    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickApn}
                variant="outlined"
                className="button"
            >
                <h4>Actualizar APN</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalCustomActions
                    modalOpen={modalOpen}
                    setOpenModal={setModalOpen}
                    titleModal="Actualizar APN"
                    subTitleModal="¿Desea actualizar APN?"
                    actionRequest={handleSubmit}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    alert={alert}
                    setAlert={setAlert}
                    errorMssge={errorMssge}
                    setErrorMssge={setErrorMssge}
                    severityAlert={severityAlert}
                    setSeverityAlert={setSeverityAlert}
                    clearFunction={clearFunction}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    actionPostUpdateApn: state.actionPostUpdateApn,
});
const mapDispatchToProps = {
    postUpdateApn,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionSendApn);
