import React from "react";
import { Grid, Button, Avatar } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import moment from "moment";

const TicketsList = ({ rows, handleNavigate }) => {
    return (
        <div className={"list-box"}>
            {rows?.map((element, index) => {
                const lastActionTime = moment(element.last_action_time)
                    .startOf("hour")
                    .fromNow();

                const created = moment(element.created)
                    .startOf("hour")
                    .fromNow();

                return (
                    <Grid container className="row" key={index}>
                        <Grid
                            item
                            xs={4}
                            className={"section-one"}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <p>
                                <ConfirmationNumberIcon className="svg" />
                                <span>
                                    <p>{element.id}</p>
                                </span>
                            </p>
                            <Button
                                id={element.id}
                                name={element.direct_number}
                                onClick={handleNavigate}
                            >
                                {element.title}
                            </Button>
                        </Grid>
                        <Grid item xs={8} className={"section-two"}>
                            <Grid
                                container
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                            >
                                <Grid item>
                                    <p className="phone-number">
                                        <LocalPhoneIcon className="svg" />
                                        {element.direct_number}
                                    </p>
                                </Grid>
                                <Grid item className={"dates"}>
                                    Creado: {created}
                                </Grid>
                                <Grid item className={"dates"}>
                                    Última actividad: {lastActionTime}
                                </Grid>

                                <Grid item className="dropdown-status">
                                    {element.general_status_label}
                                </Grid>
                                <Grid item>
                                    <Avatar className={"avatar"}>
                                        {element.assigned_to_user_name[0]}
                                        {element.assigned_to_user_last_name[0]}
                                        {
                                            element
                                                .assigned_to_user_mothers_last_name[0]
                                        }
                                    </Avatar>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </div>
    );
};

export default TicketsList;
