import axiosTokenInstance from "services/api/backoffice";

import {
    PORTABILITY_ACTION,
    PORTABILITY_ACTION_ERROR,
} from "_models/redux/types";

// Action function
export const postPortabilityAction =
    (ticket_id = undefined, params = {}) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(
                `/api/support/tickets/${ticket_id}/actions/portability`,
                params
            );

            const { data, status } = response || {};

            dispatch({
                type: PORTABILITY_ACTION,
                status: status || {},
                payload: data || {},
            });
        } catch (e) {
            console.error(e);

            const { response } = e || {};
            const { data, status } = response || {};

            dispatch({
                type: PORTABILITY_ACTION_ERROR,
                status: status || {},
                payload: data || {},
            });
        }
    };
