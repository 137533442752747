import { Box, Typography, CircularProgress } from "@mui/material";
import DataTable from "widgets/datagrid/DataTable";

const DataTableComponent = ({
    loading,
    mappedRows,
    Columns,
    pagination,
    page,
    size,
}) => (
    <div style={{ height: 400, width: "100%", marginTop: "10px" }}>
        {loading ? (
            <CircularProgress />
        ) : (
            <>
                {mappedRows?.length > 0 ? (
                    <Box sx={{ height: "100%", maxWidth: "100%" }}>
                        <DataTable
                            rows={mappedRows}
                            columns={Columns}
                            page={page}
                            pageSize={size}
                            rowCount={pagination?.total_rows ?? 0}
                            onPageChange={pagination?.handleChangePage}
                            handleChangePageSize={
                                pagination?.handleChangePageSize
                            }
                        />
                    </Box>
                ) : (
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                        No hay registros disponibles
                    </Typography>
                )}
            </>
        )}
    </div>
);

export default DataTableComponent;
