import React, { useState, useEffect } from "react";
import { Button, Typography, Grid, CircularProgress } from "@mui/material";
import ContentRechargeHistory from "modules/support/my-tickets/flow/detailTickets/components/contentRechargeHistory";
import OrdersDatagrid from "modules/my-sales/submodules/orders/list/widgets/datagrid";
import PageNetPay from "modules/transactions-netpay/page";
import PageSTP from "modules/transactions-stp/page";
import PageTOKU from "modules/transactions-toku/page";

const TransactionCard = ({
    transactionType,
    title,
    onClose,
    payment_type,
    type,
    idTransaction,
    namePayment,
}) => {
    const [showRechargeHistory, setShowRechargeHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isNetPay, setIsNetPay] = useState(false);
    const [isSTP, setIsSTP] = useState(false);
    const [isTOKU, setIsTOKU] = useState(false);

    const handleViewDetails = () => {
        setShowRechargeHistory(true);
    };

    useEffect(() => {
        if (showRechargeHistory) {
            if (namePayment === "NetPay") {
                setIsNetPay(true);
            }
            if (namePayment === "STP") {
                setIsSTP(true);
            }
            if (namePayment === "TOKU") {
                setIsTOKU(true);
            }
            setLoading(false);
        }
    }, [showRechargeHistory, idTransaction, type, namePayment]);

    if (loading) {
        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    zIndex: 10,
                }}
            >
                <CircularProgress />
            </Grid>
        );
    }

    if (isNetPay) {
        return <PageNetPay transactionId={idTransaction} onClose={onClose} />;
    }

    if (isSTP) {
        return <PageSTP transactionId={idTransaction} onClose={onClose} />;
    }

    if (isTOKU) {
        return <PageTOKU transactionId={idTransaction} onClose={onClose} />;
    }

    if (showRechargeHistory) {
        if (payment_type === "Recarga") {
            return (
                <ContentRechargeHistory
                    transactionId={idTransaction}
                    onClose={onClose}
                />
            );
        } else if (payment_type === "Compra") {
            return <OrdersDatagrid transactionId={idTransaction} />;
        }
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Grid container direction="column" className="device-info">
                <Grid item>
                    <Typography variant="h6" color="#FF6347">
                        <span style={{ color: "#7A3C92" }}>{title}</span>
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body1" color="#FF6347">
                            {transactionType}
                        </Typography>
                        <Button
                            color="primary"
                            onClick={handleViewDetails}
                            style={{
                                backgroundColor: "#ede7f6",
                                color: "#6a1b9a",
                                textTransform: "none",
                            }}
                        >
                            Ver Detalle
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TransactionCard;
