import {
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_CLEAN,
    RESET_PASSWORD_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    resetPassword: [],
    loading: false,
};

export default function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD:
        case RESET_PASSWORD_CLEAN:
            return {
                ...state,
                resetPassword: action.payload,
                loading: true,
            };
        case RESET_PASSWORD_ERROR:
        case RESET_PASSWORD_CLEAN_ERROR:
            return {
                ...state,
                resetPassword: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
