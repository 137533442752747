import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import miniLogo from "assets/images/logo.png";

import { useLayoutMenuHeader } from "modules/layout/hooks/useLayoutMenuHeader";

const LayoutMenuHeader = () => {
    const { DrawerHeader, theme, handleDrawerClose } =
        useLayoutMenuHeader() || {};

    return (
        <DrawerHeader className={"layout-menu-header"}>
            <img
                title="Mini Logo"
                alt="Mini Logo"
                src={miniLogo}
                className={"layout-logo"}
            />
            <IconButton onClick={handleDrawerClose} size="large">
                {theme?.direction === "rtl" ? (
                    <ChevronRightIcon />
                ) : (
                    <ChevronLeftIcon />
                )}
            </IconButton>
        </DrawerHeader>
    );
};

export default LayoutMenuHeader;
