import {
    NIR,
    NIR_ERROR,
    NIRS_CLEAN,
    NIRS_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    nir: [],
    loading: false,
};

export default function nirReducer(state = initialState, action) {
    switch (action.type) {
        case NIR:
        case NIRS_CLEAN:
            return {
                ...state,
                nir: action.payload,
                loading: true,
            };

        case NIR_ERROR:
        case NIRS_CLEAN_ERROR:
            return {
                ...state,
                nir: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
