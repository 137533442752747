import React from "react";
import TextField from "@mui/material/TextField";
import { Grid, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";
import SearchIcon from "@mui/icons-material/Search";

// Función para definir estilos
const getStyles = () => ({
    errorText: {
        color: "red",
        textAlign: "center",
    },
});

const SearchForm = ({
    handleSubmit,
    handleSearch,
    errors,
    register,
    errorMessage,
}) => {
    // Obtén los estilos
    const styles = getStyles();

    return (
        <form onSubmit={handleSubmit(handleSearch)}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                        label="Fecha"
                        type="date"
                        {...register("date")}
                        error={!!errors.date}
                        helperText={
                            errors.date ? "Introduzca una fecha válida." : ""
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                        label="Email"
                        {...register("email", {
                            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        })}
                        error={!!errors.email}
                        helperText={
                            errors.email
                                ? "introduzca un correo electrónico válido."
                                : ""
                        }
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                        label="Teléfono"
                        {...register("phone", { pattern: /^\d{10}$/ })}
                        error={!!errors.phone}
                        helperText={
                            errors.phone
                                ? "introduzca un número de teléfono válido."
                                : ""
                        }
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                        label="Nombre"
                        {...register("name", { minLength: 3 })}
                        error={!!errors.name}
                        helperText={
                            errors.name
                                ? "introduzca un nombre con al menos 3 caracteres."
                                : ""
                        }
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            {...register("status")}
                            defaultValue=""
                        >
                            <MenuItem value="Pendiente">Pendiente</MenuItem>
                            <MenuItem value="Procesado">Procesado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <GenericAddButton
                        buttonText="Buscar"
                        backgroundColor="#4C2484"
                        size="medium"
                        isSubmit
                        icon={<SearchIcon />}
                    />
                </Grid>

                <Grid item xs={12}>
                    {errorMessage && (
                        <p style={styles.errorText}>{errorMessage}</p>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

export default SearchForm;
