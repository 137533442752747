import axiosTokenInstance from "../../../services/api/backoffice";

import { RESUME_LINE, RESUME_LINE_ERROR } from "_models/redux/types";

// Action function
export const postResumeLineAction =
    (sessionIncidence = undefined) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(
                `/api/support/tickets/${sessionIncidence}/actions/resume-line`
            );

            const { data } = response || {};

            dispatch({
                type: RESUME_LINE,
                payload: data || {},
            });
        } catch (e) {
            dispatch({
                type: RESUME_LINE_ERROR,
                payload: e || {},
            });
        }
    };
