import {
    DAILY_REFILLS_PER_PRODUCT,
    DAILY_REFILLS_PER_PRODUCT_ERROR,
    CLEAN_DAILY_REFILLS_PER_PRODUCT,
    CLEAN_DAILY_REFILLS_PER_PRODUCT_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function dailyRefillsPerProductReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case DAILY_REFILLS_PER_PRODUCT:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case DAILY_REFILLS_PER_PRODUCT_ERROR:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_DAILY_REFILLS_PER_PRODUCT:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_DAILY_REFILLS_PER_PRODUCT_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
