// Import: Middleware Action & Custom Types
import {
    CLEAN_RECHARGE_HISTORY,
    CLEAN_RECHARGE_HISTORY_ERROR,
} from "_models/redux/types";

// Action function
export const rechargeHistoryClean = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_RECHARGE_HISTORY,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_RECHARGE_HISTORY_ERROR,
            payload: undefined,
        });
    }
};
