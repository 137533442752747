import React from "react";
import { Grid, Avatar } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";

const CustomerProfileHeaderIncidence = (props) => {
    return (
        <Grid container className="m-t-20">
            <Grid item xs={12} className="ticket-data">
                <Grid container className="basic">
                    <Grid item xs={12}>
                        <Grid container spacing={7}>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                flexDirection={"row"}
                            >
                                <Grid item xs={1} mr={1}>
                                    <Avatar
                                        sx={{
                                            bgcolor: "#441556",
                                            width: 26,
                                            height: 26,
                                        }}
                                    >
                                        <PersonIcon
                                            sx={{
                                                fontSize: 17,
                                            }}
                                        />
                                    </Avatar>
                                </Grid>
                                <Grid item xs={11}>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <h4>
                                                Nombre
                                                {/* {isLoading ? (
                                                    <CircularProgress />
                                                ) : name &&
                                                  last_name &&
                                                  mothers_last_name !=
                                                      "undefined" ? (
                                                    name +
                                                    " " +
                                                    last_name +
                                                    " " +
                                                    mothers_last_name
                                                ) : null} */}
                                            </h4>
                                            <p>
                                                {/* {isLoading ? (
                                                    <CircularProgress />
                                                ) : ( */}
                                                Teléfono
                                                {/* )} */}
                                            </p>
                                        </Grid>
                                        <Grid item xs={2} className="left-data">
                                            <Grid item xs={12}>
                                                <EditIcon />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="ticket-data">
                <Grid container className="secondary">
                    <Grid item xs={6}>
                        {/* {isLoading ? (
                            <CircularProgress />
                        ) : ( */}
                        <h4>Descripción</h4>
                        {/* )} */}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                    >
                        {/* <p className="status">{seeLineStatus()}</p> */}
                    </Grid>
                    <Grid item xs={6}>
                        {/* {isLoading ? (
                            <CircularProgress />
                        ) : ( */}
                        <p className="data vertical-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Etiam sit amet interdum mauris. Sed dignissim
                            ex vel enim scelerisque consectetur. Quisque elit
                            mauris, vulputate non condimentum vel, ultrices ac
                            mi. Sed a tempor odio. "
                        </p>
                        {/* )} */}
                    </Grid>

                    <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                    >
                        {/* <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore> */}
                    </Grid>
                </Grid>
                {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <hr color="#e9e9e9" />
                    <Grid container className="numeric m-t-10">
                        <Grid item xs={3}>
                            <h4>IMSI</h4>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            {isLoading ? <CircularProgress /> : <p> {imsi}</p>}
                        </Grid>
                    </Grid>

                    <Grid container className="numeric">
                        <Grid item xs={3}>
                            <h4>IMEI</h4>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            {isLoading ? <CircularProgress /> : <p> {imei}</p>}
                        </Grid>
                    </Grid>
                    <Grid container className="numeric">
                        <Grid item xs={3}>
                            <h4>ICC</h4>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            {isLoading ? (
                                <CircularProgress />
                            ) : (
                                <p> {icc_number}</p>
                            )}
                        </Grid>
                    </Grid>
                </Collapse> */}
            </Grid>
        </Grid>
    );
};
export default CustomerProfileHeaderIncidence;
