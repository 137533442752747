import { useContext, useState } from "react";
import { connect } from "react-redux";

import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CustomDialog from "widgets/component/CustomDialog";
import MenuDirectNumberInfoWidget from "./menu_direct_number_info_widget";

import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { cleanGetProfile } from "_models/redux/customer-profile/clean";

// import { MyContext } from "modules/layout/context";
import { LayoutContext } from "modules/layout/data/layoutContext";

const MenuCheckByDirectNumberWidget = (props) => {
    const { userPermissions } = props || {};
    const { customerProfile, getCustomerProfile } = props || {};
    const { customerProfileStatus } = customerProfile || {};

    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    // const myContext = useContext(MyContext);
    // const { open, handleDrawerOpen } = myContext || {};
    const layoutContext = useContext(LayoutContext) || {};
    const { layoutVariables, methods } = layoutContext || {};
    const { open } = layoutVariables || {};
    const { layoutMethods } = methods || {};
    const { handleDrawerOpen } = layoutMethods || {};

    const isInputValid = /^\d{10,}$/.test(searchText);

    const handleSearchClick = async () => {
        if (searchText.trim() !== "") {
            try {
                setIsLoading(true);
                await getCustomerProfile({
                    directNumber: searchText,
                });
            } catch (error) {
                console.error("Error al consultar perfil: ", error);
            } finally {
                setSearchText(""); // Limpiar el input después de la búsqueda
                setIsDialogOpen(true);
                setIsLoading(false);
            }
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            {userPermissions?.includes("SUPPORT_TICKETS_ACTIONS") && open ? (
                <Paper
                    component="form"
                    sx={{
                        p: "2px 4px",
                        m: "10px",
                        display: "flex",
                        alignItems: "center",
                        width: 215,
                    }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Número directo..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        inputProps={{
                            minLength: 10,
                            pattern: "\\d*",
                        }}
                    />
                    <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={handleSearchClick}
                        disabled={!isInputValid}
                        size="large"
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            ) : userPermissions?.includes("SUPPORT_TICKETS_ACTIONS") &&
              !open ? (
                <>
                    <IconButton
                        type="button"
                        sx={{ p: "10px", width: "100%" }}
                        aria-label="search"
                        onClick={handleDrawerOpen}
                        size="large"
                    >
                        <SearchIcon />
                    </IconButton>
                </>
            ) : null}
            {isInputValid || searchText === "" ? null : (
                <Typography color="error" variant="caption" sx={{ ml: 2 }}>
                    Se requieren al menos 10 caracteres numéricos.
                </Typography>
            )}
            {isLoading && (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {!isLoading && (
                <CustomDialog
                    title=""
                    content={
                        customerProfileStatus >= 300 ? (
                            <MenuDirectNumberInfoWidget
                                directNumber={searchText}
                                error={
                                    customerProfile?.customerProfile?.response
                                        ?.data
                                }
                            />
                        ) : (
                            <MenuDirectNumberInfoWidget
                                directNumber={searchText}
                            />
                        )
                    }
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth="xl"
                />
            )}
        </>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    customerProfile: state.customerProfile,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getCustomerProfile,
    cleanGetProfile,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuCheckByDirectNumberWidget);
