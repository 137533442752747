import React from "react";
import { Grid, Button } from "@mui/material";
const titleStyle = { color: "#7A3C92" };

const FraudAndChargebackSection = ({
    orderDetails,
    handleOpenPopUp,
    fraudStyle = {},
    chargebackStyle = {},
    title,
}) => {
    return (
        <Grid item xs={6}>
            <Grid container className="device-info">
                <Grid item xs={12}>
                    <span style={titleStyle}>{title}</span>
                </Grid>

                <Grid
                    container
                    alignItems="center"
                    style={{ marginBottom: "20px" }}
                >
                    <Grid item xs={6}>
                        <div>Fraude:</div>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        {orderDetails?.is_fraud ? (
                            <p style={fraudStyle}>Reportado</p>
                        ) : (
                            <Button
                                onClick={() =>
                                    handleOpenPopUp("fraud", orderDetails?.id)
                                }
                                style={{
                                    backgroundColor: "#ede7f6",
                                    color: "#6a1b9a",
                                    textTransform: "none",
                                    width: "100px",
                                }}
                            >
                                Reportar
                            </Button>
                        )}
                    </Grid>
                </Grid>

                <Grid
                    container
                    alignItems="center"
                    style={{ marginBottom: "20px" }}
                >
                    <Grid item xs={6}>
                        <div>Contracargo:</div>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        {orderDetails?.is_chargeback ? (
                            <p style={chargebackStyle}>Reportado</p>
                        ) : (
                            <Button
                                onClick={() =>
                                    handleOpenPopUp(
                                        "chargeback",
                                        orderDetails?.id
                                    )
                                }
                                style={{
                                    backgroundColor: "#ede7f6",
                                    color: "#6a1b9a",
                                    textTransform: "none",
                                    width: "100px",
                                }}
                            >
                                Reportar
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FraudAndChargebackSection;
