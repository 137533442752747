import { Card, Grid, Typography, Icon, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";

const ErrorPage = ({ messageResponseTickets, cleanTicketById }) => {
    const history = useNavigate();

    const handleButtonClick = () => {
        cleanTicketById();
        history("/tickets");
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh", backgroundColor: "#f4f4f4" }}
        >
            <Grid item>
                <Card
                    style={{
                        width: "400px",
                        padding: "40px",
                        borderRadius: "16px",
                        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                        textAlign: "center",
                    }}
                >
                    <Icon
                        component={ErrorIcon}
                        style={{ fontSize: 50, color: "#FF5252" }}
                    />
                    <Typography
                        variant="h5"
                        align="center"
                        style={{ margin: "20px 0", color: "#333333" }}
                    >
                        {messageResponseTickets}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleButtonClick}
                        style={{
                            backgroundColor: "#9575CD",
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: "8px",
                        }}
                    >
                        Volver a Incidencias
                    </Button>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ErrorPage;
