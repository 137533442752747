import React from "react";
import { Grid, Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { postChangeSim } from "_models/redux/request-change-sim/action";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { cleanChangeSim } from "_models/redux/request-change-sim/clean-action";
import { ModalNewIcc } from "../hooks/modalNewIcc";

function ModalSim(props) {
    const {
        iccNumber,
        isLoading,
        alert,
        errorMssge,
        severityAlert,
        handleSubmit,
        onInputChange,
        closePopup,
    } = ModalNewIcc(props);

    return (
        <Grid container className="modalBackground">
            <Grid container xs={4} className="modalContainer">
                <Grid container>
                    <Grid item xs={6} className="title" direction={"column"}>
                        <h3>Cambio de SIM</h3>
                    </Grid>
                    <Grid item xs={6} className="titleCloseBtn">
                        <button onClick={closePopup}>
                            <CloseIcon />
                        </button>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <h4>Nuevo ICC:</h4>
                </Grid>

                <Grid item xs={12} className="body">
                    <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        className="form-control"
                        placeholder="Ingrese valor del nuevo ICC"
                        name="directNumber"
                        value={iccNumber}
                        onChange={onInputChange}
                    />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                    <Collapse in={alert}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={closePopup}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity={severityAlert}
                        >
                            {errorMssge}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={12} className="footer">
                    <button onClick={closePopup} id="cancelBtn">
                        Cancelar
                    </button>
                    <LoadingButton
                        size="small"
                        onClick={handleSubmit}
                        endIcon={<SendIcon />}
                        loading={isLoading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Cambiar
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
const mapStateToProps = (state) => ({
    changeSim: state.changeSim,
});

const mapDispatchToProps = {
    postChangeSim,
    cleanChangeSim,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSim);
