import { Grid } from "@mui/material";

import { useGiftOrdersHeaders } from "modules/support-gift-orders/hooks/gift-orders-header/useGiftOrdersHeader";

import GiftOrdersHeaderButtons from "./GiftOrdersHeaderButtons";
import GiftOrdersHeaderDatePickers from "./GiftOrdersHeaderDatePickers";
import GiftOrdersHeaderTextField from "./GiftOrdersHeaderTextFields";

const GiftOrdersHeader = () => {
    // Hook propio de la funcionalidad del encabezado (filtros)
    const { handleSubmit, onSubmit } = useGiftOrdersHeaders();

    return (
        <>
            <h1>Listado de regalos</h1>

            <Grid item xs={12} className={"filters-container"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <GiftOrdersHeaderDatePickers />

                        <GiftOrdersHeaderTextField />

                        <GiftOrdersHeaderButtons />
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default GiftOrdersHeader;
