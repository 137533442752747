import React from "react";
import { Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const StatusLeadCustomFilters = (props) => {
    const { parentProperties } = props || {};
    const { form, redux, states } = parentProperties || {};
    const { leadStatus, setLeadStatus, setLandingId, landingId } = states || {};
    const { landingListObj, leadStatusListObj } = redux || {};

    const { register } = form || {};

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={6} xl={5}>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="*Landing"
                    placeholder="Landing"
                    id="landingId"
                    name="landingId"
                    {...register("landingId")}
                    value={landingId}
                    onChange={(e) => setLandingId(e.target.value)}
                    className="m-tb-20 form-control "
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#6C63FF",
                            },
                        },
                        background: "white",
                    }}
                >
                    <MenuItem value="0">{"Seleccionar"}</MenuItem>
                    {landingListObj?.payload?.landings?.map((option, index) => {
                        return (
                            <MenuItem key={option.id} value={option.id || 0}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={7}>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Lead Status"
                    placeholder="Campaña"
                    id="leadStatus"
                    name="leadStatus"
                    {...register("leadStatus")}
                    value={leadStatus}
                    onChange={(e) => setLeadStatus(e.target.value)}
                    className="m-tb-20 form-control "
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#6C63FF",
                            },
                        },
                        background: "white",
                    }}
                >
                    <MenuItem value="0">{"Todos"}</MenuItem>
                    {leadStatusListObj?.payload?.lead_status?.map(
                        (option, index) => {
                            return (
                                <MenuItem
                                    key={option.id}
                                    value={option.id || 0}
                                >
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )}
                </TextField>
            </Grid>
        </Grid>
    );
};

export default StatusLeadCustomFilters;
