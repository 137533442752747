import axiosTokenInstance from "services/api/backoffice";
import { PREPAID_BY_DAY, PREPAID_BY_DAY_ERROR } from "_models/redux/types";

// Action function
export const getPrepaidbyDay = (params) => async (dispatch) => {
    const { from_time, to_time } = params || {};

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/reports/prepaids/by-product-day?from_time=${from_time}&to_time=${to_time}`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || {},
            type: PREPAID_BY_DAY,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            type: PREPAID_BY_DAY_ERROR,
        });
    }
};
