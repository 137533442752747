import React, { useState } from "react";
import { connect } from "react-redux";
import { HelpdeskContext } from "./_context";

import HelpdeskComponent from "./_component";
import { postCreateIncidence } from "_models/redux/create_incidence/action";

const HelpdeskContainer = (props) => {
    const { postCreateIncidence } = props || {};

    const [shouldShowWarningDialog, setShouldShowWarningDialog] =
        useState(false);
    const [customDialogContent, setCustomDialogContent] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [dropdownOptionsChannels, setDropdownOptionsChannels] = useState("");
    const [selectChannel, setSelectChannel] = useState("");
    const handleClose = () => {
        setModalOpen(false);
    };
    const [formValue, setFormValue] = useState("");

    const helpdeskValues = {
        formValue,
        setFormValue,
        modalOpen,
        setModalOpen,
        dropdownOptionsChannels,
        setDropdownOptionsChannels,
        selectChannel,
        setSelectChannel,
        shouldShowWarningDialog,
        setShouldShowWarningDialog,
        customDialogContent,
        setCustomDialogContent,
        handleClose,
        postCreateIncidence,
    };

    return (
        <HelpdeskContext.Provider value={helpdeskValues}>
            <HelpdeskComponent />
        </HelpdeskContext.Provider>
    );
};

//export default CommentTickets;
const mapStateToProps = (state) => ({
    createIncidenceObj: state.createIncidenceObj,
});
const mapDispatchToProps = {
    postCreateIncidence,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskContainer);
