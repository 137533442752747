import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

//Component - Layout subnavigation routes (child):
import SubnavigationRoutes from "router/routes/subnavigation";

import { useLayoutMenu } from "../hooks/useLayoutMenu";

const LayoutContent = () => {
    const { open } = useLayoutMenu() || {};
    const theme = useTheme();

    return (
        <Grid
            container
            sx={{
                py: 3,
                pl: open ? 32 : { xs: 9, md: 10 },
                pr: 2,
                transition: theme.transitions.create("padding-left", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            }}
        >
            <Grid item xs={12}>
                <SubnavigationRoutes />
            </Grid>
        </Grid>
    );
};

export default LayoutContent;
