import React from "react";
import {
    Insights as InsightsIcon,
    StackedLineChart as StackedLineChartIcon,
    ShowChart as ShowChartIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";

const ShippingMenu = ({
    userPermissions,
    openShipping,
    handleClickShipping,
    activeStyle,
}) => {
    return (
        <>
            {userPermissions?.includes("SHIPPING") && (
                <>
                    <GenericListItemButton
                        onClick={handleClickShipping}
                        className={"layout-menu-submenu"}
                        title="Envíos"
                        IconComponent={InsightsIcon}
                        openMenu={openShipping}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={openShipping}
                        userPermissions={userPermissions}
                    >
                        {userPermissions?.includes("SHIPPING") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="SHIPPING"
                                to="/shipping"
                                title="Búsqueda"
                                IconComponent={StackedLineChartIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("SHIPPING") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="SHIPPING"
                                to="/shipping/pending"
                                title="Pendientes"
                                IconComponent={StackedLineChartIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("SHIPPING") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="SHIPPING"
                                to="/shipping/detail"
                                title="Detalle"
                                IconComponent={ShowChartIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default ShippingMenu;
