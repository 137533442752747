import { useContext } from "react";
import { Grid } from "@mui/material";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";

import { SessionContext } from "_models/context/app";

const OffersHeader = () => {
    const sessionContext = useContext(SessionContext);
    const { typeServiceSelect, setTypeServiceSelect } = sessionContext || {};

    return (
        <Grid container>
            <Grid item xs={8} className="ticket-detail-header">
                <h4>Servicios contratados</h4>
            </Grid>
            <Grid item xs={4} textAlign={"end"}>
                <Select
                    variant="standard"
                    id="outlined-select-currency"
                    value={typeServiceSelect}
                    onChange={(event) => {
                        setTypeServiceSelect(event.target.value);
                    }}
                >
                    <MenuItem value={"data"}>Datos</MenuItem>
                    <MenuItem value={"sms"}>SMS</MenuItem>
                    <MenuItem value={"calls"}>Llamadas</MenuItem>
                </Select>
            </Grid>
        </Grid>
    );
};
export default OffersHeader;
