import React from "react";
import { connect } from "react-redux";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import CardOffers from "./cardOffers";
import { ContainerModulesHook } from "../hooks/containerModulesHook";

const ContainerModules = (props) => {
    const { services, unitTypeService } = ContainerModulesHook(props);
    return <CardOffers services={services} typeService={unitTypeService} />;
};

const mapStateToProps = (state) => ({
    customerProfile: state.customerProfile,
});
const mapDispatchToProps = { getCustomerProfile };

export default connect(mapStateToProps, mapDispatchToProps)(ContainerModules);
