import axiosTokenInstance from "services/api/backoffice";
import { PATCH_LEAD, PATCH_LEAD_ERROR } from "_models/redux/types";

// Action function
export const patchLeadAction = (params) => async (dispatch) => {
    const { lead_id, body } = params || {};

    const dispatchObj = {
        method: "patch",
        status: undefined,
        payload: undefined,
        type: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.patch(
            `/api/callcenter/leads/${lead_id}/save-draft`,
            body
        );

        const { data, status } = response || {};

        dispatchObj.type = PATCH_LEAD;
        dispatchObj.status = status || {};
        dispatchObj.payload = data || {};

        dispatch(dispatchObj);
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatchObj.type = PATCH_LEAD_ERROR;
        dispatchObj.status = status || {};
        dispatchObj.payload = data || {};

        dispatch(dispatchObj);
    }
};
