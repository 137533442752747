import React from "react";
import { Grid, Collapse } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const ExpandableInfoComponent = ({
    expanded,
    isLoading,
    imsi,
    imei,
    icc_number,
}) => {
    return (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <hr color="#e9e9e9" />
            <Grid container className="numeric m-t-10">
                <Grid item xs={3}>
                    <h4>IMSI</h4>
                </Grid>
                <Grid item xs={9} display={"flex"} justifyContent={"flex-end"}>
                    {isLoading ? <CircularProgress /> : <p> {imsi}</p>}
                </Grid>
            </Grid>

            <Grid container className="numeric">
                <Grid item xs={3}>
                    <h4>IMEI</h4>
                </Grid>
                <Grid item xs={9} display={"flex"} justifyContent={"flex-end"}>
                    {isLoading ? <CircularProgress /> : <p> {imei}</p>}
                </Grid>
            </Grid>
            <Grid container className="numeric">
                <Grid item xs={3}>
                    <h4>ICC</h4>
                </Grid>
                <Grid item xs={9} display={"flex"} justifyContent={"flex-end"}>
                    {isLoading ? <CircularProgress /> : <p> {icc_number}</p>}
                </Grid>
            </Grid>
        </Collapse>
    );
};

export default ExpandableInfoComponent;
