import axiosTokenInstance from "../../../services/api/backoffice";

import { INCIDENCE, INCIDENCE_ERROR } from "_models/redux/types";

// Action function
export const postIncidence = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `/api/support/tickets`,
            params
        );

        const { data } = response || {};

        dispatch({
            type: INCIDENCE,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: INCIDENCE_ERROR,
            payload: e || {},
        });
    }
};
