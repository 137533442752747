// Import: Middleware Action & Custom Types
import {
    CLEAN_TICKET_BY_ID,
    CLEAN_TICKET_BY_ID_ERROR,
} from "_models/redux/types";

// Action function
export const cleanTicketById = (params) => async (dispatch) => {
    console.log("cleanTicketById", params);
    // Endpoint: Try & Catch

    try {
        dispatch({
            type: CLEAN_TICKET_BY_ID,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_TICKET_BY_ID_ERROR,
            payload: [],
        });
    }
};
