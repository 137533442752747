import {
    CLEAN_CHANGE_SIM_TO_ESIM,
    CLEAN_CHANGE_SIM_TO_ESIM_ERROR,
} from "_models/redux/types";

export const cleanChangeSimToEsimAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_CHANGE_SIM_TO_ESIM,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_CHANGE_SIM_TO_ESIM_ERROR,
        });
    }
};
