import React from "react";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { getStatusList } from "_models/redux/get-status/action";
import { connect } from "react-redux";
import { changeStatusTicket } from "_models/redux/change-status/actions";
import { TicketStatusHook } from "../hooks/ticketStatusHook";

const TicketStatus = (props) => {
    const { value, customerStatus, handleChange } = TicketStatusHook(props);
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
            >
                <FormControl variant="standard" size="small">
                    <Select
                        variant="standard"
                        defaultValue={2}
                        id="status-select"
                        value={value}
                        onChange={handleChange}
                        sx={{
                            background: "#74329d",
                            marginLeft: 2,
                            marginTop: 2,
                            color: "white",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            ".MuiSvgIcon-root ": {
                                fill: "white !important",
                            },
                        }}
                    >
                        {customerStatus.map((status) => {
                            return (
                                <MenuItem
                                    key={status.id}
                                    value={status.id || -1}
                                >
                                    {status.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    statusList: state.statusList,
    changeStatusTicket: state.changeStatusTicket,
    incidence: state.incidence,
    ticketById: state.ticketById,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getStatusList,
    changeStatusTicket,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(TicketStatus);
