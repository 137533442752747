import axiosTokenInstance from "../../../services/api/backoffice";
import { STATUS_LIST, STATUS_LIST_ERROR } from "_models/redux/types";

// Action function
export const getStatusList = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/catalogs/support-tickets-status`
        );

        const { data } = response || {};

        dispatch({
            type: STATUS_LIST,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: STATUS_LIST_ERROR,
            payload: e || {},
        });
    }
};
