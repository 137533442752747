import { MUNICIPALITY, MUNICIPALITY_ERROR } from "_models/redux/types";
import axiosTokenInstance from "services/api/backoffice";

export const municipalityAction = (params) => async (dispatch) => {
    const { state_id } = params || {};

    const paramsData = {
        state_id,
    };

    try {
        const response = await axiosTokenInstance.get(
            `/api/sepomex/states/${state_id}/municipalities`,
            paramsData
        );

        const { data, status } = response;

        dispatch({
            type: MUNICIPALITY,
            payload: data,
            status: status,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: MUNICIPALITY_ERROR,
            payload: e || {},
        });
    }
};
