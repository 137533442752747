import { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";

export const ActionImeiHook = (props) => {
    const { ticketId } = props;
    const { getActionImei } = props || {};
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpenDeviceInfo, setModalOpenDeviceInfo] = useState(false);
    const [infoDevicePopup, setInfoDevicePopup] = useState(false);
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        const imeiObj = getActionImei || undefined;
        if (imeiObj.imeiObj.length !== 0 && infoDevicePopup) {
            setIsLoading(false);
            setModalOpenDeviceInfo(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getActionImei]);

    const handleActionInfo = () => {
        setIsLoading(true);
        setInfoDevicePopup(true);
        props.getImei({ ticket_id: ticketId });
    };

    useEffect(() => {
        setIsScreenLoading(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return {
        handleActionInfo,
        modalOpenDeviceInfo,
        setModalOpenDeviceInfo,
    };
};
