import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import CustomPaginationTable from "./data/pagination";

const DataTable = ({
    rows,
    columns,
    onRowClick,
    page,
    pageSize,
    rowCount,
    onPageChange,
    handleChangePageSize,
}) => {
    const handleGridRowClick = (params) => {
        if (onRowClick) {
            onRowClick(params.id);
        }
    };

    const getRowClassName = (params) => {
        if (params.row.is_chargeback && params.row.is_fraud) {
            return "chargeback-and-fraud-row";
        } else if (params.row.is_chargeback) {
            return "chargeback-row";
        } else if (params.row.is_fraud) {
            return "fraud-row";
        }
        return "";
    };

    return (
        <Box sx={{ height: "100%", maxWidth: "100%" }}>
            <DataGrid
                pagination
                paginationMode="server"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={rowCount}
                page={page}
                onPageChange={(newPage) => onPageChange(newPage)}
                onRowClick={handleGridRowClick}
                getRowClassName={getRowClassName}
                components={{
                    Pagination: () => (
                        <CustomPaginationTable
                            page={page}
                            pageCount={page}
                            pageSize={pageSize}
                            rowCount={rowCount}
                            onPageChange={onPageChange}
                            onPageSizeChange={handleChangePageSize}
                        />
                    ),
                }}
            />
        </Box>
    );
};

export default DataTable;
