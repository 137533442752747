import React from "react";
import { Grid } from "@mui/material";

const TicketHeader = () => {
    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <Grid container direction={"row"}>
                    <Grid item xs={6} textAlign={"start"}>
                        <h3>Incidencias</h3>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TicketHeader;
