import React from "react";
import { Chart } from "react-google-charts";

const ChartComponent = ({ data, optionsGrid }) => (
    <Chart
        chartType="LineChart"
        width="100%"
        height="auto"
        data={data}
        options={optionsGrid}
    />
);

export default ChartComponent;
