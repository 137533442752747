import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

// Estilos para el Card
const cardStyle = {
    borderRadius: "10px", // Borde redondeado con un radio de 10 píxeles
    background: "#F9F8FC", // Fondo gris claro
    padding: "16px", // Espaciado interno
};

const CustomCard = () => {
    return (
        <Card style={cardStyle}>
            <CardContent>
                <Grid container spacing={2}>
                    {" "}
                    {/* Grid container con espaciado entre elementos */}
                    <Grid item xs={6}>
                        {" "}
                        {/* Columna 1 (mitad) */}
                        <div>
                            <Typography variant="h6">Teléfono:</Typography>
                            <Typography>777 0000 000</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        {" "}
                        {/* Columna 2 (mitad) */}
                        <TextField
                            select
                            label="Selecciona"
                            variant="outlined"
                            size="small"
                            style={{ width: "150px" }}
                        >
                            <MenuItem value="opcion1">Opción 1</MenuItem>
                            <MenuItem value="opcion2">Opción 2</MenuItem>
                            <MenuItem value="opcion3">Opción 3</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Typography variant="h6">Dirección:</Typography>
                <Typography>
                    Paseo del conquistador 30, Lomas de Cortés, Cuernavaca
                    Morelos.
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CustomCard;
