import { EVENTS, EVENTS_ERROR } from "_models/redux/types";

const initialState = {
    events: [],
    loading: false,
};

export default function eventsReducer(state = initialState, action) {
    switch (action.type) {
        case EVENTS:
            return {
                ...state,
                events: action.payload,
                loading: true,
            };

        case EVENTS_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
