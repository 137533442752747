import { useContext } from "react";
import { WeeklySalesContext } from "../data/context";

export const useWeeklySalesReportChartHook = () => {
    const weeklySalesContext = useContext(WeeklySalesContext);
    const { states } = weeklySalesContext || {};
    const { data } = states;

    const dataArray = data ? Object.keys(data).map((key) => data[key]) : [];

    const productChart = [];
    const dateChart = [];

    productChart.push("fecha");

    dataArray?.map((item, index) => {
        if (productChart.indexOf(item?.rName) === -1) {
            productChart.push(item?.rName);
        }

        if (dateChart.indexOf(item?.hName) === -1) {
            dateChart.push(item?.hName);
        }

        return true;
    });

    const productChartLabels = productChart?.map((element) =>
        element.replaceAll("_", " ")
    );

    const labelsArray = [];
    const resultChart = [];

    resultChart.push(productChartLabels);
    dateChart?.map((dItem, dIndex) => {
        const rowChart = [];

        rowChart.push(dItem);
        productChart?.map((pItem, pIndex) => {
            if (pIndex > 0) {
                const rowChartItem = dataArray?.find(
                    (element) =>
                        element.rName === pItem && element.hName === dItem
                );

                rowChart.push(rowChartItem?.total || 0);
            }

            return true;
        });

        resultChart.push(rowChart);

        return true;
    });

    resultChart.map((item, index) => {
        const labelsRow = [];
        item.map((iItem, iIndex) => {
            if (iItem.toString().indexOf("_") >= 0) {
                const test = iItem.toString().split("_");
                labelsRow.push(test[1]);
            } else {
                labelsRow.push(iItem);
            }
            return true;
        });
        labelsArray.push(labelsRow);
        return true;
    });

    const optionsGrid = {
        height: 500,
        title: "Ventas por semana",
    };

    return { resultChart: labelsArray, optionsGrid };
};
