import axiosTokenInstance from "services/api/backoffice";
import {
    TRANSACTIONS_TOKU,
    TRANSACTIONS_TOKU_ERROR,
} from "_models/redux/types";

// Action function

export const listTokuAction =
    (page = 1, size = 10, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                `/api/reports/v2/transactions-toku?`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: TRANSACTIONS_TOKU,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: TRANSACTIONS_TOKU_ERROR,
                payload: e || {},
            });
        }
    };
