import { DataGrid } from "@mui/x-data-grid";

import CustomLinearProgress from "widgets/datagrid/CustomLinearProgress";
import CustomNoRowsOverlay from "widgets/datagrid/CustomNoRowsOverlay";
// import GiftOrdersListPagination from "./GiftOrdersListPagination";

import { datagridStyles } from "modules/support-gift-orders/styles/giftOrdersStyles";

import { useGiftOrdersList } from "../../hooks/gift-orders-list/useGiftOrdersList";

import { GiftOrdersDatagridContext } from "../../data/giftOrdersContexts";

const GiftOrdersList = () => {
    // Hook propio de la funcionalidad del listado (datagrid)
    const {
        rows,
        rowCount,
        columns,

        datagridPage,
        datagridPageSize,

        loadingDatagrid,

        changePage,
        changePageSize,

        giftOrdersDatagridValues,
    } = useGiftOrdersList();

    return (
        <GiftOrdersDatagridContext.Provider value={giftOrdersDatagridValues}>
            <DataGrid
                autoHeight={true}
                columns={columns}
                rows={rows}
                pagination
                paginationMode="server"
                onPageChange={(newPage) => changePage(newPage)}
                onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
                rowsPerPageOptions={[50, 75, 100]}
                pageSize={datagridPageSize}
                page={datagridPage}
                rowCount={rowCount}
                loading={loadingDatagrid}
                disableColumnMenu
                disableSelectionOnClick={true}
                checkboxSelection={false}
                sx={datagridStyles}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    NoResultsOverlay: CustomNoRowsOverlay,
                    LoadingOverlay: CustomLinearProgress,
                    // Pagination: GiftOrdersListPagination,
                }}
            />
        </GiftOrdersDatagridContext.Provider>
    );
};

export default GiftOrdersList;
